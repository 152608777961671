import { gql } from "@apollo/client";

export const QUERY_TIPO_DOCUMENTOS = gql`
  query TipoDocumentos($id: Int!) {
    tipodocumento(id: $id) {
      id
      nombre
    }
  }
`;

export const QUERY_ALL_TIPO_DOCUMENTOS= gql`
  query AllTiposDocumentos {
    allTiposDocumento {
      id
      nombre
    }
  }
`;
