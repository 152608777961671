import React from "react";
import { Navigate, Route, Routes } from "react-router";
import { OrderFormView } from "../views/OrderFormView";
import { OrderListView } from "../views/OrderListView";

export const OrderRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<OrderListView />} />
      <Route path="create" element={<OrderFormView />} />
      <Route path=":id" element={<OrderFormView />} />
      <Route path="/*" element={<Navigate to="actives" />} />
    </Routes>
  );
};
