import { Check, Clear } from "@mui/icons-material";
import { useEffect, useMemo, useState } from "react";
import { EditButton } from "../../../../components/buttons";
import { LOCALE_TABLE } from "../../../../components/custom-table/CustomTable";
import { useUsers } from "../hooks/useUsers";

import MaterialReactTable from "material-react-table";

import { Button } from "@mui/material";
import { Box } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { ExportToCsv } from "export-to-csv";

export const UserListView = () => {
  const { data, loading } = useUsers();
  const [usuarios, setUsuarios] = useState([]);

  const columns = useMemo(
    () => [
      {
        header: "",
        accessorKey: "user.email",
        enableColumnActions: false,
        enableFilters: false,
        enableMultiSort: false,
        enableColumnFilter: false,
        enableColumnFilterChangeMode: false,
        id: "actionsEdit",
        size: 30,
        Cell: ({ cell }) => (
          <div className="text-center">
            <EditButton entity="users" id={cell.getValue()} />
          </div>
        ),
      },
      {
        accessorKey: "user.email", //access nested data with dot notation
        header: "EMAIL",
      },
      {
        accessorKey: "user.firstName",
        header: "NOMBRE",
      },
      {
        accessorKey: "user.lastName", //normal accessorKey
        header: "APELLIDO",
      },
      {
        accessorKey: "user.groups", //normal accessorKey
        header: "ROL",
        Cell: ({ cell }) => (
          <div>
            {cell.getValue().map((group) => (
              <div key={group.id}>{group.name}</div>
            ))}
          </div>
        ),
      },
      {
        accessorKey: "user.isActive",
        header: "Activo",
        Cell: ({ cell }) => (
          <div>
            {cell.getValue() ? (
              <Check color="success" />
            ) : (
              <Clear color="danger" />
            )}
          </div>
        ),
      },
      {
        accessorKey: "verified",
        header: "Verificado",
        Cell: ({ cell }) => (
          <div>
            {cell.getValue() ? (
              <span className="text-success">VERIFICADO</span>
            ) : (
              <span className="text-danger">NO VERIFICADO</span>
            )}
          </div>
        ),
      },
    ],
    []
  );

  const csvOptions = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers: columns.map((c) => c.header),
  };

  const csvExporter = new ExportToCsv(csvOptions);

  useEffect(() => {
    if (data) {
      setUsuarios(data?.users2);
    }
  }, [data]);

  const handleExportRows = (rows) => {
    csvExporter.generateCsv(rows.map((row) => row.original));
  };

  const handleExportData = () => {
    csvExporter.generateCsv(usuarios);
  };

  return (
    <>
      <MaterialReactTable
        columns={columns}
        data={usuarios}
        localization={LOCALE_TABLE}
        enableRowSelection
        enableColumnFilterModes
        enableGlobalFilterModes
        enableDensityToggle={false}
        state={{
          density: "compact",
          isLoading: loading,
        }}
        initialState={{
          pagination: {
            pageSize: 30,
          },
        }}
        muiTableProps={{
          sx: {
            tableLayout: "auto",
          },
        }}
        muiTablePaginationProps={{
          rowsPerPageOptions: [30, 50, 100],
          SelectProps: { native: true },
        }}

        renderTopToolbarCustomActions={({ table }) => (
          <Box>
            <Button
              color="primary"
              className="btn-xs btn-air-primary mx-1"
              //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
              onClick={handleExportData}
            >
              <FileDownloadIcon /> Exportar todo
            </Button>
            <Button
              color="primary"
              className="btn-xs btn-air-primary mx-1"
              disabled={table.getRowModel().rows.length === 0}
              //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
              onClick={() => handleExportRows(table.getRowModel().rows)}
            >
              <FileDownloadIcon /> Exportar página
            </Button>
            <Button
              color="primary"
              className="btn-xs btn-air-primary mx-1"
              disabled={
                !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
              }
              //only export selected rows
              onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
            >
              <FileDownloadIcon /> Exportar selección
            </Button>
          </Box>
        )}
      ></MaterialReactTable>
    </>
  );
};
