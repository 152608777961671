import React from 'react'
import { Container } from 'reactstrap'
import Breadcrumbs from '../../../layout/breadcrumb'
import { WaybillRouter } from './router/WaybillRouter'

export const WaybillPage = () => {
  return (
    <>
      <Breadcrumbs title="Albaranes/Entradas" entidad="waybills" parent="Albaranes" />
      <Container fluid={true}>
        <div className="faq-wrap">
          <WaybillRouter />
        </div>
      </Container>
    </>  )
}
