import { useMutation } from "@apollo/client";
import {
  MUTATION_CREATE_ACTIVO,
  MUTATION_DELETE_ACTIVO,
  MUTATION_DELETE_DOCUMENTO_ACTIVO,
  MUTATION_UPDATE_ACTIVO,
} from "../graphql/mutations";
import { QUERY_ALL_ACTIVOS } from "../graphql/queries";
import { toast } from "react-toastify";

export const useMutationActives = () => {
  const [deleteActivo] = useMutation(MUTATION_DELETE_ACTIVO, {
    refetchQueries: [
      {
        query: QUERY_ALL_ACTIVOS,
      },
    ],
    onError: (err) => {
      toast.error(err.message);
    },
  });
  const [createActivo] = useMutation(MUTATION_CREATE_ACTIVO, {
    refetchQueries: [
      {
        query: QUERY_ALL_ACTIVOS,
      },
    ],
  });
  const [updateActivo] = useMutation(MUTATION_UPDATE_ACTIVO, {
    refetchQueries: [
      {
        query: QUERY_ALL_ACTIVOS,
      },
    ],
  });
  const [deleteDocumento] = useMutation(MUTATION_DELETE_DOCUMENTO_ACTIVO);
  return {
    deleteActivo,
    createActivo,
    updateActivo,
    deleteDocumento,
  };
};
