import { Close } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Col, Row } from "reactstrap";
import { SaveButton } from "../../../../components";
import { useSites } from "../../site/hooks/useSites";
import { useMutationsWaybill } from "../../waybill/hooks/useMutationsWaybill";
import { toast } from "react-toastify";

export const CreateAlbaranModal = ({ visible, closeHandler, pedidoId }) => {
  const { estadosMercancias, tiemposEntrega } = useSelector(
    (state) => state.GlobalState
  );
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      pedidoId,
      fecha: moment().format("YYYY-MM-DD"),
      cantidad: 0,
      precioUnidad: 0,
      tiempoEntrega: "A",
      estadoMercancia: "A",
      sedeId: 1,
    },
  });
  const { data: dataSedes } = useSites();
  const [sede, setSede] = useState(1);
  const [tiempoEntrega, setTiempoEntrega] = useState("A");
  const [estadoMercancia, setEstadoMercancia] = useState("A");
  const { createAlbaran, updateAlbaran } = useMutationsWaybill();

  const onSubmit = (formData) => {
    createAlbaran({
      variables: {
        data: {
          pedidoId: formData.pedidoId,
          ...formData,
        },
      },
    })
      .then(({ data }) => {
        if (data.createAlbaran.ok) {
          toast.info("Albarán creado correctamente");
          closeHandler();
        } else {
          toast.error("Error al crear albarán");
        }
      })
      .catch((error) => {
        toast.error("Error al crear albarán. " + error.message);
      });
  };
  return (
    <Dialog
      disableEscapeKeyDown
      fullWidth
      maxWidth="xl"
      open={visible}
      onClose={(_, reason) => {
        if (reason !== "backdropClick") {
          closeHandler();
        }
      }}
    >
      <DialogTitle className="d-flex justify-content-between my-2 py-2">
        <span id="modal-title">
          Crear albarán de entrega /{" "}
          <Typography variant="h5" color="primary">
            PEDIDO {pedidoId}
          </Typography>
        </span>
        <Button onClick={closeHandler}>
          <Close />
        </Button>
      </DialogTitle>

      <DialogContent className="p-">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row className="mb-3">
            <Col sm="12">
              <h6>Nuevo albarán</h6>
            </Col>
          </Row>
          <Row>
            <Col sm="12" md="2">
              <TextField
                size="small"
                name="fecha"
                label="Fecha"
                type="date"
                focused={true}
                {...register("fecha", { required: true })}
                helperText={errors.fecha ? "Campo obligatorio" : ""}
                error={errors.fecha?.type === "required"}
              />
            </Col>
            <Col sm="12" md="2">
              <FormControl fullWidth>
                <input
                  type="hidden"
                  {...register("sedeId", {
                    required: true,
                  })}
                />
                <InputLabel>Sede</InputLabel>
                <Select
                  size="small"
                  name="sedeId"
                  label="Sede"
                  value={sede}
                  onChange={(e) => {
                    setSede(e.target.value);
                    setValue("sedeId", e.target.value);
                  }}
                >
                  {dataSedes?.allSedes.map((estado) => (
                    <MenuItem key={estado.id} value={estado.id}>
                      {estado.nombre}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Col>
            <Col sm="12" md="2">
              <TextField
                size="small"
                name="cantidad"
                label="Cantidad"
                type="number"
                {...register("cantidad", { required: true })}
                helperText={errors.cantidad ? "Campo obligatorio" : ""}
                error={errors.cantidad?.type === "required"}
              />
            </Col>
            <Col sm="12" md="2">
              <TextField
                size="small"
                name="precioUnidad"
                label="Precio Unidad"
                inputProps={{
                  inputMode: "decimal",
                  pattern: "([0-9]+)?[,\\.]?[0-9]*",
                }}
                {...register("precioUnidad", { required: true })}
                helperText={errors.precioUnidad ? "Campo obligatorio" : ""}
                error={errors.precioUnidad?.type === "required"}
              />
            </Col>
            <Col sm="12" md="2">
              <FormControl fullWidth>
                <input type="hidden" {...register("estadoMercancia")} />
                <InputLabel>Estado Mercancías</InputLabel>
                <Select
                  size="small"
                  name="estadoMercancia"
                  label="Estado mercancía"
                  value={estadoMercancia}
                  onChange={(e) => {
                    setValue("estadoMercancia", e.target.value);
                    setEstadoMercancia(e.target.value);
                  }}
                >
                  {estadosMercancias.map((estado) => (
                    <MenuItem key={estado.id} value={estado.id}>
                      {estado.nombre}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Col>
            <Col sm="12" md="2">
              <FormControl fullWidth>
                <input type="hidden" {...register("tiempoEntrega")} />
                <InputLabel>Tiempos de Entrega</InputLabel>
                <Select
                  size="small"
                  name="tiempoEntrega"
                  label="Tiempo de entrega"
                  value={tiempoEntrega}
                  onChange={(e) => {
                    setTiempoEntrega(e.target.value);
                    setValue("tiempoEntrega", e.target.value);
                  }}
                >
                  {tiemposEntrega.map((estado) => (
                    <MenuItem key={estado.id} value={estado.id}>
                      {estado.nombre}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              <TextField
                name="observaciones"
                label="Observaciones"
                multiline
                rows={4}
                fullWidth
                size="small"
                {...register("observaciones")}
              />
            </Col>
          </Row>
          <Row>
            <Col sm="12" className="d-flex justify-content-end">
              <SaveButton />
            </Col>
          </Row>
        </form>
      </DialogContent>
    </Dialog>
  );
};
