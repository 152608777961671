import React from "react";
import { useNavigate } from "react-router";
import { Card, CardHeader, Table } from "reactstrap";

export const SiteInvoices = ({ invoices }) => {
  const navigate = useNavigate();
  return (
    <Card>
      <CardHeader>
        <h6>Facturas</h6>
      </CardHeader>
      <Table responsive hover>
        <thead>
          <tr>
            <th>Número</th>
            <th>Fecha</th>
            <th>Cliente</th>
          </tr>
        </thead>
        <tbody>
          {invoices?.map((invoice) => (
            <tr
              key={invoice.id}
              onClick={() => navigate(`/invoices/${invoice.id}`)}
            >
              <td>{invoice.numero}</td>
              <td>{invoice.fecha}</td>
              <td>{invoice.cliente.nombre}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Card>
  );
};
