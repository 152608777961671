import { ApolloClient, InMemoryCache, HttpLink, gql } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";

// const token = await localStorage.getItem("gestion-editorial-token");

const httpLink = new HttpLink({
  // uri: "https://editorialbk.gestiones.pro/graphql/",
  uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
  // headers: {
  //   authorization: token ? `JWT ${token}` : "",
  // },
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("gestion-editorial-token");
  return {
    headers: {
      ...headers,
      authorization: token ? `JWT ${token}` : "",
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError, response }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );
      if (message.includes("permission")) {
        // alert(message);
        window.localStorage.clear();
        window.location.href = "/auth/login" + message;
      }
      if (message.includes("permisos")) {
        // alert(message);
        window.location.href = "/auth/forbidden?message=" + message;
      }
    });
  }
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
    if ("statusCode" in networkError && networkError.statusCode === 401) {
      // TODO redirect to /login
      // window.location.href = "/auth/login";
    }
  }
});

export const client = new ApolloClient({
  link: authLink.concat(errorLink.concat(httpLink)),
  cache: new InMemoryCache(),
});
