import React from "react";
import { Container } from "reactstrap";
import Breadcrumbs from "../../../layout/breadcrumb";
import { TipoDocumentoRouter } from "./router/TipoDocumentoRouter";

export const TipoDocumentoPage = () => {
  return (
    <>
      <Breadcrumbs
        title="Tipos Documentos"
        entidad="tipos-documentos"
        parent="Tipos Documentos"
      />
      <Container fluid={true}>
        <div className="faq-wrap">
          <TipoDocumentoRouter />
        </div>
      </Container>
    </>
  );
};
