import { useMemo } from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import { CustomTable } from "../../../../../components";
import { useFiltrosPaginacion } from "../../../../../hooks/useFiltrosPaginacion";
import moment from "moment";

export const VendorWaybills = ({ pedidos }) => {
  const { setFiltros, filtros, setPage } = useFiltrosPaginacion();

  const columns = useMemo(() => [
    {
      header: "FECHA",
      accessorKey: "fecha",
      filterVariant: "date",
      Cell: ({ cell }) => {
        return moment(cell.value).format("DD/MM/YYYY HH:mm");
      },
    },
    {
      header: "ACTIVO",
      accessorKey: "activo",
      Cell: ({ cell }) => {
        return (
          <a
            target="_blank"
            rel="noreferrer"
            href={`/actives/${cell.getValue().id}`}
          >
            {cell.getValue().titulo}
          </a>
        );
      },
    },
    {
      header: "CANTIDAD",
      accessorKey: "cantidad",
    },
    {
      header: "OBSERVACIONES",
      accessorKey: "observaciones",
    },
  ]);
  return (
    <>
      <Card>
        <CardHeader>
          <h6>Pedidos</h6>
        </CardHeader>
        <CustomTable
          estadoFiltros={filtros}
          setfiltros={setFiltros}
          data={pedidos}
          columns={columns}
        />
      </Card>
    </>
  );
};
