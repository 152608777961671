import { useQuery } from "@apollo/client";
import { CALCULO_FACTURALINEA } from "../graphql/queries-graphql";
import toast from "react-hot-toast";
export const useCalculoTotal = () => {
  const {
    data,
    loading,
    error,
    refetch: getCalculo,
  } = useQuery(CALCULO_FACTURALINEA);
  return {
    getCalculo,
  };
};
