import React from "react";
import { Container } from "reactstrap";
import Breadcrumbs from "../../../layout/breadcrumb";
import { OrderRouter } from "./router/OrderRouter";

export const OrderPage = () => {
  return (
    <>
      <Breadcrumbs title="Pedidos" entidad="orders" parent="Pedidos" />
      <Container fluid={true}>
        <div className="faq-wrap">
          <OrderRouter />
        </div>
      </Container>
    </>
  );
};
