import moment from "moment";
import React, { Fragment } from "react";
import { Card, Table } from "react-bootstrap";
import { CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import { useActives } from "../active/hooks/useActives";
import { useGetUltimosCambios } from "./hooks/useGetUltimosCambios";
import { IconButton, Tooltip } from "@mui/material";
import { Loupe } from "@mui/icons-material";
import { DetailsModal } from "./DetailsModal";

export const DashboardPage = () => {
  const { data: dataActivos } = useActives();
  const { data: dataUltimosCambios } = useGetUltimosCambios();
  const [open, setOpen] = React.useState(false);
  const [detalles, setDetalles] = React.useState("");
  const handleOpen = (detalles) => {
    setDetalles(detalles);
    setOpen(true);
  };

  return (
    <Fragment>
      <Container fluid={true}>
        <div className="p-3">
          <Row className="my-5">
            <Col sm="12">
              <Card>
                <CardHeader className="py-2 my-2">
                  <h3>Activos</h3>
                </CardHeader>
                <CardBody
                  className="p-4"
                  style={{
                    maxHeight: "40vh",
                    overflowY: "scroll",
                  }}
                >
                  <ul className="d-flex flex-wrap justify-content-center">
                    {dataActivos?.allActivos.items.map((activo) => (
                      <li className="folder-box m-2" key={activo.id}>
                        <a
                          href={
                            "https://docs.google.com/folder/d/" + activo?.gdrive
                          }
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          <div className="media">
                            <i className="fa fa-folder f-36 txt-warning"></i>
                            <div
                              className="media-body ms-3"
                              style={{ width: "200px" }}
                            >
                              <Tooltip title={activo.titulo} placement="top">
                                <span className="mb-0">
                                  {activo.titulo.slice(0, 40)}{" "}
                                  {activo.titulo.length > 40 ? "..." : ""}
                                </span>
                              </Tooltip>
                              <p className="my-0 text-muted">{`${activo.documentoSet?.length} documentos`}</p>
                              <p className="my-0 text-muted">
                                Alta{" "}
                                {moment(activo.fechaAlta).format("DD-MM-YYYY")}
                              </p>
                            </div>
                          </div>
                        </a>
                      </li>
                    ))}
                  </ul>
                </CardBody>
              </Card>
            </Col>
            <Col sm="12">
              <DetailsModal open={open} setOpen={setOpen} detalles={detalles} />
              <Card>
                <CardHeader className="my-2 py-2">
                  <h3>Últimos cambios</h3>
                </CardHeader>
                <CardBody className="p-4">
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>FECHA/HORA</th>
                        <th>USUARIO</th>
                        <th>OPERACIÓN</th>
                        <th>TABLA</th>
                        <th>DETALLE</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dataUltimosCambios?.ultimosCambios?.map((cambio) => (
                        <tr key={cambio.id}>
                          <td>{cambio.id}</td>
                          <td>
                            {moment(cambio.fechahora).format(
                              "DD-MM-YYYY HH:mm"
                            )}
                          </td>
                          <td>{cambio.usuario.email}</td>
                          <td>{cambio.operacion}</td>
                          <td>{cambio.tabla}</td>
                          <td>
                            {cambio.detalle.slice(0, 100)}...{" "}
                            <IconButton
                              key={cambio.id}
                              onClick={() => handleOpen(cambio.detalle)}
                            >
                              <Loupe />
                            </IconButton>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
    </Fragment>
  );
};
