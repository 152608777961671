import {
  Book,
  BuildTwoTone,
  CloudCircleSharp,
  DocumentScannerTwoTone,
  List,
  OnlinePredictionSharp,
  PercentOutlined,
  PersonPinRounded,
  ReceiptLong,
  ReceiptTwoTone,
  Tune,
} from "@mui/icons-material";
import { Home, Users } from "react-feather";

export const MENUITEMS = [
  {
    menutitle: "General",
    Items: [
      {
        path: `/dashboard`,
        icon: Home,
        type: "link",
        active: false,
        title: "Dashboard",
      },
      {
        path: `/actives`,
        icon: Book,
        type: "link",
        active: false,
        title: "Activos",
      },
      // {
      //   path: `/documents`,
      //   icon: DocumentScannerOutlined,
      //   type: "link",
      //   active: false,
      //   title: "Documentos",
      // },
      {
        path: `/clients`,
        icon: Users,
        type: "link",
        active: false,
        title: "Clientes",
      },
      {
        path: `/invoices`,
        icon: ReceiptLong,
        type: "link",
        active: false,
        title: "Albaranes/Salidas",
      },
      {
        path: `/orders`,
        icon: List,
        type: "link",
        active: false,
        title: "Pedidos",
      },
      {
        path: `/waybills`,
        icon: ReceiptTwoTone,
        type: "link",
        active: false,
        title: "Albaranes/Entradas",
      },
      {
        path: `/vendors`,
        icon: PersonPinRounded,
        type: "link",
        active: false,
        title: "Proveedores",
      },
    ],
  },

];

export const MENUITEMS_ADMIN = [
  {
    menutitle: "Configuración",
    menucontent: "",
    Items: [
      {
        path: `/users`,
        icon: Users,
        type: "link",
        active: false,
        title: "Usuarios",
      },
      {
        title: 'Configuraciones', icon: Tune, type: 'sub', active: false, children: [
          {
            path: `/sites`,
            icon: BuildTwoTone,
            type: "link",
            active: false,
            title: "Sedes",
          },
          {
            path: `/tipos-igic`,
            icon: PercentOutlined,
            type: "link",
            active: false,
            title: "Tipos IGIC",
          },
          {
            path: `/tipos-activos`,
            icon: CloudCircleSharp,
            type: "link",
            active: false,
            title: "Tipos de Activos",
          },
    
          {
            path: `/tipos-documentos`,
            icon: DocumentScannerTwoTone,
            type: "link",
            active: false,
            title: "Tipos de Documentos",
          },
        ]
    },

    ],
  },
];
