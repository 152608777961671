import React from "react";

export const ActivoFolder = ({ activo }) => {
  return (
    <a
      href={"https://docs.google.com/folder/d/" + activo?.gdrive}
      target="_blank"
      rel="noreferrer noopener"
      className="d-flex   justify-content-center align-items-center"
    >
      <i className="fa fa-folder f-40 txt-warning mx-2"></i>
      <span>Ir a la Carpeta</span>
    </a>
  );
};
