import React, { useEffect, useMemo, useState } from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import { CustomTable } from "../../../../../components/custom-table/CustomTable";
import moment from "moment";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import {
  Close,
  Delete,
  ExpandMore,
  OpenInNew,
  Save,
  Sync,
} from "@mui/icons-material";
import { TableLoading } from "../../../../../components/custom-table/TableLoading";
import { useSelector } from "react-redux";
import { useMutationActives } from "../../hooks/useMutationActives";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import { useTiposDocumento } from "../../../tipos-documentos/hooks/useTiposDocumento";
import { useUpdateDocumento } from "../../hooks/useUpdateDocumento";
import { SaveButton } from "../../../../../components/buttons/SaveButton";

const estadosDocumentos = [
  { id: "E", nombre: "En Elaboración" },
  { id: "F", nombre: "Versión final para imprenta" },
];

export const ActiveDocuments = ({ documentos = [], syncronize, loading }) => {
  const [filtros, setFiltros] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [visibleChangeEstado, setVisibleChangeEstado] = useState(false);
  const [visibleChangeTipo, setVisibleChangeTipo] = useState(false);
  const [documentoAModificar, setDocumentoAModificar] = useState(null);
  const [estadoDocumento, setEstadoDocumento] = useState("E");
  const [tipoDocumento, setTipoDocumento] = useState(1);
  const { data: dataTiposDocumentos } = useTiposDocumento();
  const { updateDocumento } = useUpdateDocumento();

  const { deleteDocumento } = useMutationActives();

  const [state, setState] = useState({
    fechahora: "",
    nombre: "",
    tipo: "",
    autor: "",
    estado: "",
    refGdrive: "",
  });
  const { estadosActivos, tiposActivos } = useSelector(
    (state) => state.GlobalState
  );
  const { activeUser } = useSelector((state) => state.Auth);

  useEffect(() => {
    console.log(activeUser);
  }, [activeUser]);

  useEffect(() => {
    console.log(estadosActivos);
  }, [estadosActivos]);

  useEffect(() => {
    setFilteredData(documentos);
  }, [documentos]);

  const handlerDeleteDocument = (id) => {
    console.log(id);
    confirmAlert({
      title:
        "Este documento no se encuentra ya disponible. ¿Desear eliminarlo?",
      message: "Esta acción es irreversible",
      buttons: [
        {
          label: "Si, ELIMINAR",
          onClick: () => {
            deleteDocumento({
              variables: {
                id,
              },
            })
              .then(({ data }) => {
                if (data?.deleteDocumento) {
                  toast.info("Documento eliminado correctamente");
                  syncronize();
                }
              })
              .catch((error) => {
                toast.error("Error al eliminar el documento. " + error.message);
              });
          },
        },
        {
          label: "No, CANCELAR",
          onClick: () => {},
        },
      ],
    });
  };

  const handleUpdateDocumento = ({ tipo, estado }) => {
    console.log(tipo, estado);
    updateDocumento({
      variables: {
        id: documentoAModificar.id,
        data: {
          tipoId: tipo,
          estado,
        },
      },
    })
      .then(({ data }) => {
        setVisibleChangeEstado(false);
        setVisibleChangeTipo(false);
        syncronize();
      })
      .catch(({ message }) => {
        toast.error("Error al actualizar el documento. " + message);
      });
  };

  const columns = useMemo(() => [
    {
      header: "NOMBRE",
      accessorKey: "nombre",
      accessorFn: (originalRow, index) => {
        return (
          <div className="d-flex">
            {originalRow.noEncontrado ? (
              <div className="text-danger d-flex align-items-center">
                {originalRow.carpeta ? (
                  <i className="fa fa-folder f-24 txt-warning"></i>
                ) : (
                  <i className="fa fa-file f-24 txt-primary"></i>
                )}
                <div className="mx-3 d-flex align-items-center">
                  {activeUser?.isSuperuser && (
                    <IconButton
                      className="mx-1"
                      color="error"
                      onClick={() => handlerDeleteDocument(originalRow.id)}
                    >
                      <Delete />
                    </IconButton>
                  )}
                  <span className="text-decoration-line-through">
                    {originalRow?.nombre}
                  </span>
                </div>
              </div>
            ) : (
              <a
                href={originalRow.url}
                rel="noopener noreferrer"
                target="_blank"
                className="d-flex align-items-center"
              >
                {originalRow.carpeta ? (
                  <i className="fa fa-folder f-24 txt-warning"></i>
                ) : (
                  <i className="fa fa-file f-24 txt-primary"></i>
                )}
                <small className="mx-3 d-flex align-item-center">
                  <OpenInNew className="mx-1" fontSize="0.8rem" />{" "}
                  {originalRow?.nombre}
                </small>
              </a>
            )}
          </div>
        );
      },
    },
    {
      header: "TIPO",
      accessorKey: "tipo",
      accessorFn: (originalRow, index) => {
        return (
          <>
            <span
              className={
                originalRow.noEncontrado
                  ? "text-decoration-line-through text-danger"
                  : ""
              }
            >
              <Button
                size="small"
                onClick={() => {
                  console.log({ originalRow });
                  if (originalRow.noEncontrado) return;
                  setDocumentoAModificar(originalRow);
                  setTipoDocumento(originalRow.tipo ? originalRow.tipo.id : 1);
                  setVisibleChangeTipo(true);
                }}
              >
                {dataTiposDocumentos?.allTiposDocumento.find(
                  (e) => e.id === originalRow.tipo?.id
                )?.nombre || "SIN TIPO DEFINIDO"}
                <ExpandMore />
              </Button>
            </span>
          </>
        );
      },
    },
    {
      header: "ESTADO",
      accessorKey: "estado",
      accessorFn: (originalRow, index) => {
        return (
          <span
            className={
              originalRow.noEncontrado
                ? "text-decoration-line-through text-danger"
                : ""
            }
          >
            <Button
              size="small"
              key={originalRow.id}
              onClick={() => {
                if (originalRow.noEncontrado) return;
                setEstadoDocumento(originalRow.estado);
                setDocumentoAModificar(originalRow);
                setVisibleChangeEstado(true);
              }}
            >
              {
                estadosDocumentos.find((e) => e.id === originalRow.estado)
                  ?.nombre
              }
              <ExpandMore />
            </Button>
          </span>
        );
      },
    },
    {
      header: "F. CREACIÓN",
      accessorKey: "createdTime",
      accessorFn: (originalRow, index) => {
        return (
          <span
            className={
              originalRow.noEncontrado
                ? "text-decoration-line-through text-danger"
                : ""
            }
          >
            {moment(originalRow.createdTime).format("DD/MM/YYYY HH:mm")}
          </span>
        );
      },
    },
    {
      header: "F. MODIF.",
      accessorKey: "modifiedTime",
      accessorFn: (originalRow, index) => {
        return (
          <span
            className={
              originalRow.noEncontrado
                ? "text-decoration-line-through text-danger"
                : ""
            }
          >
            {moment(originalRow.modifiedTime).format("DD/MM/YYYY HH:mm")}
          </span>
        );
      },
    },
    {
      header: "MODIFICADO POR",
      accessorKey: "modifiedUser",
      accessorFn: (originalRow, index) => {
        return (
          <span
            className={
              originalRow.noEncontrado
                ? "text-decoration-line-through text-danger"
                : ""
            }
          >
            {originalRow.modifiedUser}
          </span>
        );
      },
    },
  ]);

  return (
    <>
      {/* Modal para cambiar estado documento */}
      <Dialog
        disableEscapeKeyDown
        fullWidth
        maxWidth="sm"
        open={visibleChangeEstado}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            setVisibleChangeEstado(false);
          }
        }}
      >
        <DialogTitle className="d-flex justify-content-between">
          <span id="modal-title">Cambiar Estado</span>
          <Button onClick={() => setVisibleChangeEstado(false)}>
            <Close />
          </Button>
        </DialogTitle>
        <DialogContent>
          <Select
            fullWidth
            label="Estado"
            value={estadoDocumento}
            onChange={(e) => {
              setEstadoDocumento(e.target.value);
            }}
          >
            {estadosDocumentos.map((e) => (
              <MenuItem key={e.id} value={e.id}>
                {e.nombre}
              </MenuItem>
            ))}
          </Select>
        </DialogContent>
        <DialogActions className="d-flex justify-content-between">
          <Button
            color="error"
            onClick={() => setVisibleChangeEstado(false)}
            type="button"
            size="large"
            className="btn-air-danger mx-2"
          >
            Cancelar
          </Button>
          <Button
            color="success"
            type="submit"
            size="large"
            className="btn-air-success mx-2"
            endIcon={<Save />}
            onClick={() => {
              handleUpdateDocumento({ estado: estadoDocumento });
            }}
          >GUARDAR</Button>
        </DialogActions>
      </Dialog>
      {/* Fin Modal para cambiar estado documento */}
      {/* Modal para cambiar tipo documento */}
      <Dialog
        disableEscapeKeyDown
        fullWidth
        maxWidth="sm"
        open={visibleChangeTipo}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            setVisibleChangeTipo(false);
          }
        }}
      >
        <DialogTitle className="d-flex justify-content-between">
          <span id="modal-title">Cambiar Tipo</span>
          <Button onClick={() => setVisibleChangeTipo(false)}>
            <Close />
          </Button>
        </DialogTitle>
        <DialogContent>
          <Select
            label="Tipo"
            fullWidth
            value={tipoDocumento}
            onChange={(e) => setTipoDocumento(e.target.value)}
          >
            {dataTiposDocumentos?.allTiposDocumento.map((tipo) => (
              <MenuItem key={tipo.id} value={tipo.id}>
                {tipo.nombre}
              </MenuItem>
            ))}
          </Select>
        </DialogContent>
        <DialogActions className="d-flex justify-content-between">
          <Button
            color="error"
            onClick={() => setVisibleChangeTipo(false)}
            type="button"
            size="large"
            className="btn-air-danger mx-2"
          >
            Cancelar
          </Button>
          <Button
            color="success"
            type="submit"
            size="large"
            className="btn-air-success mx-2"
            endIcon={<Save />}
            onClick={() => {
              handleUpdateDocumento({ tipo: tipoDocumento });
              setVisibleChangeTipo(false);
            }}
          >GUARDAR</Button>
        </DialogActions>
      </Dialog>
      {/* Fin Modal para cambiar tipo documento */}

      <Card>
        <CardHeader className="d-flex align-items-center justify-content-between py-2 my-2">
          <h6>Documentos</h6>
          <Button
            variant="contained"
            color="primary"
            startIcon={loading ? "" : <Sync />}
            onClick={syncronize}
            disabled={loading}
          >
            {loading ? (
              <span className="d-flex align-items-center">
                <CircularProgress className="mx-2" size={"1rem"} />{" "}
                Sincronizando...
              </span>
            ) : (
              "Sincronizar"
            )}
          </Button>
        </CardHeader>
        <CardBody className="p-2">
          {loading ? (
            <TableLoading rows={5} />
          ) : (
            <CustomTable
              manualFiltersPagination={false}
              data={filteredData}
              columns={columns}
              estadoFiltros={filtros}
              setfiltros={setFiltros}
              viewTableOptions={false}
            />
          )}
        </CardBody>
      </Card>
    </>
  );
};
