import { combineReducers } from 'redux'
import Customizer from './customizer/reducer'
import Auth from './auth/reducer'
import GlobalState from './global/reducer'

const reducers = combineReducers({
    Customizer,
    Auth,
    GlobalState
});

export default reducers;