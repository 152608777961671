import {
  SET_TIPOS_ACTIVOS,
  SET_TIPOS_IGIC,
  SET_ESTADOS_ACTIVOS,
  SET_ESTADOS_MERCANCIA,
  SET_TIEMPOS_ENTREGA,
} from "../actionTypes";

const initialState = {
  loading: false,
  tiposActivos: [],
  tiposIgic: [],
  estadosActivos: [],
  estadosMercancias: [],
  tiemposEntrega: [],
};

const globalState = (state = initialState, action) => {
  switch (action.type) {
    case SET_TIPOS_ACTIVOS:
      state.tiposActivos = action.payload;
      return { ...state };
    case SET_TIPOS_IGIC:
      state.tiposIgic = action.payload;
      return { ...state };
    case SET_ESTADOS_ACTIVOS:
      state.estadosActivos = action.payload;
      return { ...state };
    case SET_ESTADOS_MERCANCIA:
      state.estadosMercancias = action.payload;
      return { ...state };
    case SET_TIEMPOS_ENTREGA:
      state.tiemposEntrega = action.payload;
      return { ...state };
    default:
      return { ...state };
  }
};

export default globalState;
