import { TextField } from "@mui/material";
import React, { useState } from "react";
import { ClearFilterButton } from "../buttons";

export const TextFilter = ({ filtros, setFiltros, removeFilter, campo }) => {
  const [value, setValue] = useState("");
  const handleFiltro = (_filtro) => {
    const filtrosTemp = filtros.filtros.filter(
      (f) => f.campo !== _filtro.campo
    );

    filtrosTemp.push({
      campo: _filtro.campo,
      valor: _filtro.valor,
      tipo: _filtro.tipo,
    });

    setFiltros({
      ...filtros,
      filtros: [...filtrosTemp],
    });
  };
  return (
    <div className="d-flex">
      <TextField
        variant="standard"
        value={value}
        fullWidth
        size="small"
        type="text"
        onChange={(e) => {
          setValue(e.target.value ? e.target.value : "");
          handleFiltro({
            valor: e.target.value,
            tipo: "text",
            campo,
          });
        }}
      />
      <ClearFilterButton
        onClick={(e) => {
          setValue("");
          removeFilter(campo);
        }}
      />
    </div>
  );
};
