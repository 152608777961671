import { TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { SaveButton } from "../../../../components";
import { useTipoDocumentoMutation } from "../hooks/useTipoDocumentoMutation";
import { useTipoDocumento } from "../hooks/useTipoDocumento";
import { toast } from "react-toastify";

export const TipoDocumentoFormView = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [tipo, setTipo] = useState({});
  const { getTipoDocumento } = useTipoDocumento();
  const { createTipoDocumento, updateTipoDocumento } =
    useTipoDocumentoMutation();
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
  } = useForm();

  useEffect(() => {
    if (id) {
      getTipoDocumento({ variables: { id } })
        .then(({ data }) => {
          if (data?.tipodocumento) {
            setTipo(data.tipodocumento);
            setValue("nombre", data.tipodocumento.nombre);
          }
        })
        .catch((error) => {
          toast.error(error.message);
        });
    }
  }, [id]);

  const isFocused = (field) => {
    return id !== undefined && tipo[field] !== undefined && tipo[field] !== "";
  };

  const onSubmit = (formData) => {
    if (!id) {
      createTipoDocumento({ variables: { data: { ...formData } } })
        .then(({ data }) => {
          if (data.createTipodocumento.ok) {
            toast.info("Tipo de documento creado");
            navigate("/tipos-documentos", { replace: true });
          } else {
            toast.error("Error al crear el tipo de documento");
          }
        })
        .catch((error) => {
          toast.error(error.message);
        });
    } else {
      updateTipoDocumento({ variables: { id, data: { ...formData } } })
        .then(({ data }) => {
          if (data.updateTipodocumento.ok) {
            toast.info("Tipo de documento actualizado");
          } else {
            toast.error("Error al actualizar el tipo de documentop");
          }
        })
        .catch((error) => {
          toast.error(error.message);
        });
    }
  };

  return (
    <Container fluid={true}>
      <Row>
        <Col sm="6">
          <Card>
            <CardBody>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Row className="mb-3">
                  <Col sm="12">
                    <h6>{id ? "Editar" : "Nuevo"} tipo</h6>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <TextField
                      fullWidth
                      size="small"
                      label="NOMBRE"
                      name="nombre"
                      {...register("nombre", { required: true })}
                      helperText={errors.nombre && "El nombre es requerido"}
                      error={errors.nombre ? true : false}
                      focused={isFocused("nombre")}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="d-flex justify-content-end">
                    <SaveButton />
                  </Col>
                </Row>
              </form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
