import { useEffect, useMemo, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { useMutationsVendor } from "../hooks/useMutationsVendor";
import { useVendors } from "../hooks/useVendors";
import { DeleteButton, EditButton, LOCALE_TABLE } from "../../../../components";
import { toast } from "react-toastify";
import { TableLoading } from "../../../../components/custom-table/TableLoading";

import MaterialReactTable from "material-react-table";

import { Button } from "@mui/material";
import { Box } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { ExportToCsv } from "export-to-csv";

export const VendorListView = () => {
  const [proveedores, setProveedores] = useState([]);
  const { loading, dataProveedores } = useVendors();
  const { deleteProveedor } = useMutationsVendor();

  const columns = useMemo(() => [
    {
      header: "",
      accessorKey: "id",
      enableColumnActions: false,
      enableFilters: false,
      enableMultiSort: false,
      enableColumnFilter: false,
      enableColumnFilterChangeMode: false,
      id: "actionsEdit",
      size: 30,
      Cell: ({ cell }) => (
        <div className="text-center">
          <EditButton entity="vendors" id={cell.getValue()} />
        </div>
      ),
    },
    {
      header: "ID",
      accessorKey: "id",
      id: "proveedorId",
      size: 40,
    },

    {
      header: "NOMBRE",
      accessorKey: "nombre",
    },
    {
      header: "TELÉFONO",
      accessorKey: "telefono",
    },
    {
      header: "URL",
      accessorKey: "url",
    },
    {
      header: "",
      accessorKey: "id",
      enableColumnActions: false,
      enabaleFilters: false,
      enableColumnFilters: false,
      id: "actions",
      Cell: ({ cell }) => (
        <div className="text-end">
          <DeleteButton onClick={() => handleDeleteInvoice(cell.getValue())} />
        </div>
      ),
    },
  ]);

  const csvOptions = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers: columns.map((c) => c.header),
  };

  const csvExporter = new ExportToCsv(csvOptions);

  useEffect(() => {
    if (dataProveedores) {
      setProveedores(dataProveedores.allProveedores.items);
    }
  }, [dataProveedores]);

  const handleDeleteInvoice = (id) => {
    confirmAlert({
      title: "¿Está seguro de eliminar esta factura?",
      message: "Esta acción es irreversible",
      buttons: [
        {
          label: "Si, ELIMINAR",
          onClick: () => {
            deleteProveedor({
              variables: {
                id,
              },
            }).then(({ data }) => {
              if (data.deleteProveedor.ok) {
                toast.info("Proveedor eliminado con éxito");
              } else {
                toast.error("Error al eliminar el proveedor");
              }
            });
          },
        },
        {
          label: "No",
          onClick: () => null,
        },
      ],
    });
  };

  const handleExportRows = (rows) => {
    csvExporter.generateCsv(rows.map((row) => row.original));
  };

  const handleExportData = () => {
    csvExporter.generateCsv(proveedores);
  };

  return (
    <>
      {dataProveedores ? (
        <MaterialReactTable
          columns={columns}
          data={proveedores}
          localization={LOCALE_TABLE}
          enableRowSelection
          enableColumnFilterModes
          enableGlobalFilterModes
          enableDensityToggle={false}
          state={{
            density: "compact",
            isLoading: loading,
          }}
          initialState={{
            pagination: {
              pageSize: 30,
            },
          }}
          muiTableProps={{
            sx: {
              tableLayout: "auto",
            },
          }}
          muiTablePaginationProps={{
            rowsPerPageOptions: [30, 50, 100],
            SelectProps: { native: true },
          }}

          renderTopToolbarCustomActions={({ table }) => (
            <Box>
              <Button
                color="primary"
                className="btn-xs btn-air-primary mx-1"
                //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                onClick={handleExportData}
              >
                <FileDownloadIcon /> Exportar todo
              </Button>
              <Button
                color="primary"
                className="btn-xs btn-air-primary mx-1"
                disabled={table.getRowModel().rows.length === 0}
                //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
                onClick={() => handleExportRows(table.getRowModel().rows)}
              >
                <FileDownloadIcon /> Exportar página
              </Button>
              <Button
                color="primary"
                className="btn-xs btn-air-primary mx-1"
                disabled={
                  !table.getIsSomeRowsSelected() &&
                  !table.getIsAllRowsSelected()
                }
                //only export selected rows
                onClick={() =>
                  handleExportRows(table.getSelectedRowModel().rows)
                }
              >
                <FileDownloadIcon /> Exportar selección
              </Button>
            </Box>
          )}
        ></MaterialReactTable>
      ) : (
        <TableLoading />
      )}
    </>
  );
};
