import { useEffect, useMemo, useState } from "react";

import { confirmAlert } from "react-confirm-alert";
import { DeleteButton } from "../../../../components/buttons/DeleteButton";
import { EditButton } from "../../../../components/buttons/EditButton";
import { useActives } from "../hooks/useActives";
import { useMutationActives } from "../hooks/useMutationActives";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import moment from "moment";
import { TableLoading } from "../../../../components/custom-table/TableLoading";
import MaterialReactTable from "material-react-table";
import { LOCALE_TABLE } from "../../../../components/custom-table/CustomTable";
import { Button, Tooltip } from "@mui/material";
import { Box } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { ExportToCsv } from "export-to-csv";

export const ActiveListView = () => {
  const [activos, setActivos] = useState([]);
  const { estadosActivos } = useSelector((state) => state.GlobalState);
  const { data, loading } = useActives();
  const { deleteActivo } = useMutationActives();

  const columns = useMemo(() => [
    {
      header: "",
      accessorKey: "id",
      enableColumnActions: false,
      enableFilters: false,
      enableMultiSort: false,
      enableColumnFilter: false,
      enableColumnFilterChangeMode: false,
      id: "editAction",
      size: 30,
      Cell: ({ cell }) => (
        <div className="text-center">
          <EditButton entity="actives" id={cell.getValue()} />
        </div>
      ),
    },
    {
      header: "FECHA ALTA",
      accessorKey: "fechaAlta",
      sortingFn: (e) => console.log("sorting", e),

      Cell: ({ cell }) => {
        return moment(cell.getValue()).format("DD/MM/YYYY");
      },
    },
    {
      header: "TÍTULO",
      accessorKey: "titulo",
      Cell: ({ cell }) => {
        const titulo = cell.getValue();
        return (
          <Tooltip title={titulo} placement="top-start">
            <span>
              {titulo.slice(0, 50)} {titulo.length > 50 ? "..." : ""}
            </span>
          </Tooltip>
        );
      },
    },
    {
      header: "ESTADO",
      accessorKey: "estado",
      Cell: ({ cell }) => (
        <span>
          {estadosActivos.find((e) => e.id === cell.getValue())?.nombre}
        </span>
      ),
    },
    {
      header: "ISBN",
      accessorKey: "isbn",
    },

    {
      header: "TIPO",
      accessorKey: "tipo.nombre",
    },
    {
      header: "COLOR",
      accessorKey: "color",
      Cell: ({ cell }) => (
        <div>
          {cell.getValue() ? (
            <span className="text-info">SI</span>
          ) : (
            <span className="text-danger">NO</span>
          )}
        </div>
      ),
    },
    {
      header: "ENCUADERNACIÓN",
      accessorKey: "tipoEncuadernacion",
    },
    {
      header: "",
      accessorKey: "id",
      enableColumnActions: false,
      enableFilters: false,
      enableMultiSort: false,
      enableColumnFilter: false,
      enableColumnFilterChangeMode: false,
      id: "actions",
      size: 30,
      Cell: ({ cell }) => (
        <div className="text-end">
          <DeleteButton onClick={() => handleDeleteClient(cell.getValue())} />
        </div>
      ),
    },
  ]);

  const csvOptions = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers: columns.map((c) => c.header),
  };

  const csvExporter = new ExportToCsv(csvOptions);

  useEffect(() => {
    if (data?.allActivos) {
      setActivos(data?.allActivos?.items || []);
    }
  }, [data]);

  const handleDeleteClient = (id) => {
    confirmAlert({
      title: "¿Está seguro de eliminar este activo?",
      message: "Esta acción es irreversible",
      buttons: [
        {
          label: "Si, ELIMINAR",
          onClick: () => {
            deleteActivo({
              variables: {
                id,
              },
            })
              .then(({ data }) => {
                if (data.deleteActivo.ok) {
                  toast.info("Activo eliminado correctamente");
                  // refetch({
                  //   filtros: filtros.filtros,
                  //   order: filtros.order,
                  //   skip: filtros.skip,
                  // }).then(({ data }) => {
                  //   console.log(data);
                  //   setActivos(data?.allActivos?.items || []);
                  //   setNumItems(data?.allActivos?.numitems);
                  // });
                } else {
                  toast.error("Error al eliminar el activo");
                }
              })
              .catch((error) => {
                toast.error("Error al eliminar el activo. " + error.message);
              });
          },
        },
        {
          label: "No",
          onClick: () => null,
        },
      ],
    });
  };

  const handleExportRows = (rows) => {
    csvExporter.generateCsv(rows.map((row) => row.original));
  };

  const handleExportData = () => {
    csvExporter.generateCsv(data);
  };

  return (
    <>
      {data ? (
        <MaterialReactTable
          columns={columns}
          data={activos}
          localization={LOCALE_TABLE}
          enableRowSelection
          enableColumnFilterModes
          enableGlobalFilterModes
          enableDensityToggle={false}
          state={{
            density: "compact",
            isLoading: loading,
          }}
          initialState={{
            pagination: {
              pageSize: 30,
            },
          }}
          muiTableProps={{
            sx: {
              tableLayout: "auto",
            },
          }}
          muiTablePaginationProps={{
            rowsPerPageOptions: [30, 50, 100],
            SelectProps: { native: true },
          }}

          renderTopToolbarCustomActions={({ table }) => (
            <Box>
              <Button
                color="primary"
                className="btn-xs btn-air-primary mx-1"
                //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                onClick={handleExportData}
              >
                <FileDownloadIcon /> Exportar todo
              </Button>
              <Button
                color="primary"
                className="btn-xs btn-air-primary mx-1"
                disabled={table.getRowModel().rows.length === 0}
                //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
                onClick={() => handleExportRows(table.getRowModel().rows)}
              >
                <FileDownloadIcon /> Exportar página
              </Button>
              <Button
                color="primary"
                className="btn-xs btn-air-primary mx-1"
                disabled={
                  !table.getIsSomeRowsSelected() &&
                  !table.getIsAllRowsSelected()
                }
                //only export selected rows
                onClick={() =>
                  handleExportRows(table.getSelectedRowModel().rows)
                }
              >
                <FileDownloadIcon /> Exportar selección
              </Button>
            </Box>
          )}
        ></MaterialReactTable>
      ) : (
        <TableLoading />
      )}
    </>
  );
};
