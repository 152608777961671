import { gql } from "@apollo/client";

const CREATE_CLIENTE = gql`
  mutation createCliente($data: ClienteInput!) {
    createCliente(data: $data) {
      ok
      cliente {
        id

        identificacion
        nombre

        email
        telefono
      }
    }
  }
`;

const UPDATE_CLIENTE = gql`
  mutation updateCliente($id: Int!, $data: ClienteUpdateInput!) {
    updateCliente(id: $id, data: $data) {
      ok
      cliente {
        id
        identificacion
        nombre
        email
        telefono
      }
    }
  }
`;

const DELETE_CLIENTE = gql`
  mutation deleteCliente($id: Int!) {
    deleteCliente(id: $id) {
      ok
    }
  }
`;

export { CREATE_CLIENTE, UPDATE_CLIENTE, DELETE_CLIENTE };
