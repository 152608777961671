import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { SiteFormView } from "../views/SiteFormView";
import { SiteListView } from "../views/SiteListView";

export const SiteRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<SiteListView />} />
      <Route path="create" element={<SiteFormView />} />
      <Route path=":id" element={<SiteFormView />} />
      <Route path="/*" element={<Navigate to="sites" />} />
    </Routes>
  );
};
