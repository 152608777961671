import { Skeleton } from "@mui/material";
import React from "react";

export const TableLoading = ({ rows = 15 }) => {
  return (
    <>
      {[...Array(rows)].map((v, i) => (
        <Skeleton variant="rect" width="100%" className="my-3" key={i} />
      ))}
    </>
  );
};
