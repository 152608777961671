import { useQuery } from "@apollo/client";
import { QUERY_ALL_ACTIVOS } from "../graphql/queries";

export const useActives = () => {
  const { data, loading, refetch } = useQuery(QUERY_ALL_ACTIVOS);
  return {
    data,
    loading,
    refetch,
  };
};
