import { useQuery } from "@apollo/client";
import { QUERY_ALL_PEDIDOS } from "../graphql/queries";

export const useOrders = () => {
  const {
    data: dataPedidos,
    loading,
    error,
    refetch,
  } = useQuery(QUERY_ALL_PEDIDOS);
  return {
    dataPedidos,
    loading,
    refetch,
  };
};
