import { useMutation } from "@apollo/client";
import { MUTATION_CREATE_SEDE, MUTATION_UPDATE_SEDE } from "../graphql/mutations";
import { QUERY_SEDES } from "../graphql/queries";

export const useSiteMutations = () => {
  const [createSede, { data }] = useMutation(MUTATION_CREATE_SEDE, {
    refetchQueries: [{ query: QUERY_SEDES }],
  });
  const [updateSede, { data: updateData }] = useMutation(MUTATION_UPDATE_SEDE, {
    refetchQueries: [{ query: QUERY_SEDES }],
  });

  return {
    createSede,
    updateSede,
    data,
    updateData,
  };
};
