import { useQuery } from "@apollo/client";
import { QUERY_ALL_PROVEEDORES } from "../graphql/queries";

export const useVendors = () => {
  const {
    data: dataProveedores,
    loading,
    error,
    refetch,
  } = useQuery(QUERY_ALL_PROVEEDORES);
  return {
    dataProveedores,
    loading,
    refetch,
  };
};
