import { Save } from "@mui/icons-material";
import { Button } from "@mui/material";

export const SaveButton = ({ onClick }) => {
  return (
    <Button
      color="success"
      type="submit"
      size="large"
      className="btn-air-success mx-2"
      endIcon={<Save />}
      onClick={onClick}
    >
      Guardar
    </Button>
  );
};
