import { useLazyQuery } from "@apollo/client";
import { QUERY_USER } from "../graphql/queries";

export const useUser = () => {
  const [getUser] = useLazyQuery(QUERY_USER, {
    fetchPolicy: "network-only",
  });
  return {
    getUser,
  };
};
