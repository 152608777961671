import { gql, useMutation } from "@apollo/client";
import React from "react";

const TOKENAUTH = gql`
  mutation tokenAuth($email: String, $password: String!) {
    tokenAuth(password: $password, email: $email) {
      token
      errors
      success
      user {
        id
        email
      }
    }
  }
`;

export const useLogin = () => {
  const [tokenAuth, { data, user }] = useMutation(TOKENAUTH,  {
    fetchPolicy: "no-cache",
  });
  return {
    tokenAuth,
    data,
    user,
  };
};
