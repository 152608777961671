import React from "react";
import { Container } from "reactstrap";
import Breadcrumbs from "../../../layout/breadcrumb";
import { TipoIgicRouter } from "./router/TipoIgicRouter";

export const TipoIgicPage = () => {
  return (
    <>
      <Breadcrumbs title="Tipos Igic" entidad="tipos-igic" parent="Tipos Igic" addOption={false}/>
      <Container fluid={true}>
        <div className="faq-wrap">
          <TipoIgicRouter />
        </div>
      </Container>
    </>
  );
};
