import React, { Fragment, useEffect } from "react";
import Loader from "../layout/loader";
import Taptop from "../layout/tap-top";
import Header from "../layout/header";
import Sidebar from "../layout/sidebar";
import Footer from "../layout/footer";
// import ThemeCustomize from "../layout/theme-customizer";
import { toast, ToastContainer } from "react-toastify";
import { Outlet } from "react-router-dom";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useTiposActivo } from "../pages/full-pages/tipo-activo/hooks/useTiposActivo";
import { useTiposIgic } from "../pages/full-pages/tipo-igic/hooks/useTiposIgic";
import { useDispatch } from "react-redux";
import {
  SET_TIPOS_ACTIVOS,
  SET_TIPOS_IGIC,
  SET_ESTADOS_ACTIVOS,
  SET_ESTADOS_MERCANCIA,
  SET_TIEMPOS_ENTREGA,
  LOGOUT,
  SET_ACTIVE_USER,
} from "../redux/actionTypes";
import { useGetEnums } from "../hooks/useGetEnums";
import { useUserVerified } from "../hooks/auth/useUserVerified";

const AppLayout = ({ children, classNames, ...rest }) => {
  const dispatch = useDispatch();
  const { data: dataTiposActivos } = useTiposActivo();
  const { data: dataTiposIgic } = useTiposIgic();
  const { enumEstados, enumEstadosMercancias, enumTiemposEntrega } =
    useGetEnums();
  const { getMe } = useUserVerified();

  useEffect(() => {
    getMe()
      .then(({ data }) => {
        if (data?.me2) {
          dispatch({ type: SET_ACTIVE_USER, payload: data.me2 });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (dataTiposActivos) {
      dispatch({
        type: SET_TIPOS_ACTIVOS,
        payload: dataTiposActivos?.allTiposActivo,
      });
    }
  }, [dataTiposActivos]);

  useEffect(() => {
    if (dataTiposIgic) {
      dispatch({ type: SET_TIPOS_IGIC, payload: dataTiposIgic?.allTiposIgic });
    }
  }, [dataTiposIgic]);

  useEffect(() => {
    if (enumEstados) {
      const ESTADOS = enumEstados?.__type.enumValues.map((e) => {
        return {
          id: e.name,
          nombre: e.description,
          text: e.description,
          value: e.name,
        };
      });
      dispatch({ type: SET_ESTADOS_ACTIVOS, payload: ESTADOS });
    }
  }, [enumEstados]);

  useEffect(() => {
    if (enumEstadosMercancias) {
      const ESTADOS_MERCANCIAS = enumEstadosMercancias?.__type.enumValues.map(
        (e) => {
          return {
            id: e.name,
            nombre: e.description,
            text: e.description,
            value: e.name,
          };
        }
      );
      dispatch({ type: SET_ESTADOS_MERCANCIA, payload: ESTADOS_MERCANCIAS });
    }
  }, [enumEstadosMercancias]);

  useEffect(() => {
    if (enumTiemposEntrega) {
      const TIEMPOS = enumTiemposEntrega?.__type.enumValues.map((e) => {
        return {
          id: e.name,
          nombre: e.description,
          text: e.description,
          value: e.name,
        };
      });
      dispatch({ type: SET_TIEMPOS_ENTREGA, payload: TIEMPOS });
    }
  }, [enumTiemposEntrega]);

  return (
    <Fragment>
      <ToastContainer />
      <Loader />
      <Taptop />
      <div className="page-wrapper compact-wrapper" id="pageWrapper">
        <Header />
        <div className="page-body-wrapper">
          <Sidebar />
          <div className="page-body">
            <div>
              <Outlet />
            </div>
          </div>
          <Footer />
        </div>
      </div>
      {/* <ThemeCustomize /> */}
    </Fragment>
  );
};

export default AppLayout;
