import { gql, useMutation } from "@apollo/client";

const mutation = gql`
  mutation googleAuth($accessToken: String!) {
    socialAuth(provider: "google-oauth2", accessToken: $accessToken) {
      token
    }
  }
`;

export const useLoginWithGoogle = () => {
  const [googleAuth, { data: google_response }] = useMutation(mutation, {
    fetchPolicy: "no-cache",
  });
  return { googleAuth, google_response };
};
