import { useLazyQuery } from "@apollo/client";

import { CLIENTE } from "../graphql/queries";

export const useClient = () => {
  const [getClient, { loading, data, error }] = useLazyQuery(CLIENTE);
  return {
    getClient,
    loading,
    data,
    error,
  };
};
