import { useQuery } from "@apollo/client";
import { ESTADOS_MERCANCIA, TIEMPOS_ENTREGA, TIPOS_ESTADOS } from "../graphql/globalQuerys";

export const useGetEnums = () => {
  const { data: enumEstados } = useQuery(TIPOS_ESTADOS);
  const {data: enumEstadosMercancias} = useQuery(ESTADOS_MERCANCIA);
  const {data: enumTiemposEntrega} = useQuery(TIEMPOS_ENTREGA);
  return {
    enumEstados,
    enumEstadosMercancias,
    enumTiemposEntrega
  };
};
