import { useQuery, useLazyQuery } from "@apollo/client";
import { ALL_CLIENTES } from "../graphql/queries";

export const useClients = () => {
  const { loading, data, error, refetch } = useQuery(ALL_CLIENTES);
  const [getClients, { data: _dataLazy, loading: _loading, error: _error }] = useLazyQuery(ALL_CLIENTES);

  return {
    loading,
    data,
    error,
    getClients,
    _dataLazy,
    refetch
  };
};
