import React from "react";
import { useLocation } from "react-router";
import { Container } from "reactstrap";
import Breadcrumbs from "../../../layout/breadcrumb";
import { ActiveRouter } from "./router/ActiveRouter";

export const ActivePage = () => {
  const location = useLocation();

  return (
    <>
      <Breadcrumbs title="Activos" entidad="actives" parent="Activos" />
      <Container fluid={true}>
        <div className="faq-wrap">
          <ActiveRouter />
        </div>
      </Container>
    </>
  );
};
