import { gql } from "@apollo/client";

const ALL_FACTURAS = gql`
  query AllFacturas(
    $skip: Int
    $order: String
    $filtros: [FiltroType]
    $search: String
    $filtrosOr: [FiltroType]
  ) {
    allFacturas(
      first: 15
      skip: $skip
      order: $order
      filtros: $filtros
      search: $search
      filtrosOr: $filtrosOr
    ) {
      numitems
      items {
        id
        numero
        sede {
          id
          nombre
        }
        cliente {
          id
          identificacion
          nombre
        }
        fecha
        identificacion
        direccion
        telefono
        concepto
        total
        baseimp
        igic
        idgrupo
      }
    }
  }
`;

const FACTURA = gql`
  query Factura($id: Int!) {
    factura(id: $id) {
      id
      numero
      cliente {
        id
        identificacion
        nombre
      }
      fecha
      identificacion
      direccion
      telefono
      concepto
      total
      baseimp
      igic
      idgrupo
      sede {
        id
        nombre
      }
      facturalineaSet {
        id
        numlinea
        concepto
        activo {
          id
          titulo
        }
        activo {
          id
          titulo
        }
        cantidad
        precioUnidad
        baseimp
        tipoigic {
          id
          valor
          nombre
        }
        igic
        total
      }
    }
  }
`;

const CALCULO_FACTURALINEA = gql`
  query calculoFacturalinea(
    $idtipoigic: Int!
    $cantidad: Int!
    $precioUnidad: Float!
  ) {
    calculoFacturalinea(
      idtipoigic: $idtipoigic
      cantidad: $cantidad
      precioUnidad: $precioUnidad
    ) {
      total
      pigic
      igic
      baseimp
    }
  }
`;

export { ALL_FACTURAS, FACTURA, CALCULO_FACTURALINEA };
