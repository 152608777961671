import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { TipoDocumentoFormView } from "../views/TipoDocumentoFormView";
import { TipoDocumentoListView } from "../views/TipoDocumentoListView";

export const TipoDocumentoRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<TipoDocumentoListView />} />
      <Route path="create" element={<TipoDocumentoFormView />} />
      <Route path=":id" element={<TipoDocumentoFormView />} />
      <Route path="/*" element={<Navigate to="tipos-documentos" />} />
    </Routes>
  );
};
