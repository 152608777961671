import { useMutation } from "@apollo/client";
import { UPDATE_DOCUMENTO } from "../graphql/mutations";

export const useUpdateDocumento = () => {
  const [updateDocumento, { data, loading, error }] =
    useMutation(UPDATE_DOCUMENTO);
  return {
    updateDocumento,
  };
};
