import { Stack, Switch, TextField, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { SaveButton } from "../../../../components/buttons";
import { useUserMutations } from "../hooks/useUserMutations";
import { toast } from "react-toastify";
import { useUser } from "../hooks/useUser";

export const UserFormView = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [user, setUser] = useState({});
  const password = useRef({});
  const [activo, setActivo] = useState(true);

  const {
    handleSubmit,
    setValue,
    register,
    formState: { errors },
    getValues,
  } = useForm();
  const { createUser, updateUser } = useUserMutations();
  const { getUser } = useUser();

  useEffect(() => {
    if (id) {
      getUser({
        variables: {
          email: id,
        },
      }).then(({ data }) => {
        setUser(data.user2);
        setValue("email", data.user2.email);
        setValue("firstName", data.user2.firstName);
        setValue("lastName", data.user2.lastName);
        setActivo(data.user2.isActive);
      });
    }
  }, [id]);

  const isFocused = (field) => {
    return id !== undefined && user[field] !== undefined && user[field] !== "";
  };

  const onSubmit = (formData) => {
    console.log(formData);
    if (!id) {
      createUser({
        variables: {
          data: {
            email: formData.email,
            isActive: activo,
            firstName: formData.firstName,
            lastName: formData.lastName,
            password: formData.password,
          },
        },
      }).then(({ data }) => {
        console.log(data);
        if (data.createUser.ok) {
          toast.info("Usuario creado correctamente");
          navigate("/users", { replace: true });
        }
      });
    } else {
      updateUser({
        variables: {
          email: id,
          data: {
            isActive: activo,
            firstName: formData.firstName,
            lastName: formData.lastName,
          },
        },
      }).then(({ data }) => {
        if (data.updateUser.ok) {
          toast.info("Usuario actualizado correctamente");
        }
      });
    }
  };

  return (
    <>
      <Container fluid={true}>
        <Row>
          <Col sm="12" md={8}>
            <Card>
              <CardBody>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Row>
                    <Col sm="12">
                      <h6>{id ? "Editar" : "Nuevo"} usuario</h6>
                    </Col>
                  </Row>
                  <Row className="d-flex align-items-end">
                    <Col sm="12" md={8}>
                      <TextField
                        size="small"
                        fullWidth
                        name="email"
                        label="Email"
                        type="email"
                        focused={isFocused("email")}
                        {...register("email", { required: true })}
                        helperText={errors.email ? "Campo obligatorio" : ""}
                        error={errors.email?.type === "required"}
                      />
                    </Col>
                    <Col
                      sm={12}
                      md={4}
                      className="d-flex justify-content-center flex-column align-items-center"
                    >
                      <span>ACTIVO</span>
                      <Stack direction="row" spacing={1} alignItems="start">
                        <Typography>NO</Typography>
                        <Switch
                          checked={activo}
                          value={activo}
                          onChange={(event) => {
                            setActivo(event.target.checked);
                            setValue("isActive", event.target.checked);
                          }}
                          inputProps={{ "aria-label": "ant design" }}
                        />
                        <Typography>SI</Typography>
                      </Stack>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12} md={6}>
                      <TextField
                        size="small"
                        fullWidth
                        name="firstName"
                        label="Nombre"
                        focused={isFocused("firstName")}
                        {...register("firstName")}
                      />
                    </Col>
                    <Col sm={12} md={6}>
                      <TextField
                        size="small"
                        fullWidth
                        name="lastName"
                        label="Apellido"
                        focused={isFocused("lastName")}
                        {...register("lastName")}
                      />
                    </Col>
                  </Row>
                  {!id && (
                    <>
                      <Row>
                        <Col>
                          <TextField
                            size="small"
                            fullWidth
                            name="password"
                            label="Contraseña"
                            type="password"
                            {...register("password", {
                              required: true,
                            })}
                            helperText={
                              errors.password ? "Campo obligatorio" : ""
                            }
                            error={errors.password?.type === "required"}
                          />
                        </Col>
                        <Col>
                          <TextField
                            size="small"
                            fullWidth
                            name="password_repeat"
                            label="Confirmar contraseña"
                            type="password"
                            {...register("password_repeat", {
                              required: true,
                              validate: (value) =>
                                value === getValues("password") ||
                                "Las contraseñas no coinciden",
                            })}
                            helperText={
                              errors.password_repeat
                                ? errors.password_repeat?.type === "validate"
                                  ? "No coinciden"
                                  : "Campo obligatorio"
                                : ""
                            }
                            error={
                              errors.password_repeat?.type === "validate" ||
                              errors.password_repeat?.type === "required"
                            }
                          />
                        </Col>
                      </Row>
                    </>
                  )}
                  <Row>
                    <Col className="d-flex justify-content-end">
                      <SaveButton />
                    </Col>
                  </Row>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};
