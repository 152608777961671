import { useMutation } from "@apollo/client";
import {
  MUTATION_CREATE_PROVEEDOR,
  MUTATION_DELETE_PROVEEDOR,
  MUTATION_UPDATE_PROVEEDOR,
} from "../graphql/mutations";
import { QUERY_ALL_PROVEEDORES } from "../graphql/queries";

export const useMutationsVendor = () => {
  const [createProveedor] = useMutation(MUTATION_CREATE_PROVEEDOR, {
    refetchQueries: [{ query: QUERY_ALL_PROVEEDORES }],
  });
  const [updateProveedor] = useMutation(MUTATION_UPDATE_PROVEEDOR, {
    refetchQueries: [{ query: QUERY_ALL_PROVEEDORES }],
  });
  const [deleteProveedor] = useMutation(MUTATION_DELETE_PROVEEDOR, {
    refetchQueries: [
      {
        query: QUERY_ALL_PROVEEDORES,
      },
    ],
  });
  return {
    createProveedor,
    updateProveedor,
    deleteProveedor,
  };
};
