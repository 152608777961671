import React, { useState } from "react";
import { useNavigate } from "react-router";
import { Card, CardHeader, Table } from "reactstrap";

export const SiteWaybills = ({ waybills }) => {
  const navigate = useNavigate();
  return (
    <Card>
      <CardHeader>
        <h6>Albaranes</h6>
      </CardHeader>
      <Table responsive hover>
        <thead>
          <tr>
            <th>Número</th>
            <th>Fecha</th>
          </tr>
        </thead>
        <tbody>
          {waybills?.map((waybill) => (
            <tr
              key={waybill.id}
              onClick={() => navigate(`/waybills/${waybill.id}`)}
            >
              <td>{waybill.id}</td>
              <td>{waybill.fecha}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Card>
  );
};
