
/* CUSTOMIZER */
export const WATCH_CUSTOMIZER = "WATCH_CUSTOMIZER";
export const ADD_COSTOMIZER = "ADD_COSTOMIZER";
export const ADD_LAYOUT = "ADD_LAYOUT";
export const ADD_SIDEBAR_TYPES = "ADD_SIDEBAR_TYPES";
export const ADD_SIDEBAR_SETTINGS = "ADD_SIDEBAR_SETTINGS";
export const ADD_COLOR = "ADD_COLOR";
export const ADD_MIX_BACKGROUND_LAYOUT = "ADD_MIX_BACKGROUND_LAYOUT";
export const ROUTER_ANIMATION = "ROUTER_ANIMATION";
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const SET_TIPOS_ACTIVOS = 'SET_TIPOS_ACTIVOS';
export const SET_TIPOS_IGIC = 'SET_TIPOS_IGIC';
export const SET_ESTADOS_ACTIVOS = 'SET_ESTADOS_ACTIVOS';
export const SET_ACTIVE_USER = 'SET_ACTIVE_USER';
export const SET_ESTADOS_MERCANCIA = 'SET_ESTADOS_MERCANCIA';
export const SET_TIEMPOS_ENTREGA = 'SET_TIEMPOS_ENTREGA';