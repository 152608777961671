import { useQuery } from "@apollo/client";
import { QUERY_ALL_ALBARANES } from "../graphql/queries";

export const useWaybills = () => {
  const { data: _albaranes, loading, refetch } = useQuery(QUERY_ALL_ALBARANES);
  return {
    _albaranes,
    loading,
    refetch,
  };
};
