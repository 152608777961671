import { useQuery } from "@apollo/client";
import { QUERY_ALL_TIPO_ACTIVO } from "../graphql/queries";

export const useTiposActivo = () => {
  const {
    data,
    loading,
    error
  } = useQuery(QUERY_ALL_TIPO_ACTIVO);
  return { data, loading, error };
};
