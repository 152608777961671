import React, { useEffect, useMemo, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { useFiltrosPaginacion } from "../../../../hooks/useFiltrosPaginacion";
import { DeleteButton, EditButton, LOCALE_TABLE } from "../../../../components";
import { useOrders } from "../hooks/useOrders";
import { useMutationsOrder } from "../hooks/useMutationsOrder";
import { toast } from "react-toastify";
import { TextFilter } from "../../../../components/customFilters/TextFilter";
import { DateFilter } from "../../../../components/customFilters/DateFilter";
import moment from "moment";
import { TableLoading } from "../../../../components/custom-table/TableLoading";

import MaterialReactTable from "material-react-table";

import { Button } from "@mui/material";
import { Box } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { ExportToCsv } from "export-to-csv";

export const OrderListView = () => {
  const [pedidos, setPedidos] = useState([]);
  const { setFiltros, filtros, removeFilter } = useFiltrosPaginacion();
  const { dataPedidos, loading } = useOrders();
  const { deletePedido } = useMutationsOrder();

  const columns = useMemo(() => [
    {
      header: "",
      accessorKey: "id",
      enableColumnActions: false,
      enableFilters: false,
      enableMultiSort: false,
      enableColumnFilter: false,
      enableColumnFilterChangeMode: false,
      id: "actionsEdit",
      size: 30,
      Cell: ({ cell }) => (
        <div className="text-center">
          <EditButton entity="orders" id={cell.getValue()} />
        </div>
      ),
    },
    {
      header: "ID",
      accessorKey: "id",
      id: "proveedorId",
      size: 40,
      Filter: ({ filter }) => (
        <TextFilter
          filtros={filtros}
          setFiltros={setFiltros}
          removeFilter={removeFilter}
          campo="id"
        />
      ),
    },
    {
      header: "FECHA",
      accessorKey: "fecha",
      Filter: ({ filter }) => (
        <DateFilter
          filtros={filtros}
          setFiltros={setFiltros}
          removeFilter={removeFilter}
          campo="fecha"
        />
      ),
      Cell: ({ cell }) => {
        return moment(cell.getValue()).format("DD/MM/YYYY");
      },
    },
    {
      header: "ACTIVO",
      accessorKey: "activo.titulo",
      Filter: ({ filter }) => (
        <TextFilter
          filtros={filtros}
          setFiltros={setFiltros}
          removeFilter={removeFilter}
          campo="activo__titulo"
        />
      ),
    },
    {
      header: "CANTIDAD",
      accessorKey: "cantidad",
      Filter: ({ filter }) => (
        <TextFilter
          filtros={filtros}
          setFiltros={setFiltros}
          removeFilter={removeFilter}
          campo="cantidad"
        />
      ),
    },
    {
      header: "PROVEEDOR",
      accessorKey: "proveedor.nombre",
      Filter: ({ filter }) => (
        <TextFilter
          filtros={filtros}
          setFiltros={setFiltros}
          removeFilter={removeFilter}
          campo="proveedor__nombre"
        />
      ),
    },
    {
      header: "OBSERVACIONES",
      accessorKey: "observaciones",
      Filter: ({ filter }) => (
        <TextFilter
          filtros={filtros}
          setFiltros={setFiltros}
          removeFilter={removeFilter}
          campo="observaciones"
        />
      ),
      Cell: ({ cell }) => {
        return (
          <div className="text-wrap">
            {cell.getValue().slice(0, 50)}{" "}
            {cell.getValue().length > 50 ? "..." : ""}
          </div>
        );
      },
    },
    {
      header: "",
      accessorKey: "id",
      enableColumnActions: false,
      enabaleFilters: false,
      enableColumnFilters: false,
      id: "actions",
      Cell: ({ cell }) => (
        <div className="text-end">
          <DeleteButton onClick={() => handleDeleteInvoice(cell.getValue())} />
        </div>
      ),
    },
  ]);
  const csvOptions = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers: columns.map((c) => c.header),
  };

  const csvExporter = new ExportToCsv(csvOptions);

  useEffect(() => {
    if (dataPedidos) {
      setPedidos(dataPedidos.allPedidos.items);
    }
  }, [dataPedidos]);


  const handleDeleteInvoice = (id) => {
    confirmAlert({
      title: "¿Está seguro de eliminar este pedido?",
      message: "Esta acción es irreversible",
      buttons: [
        {
          label: "Si, ELIMINAR",
          onClick: () => {
            deletePedido({
              variables: {
                id,
              },
            })
              .then(({ data }) => {
                if (data.deletePedido.ok) {
                  toast.info("Pedido eliminado con éxito");
                } else {
                  toast.error("Error al eliminar el pedido");
                }
              })
              .catch((error) => {
                toast.error("Error al eliminar el pedido. " + error.message);
              });
          },
        },
        {
          label: "No",
          onClick: () => null,
        },
      ],
    });
  };

  const handleExportRows = (rows) => {
    csvExporter.generateCsv(rows.map((row) => row.original));
  };

  const handleExportData = () => {
    csvExporter.generateCsv(pedidos);
  };

  return (
    <>
      {dataPedidos ? (
        <MaterialReactTable
          columns={columns}
          data={pedidos}
          localization={LOCALE_TABLE}
          enableRowSelection
          enableColumnFilterModes
          enableGlobalFilterModes
          enableDensityToggle={false}
          state={{
            density: "compact",
            isLoading: loading,
          }}
          initialState={{
            pagination: {
              pageSize: 30,
            },
          }}
          muiTableProps={{
            sx: {
              tableLayout: "auto",
            },
          }}
          muiTablePaginationProps={{
            rowsPerPageOptions: [30, 50, 100],
            SelectProps: { native: true },
          }}

          renderTopToolbarCustomActions={({ table }) => (
            <Box>
              <Button
                color="primary"
                className="btn-xs btn-air-primary mx-1"
                //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                onClick={handleExportData}
              >
                <FileDownloadIcon /> Exportar todo
              </Button>
              <Button
                color="primary"
                className="btn-xs btn-air-primary mx-1"
                disabled={table.getRowModel().rows.length === 0}
                //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
                onClick={() => handleExportRows(table.getRowModel().rows)}
              >
                <FileDownloadIcon /> Exportar página
              </Button>
              <Button
                color="primary"
                className="btn-xs btn-air-primary mx-1"
                disabled={
                  !table.getIsSomeRowsSelected() &&
                  !table.getIsAllRowsSelected()
                }
                //only export selected rows
                onClick={() =>
                  handleExportRows(table.getSelectedRowModel().rows)
                }
              >
                <FileDownloadIcon /> Exportar selección
              </Button>
            </Box>
          )}
        ></MaterialReactTable>
      ) : (
        <TableLoading />
      )}
    </>
  );
};
