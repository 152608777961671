import { Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router";
import { CardBody, Col, Row, Card, Table } from "reactstrap";

export const GridAlbaranes = ({ albaranes = [] }) => {
  const navigate = useNavigate();
  return (
    <Row>
      <Col md={12}>
        <Card>
          <CardBody>
            <Typography variant="h6" gutterBottom component="div">
              Albaranes
            </Typography>
            <Table striped hover>
              <thead>
                <tr>
                  <th>Fecha</th>
                  <th>Sede</th>
                  <th>Cantidad</th>
                  <th>Precio U.</th>
                  <th>Observaciones</th>
                </tr>
              </thead>
              <tbody>
                {albaranes.map((albaran) => (
                  <tr
                    key={albaran.id}
                    onClick={() => navigate(`/waybills/${albaran.id}`)}
                  >
                    <td>{albaran.fecha}</td>
                    <td>{albaran.sede?.nombre}</td>
                    <td>{albaran.cantidad}</td>
                    <td>{albaran.precioUnidad}</td>
                    <td>{albaran.observaciones}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};
