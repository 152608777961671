import { useQuery } from "@apollo/client";
import { QUERY_USERS } from "../graphql/queries";

export const useUsers = () => {
  const {
    data,
    loading,
    error,
    refetch: getUsers,
  } = useQuery(QUERY_USERS);
  return { data, loading, error, getUsers };
};
