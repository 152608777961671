import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, FormGroup, Input, Label } from "reactstrap";
import { Password } from "../../constant";
import { GoogleLogin } from "react-google-login";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { useLogin } from "../../hooks/auth/useLogin";
import { LOGIN } from "../../redux/actionTypes";
import { Button, IconButton } from "@mui/material";
import { gapi } from "gapi-script";
import { useLoginWithGoogle } from "../../hooks/auth/useLoginWithGoogle";
import { toast, ToastContainer } from "react-toastify";

const LoginWithBgImage = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [Autenticated, setIsAutenticated] = useState(false);
  const { tokenAuth, data, user } = useLogin({ username: "", password: "" });
  const [togglePassword, setTogglePassword] = useState(false);
  const [password, setPassword] = useState("");
  const [selected, setSelected] = useState("firebase");
  const { googleAuth, google_response } = useLoginWithGoogle();

  // useEffect(() => {
  const initClient = () => {
    gapi.client.init({
      clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      scope: "",
    });
  };
  gapi.load("client:auth2", initClient)
  // });

  const handleChange = (e) => {
    setPassword(e.target.value);
  };
  const HideShowPassword = (tPassword) => {
    setTogglePassword(!tPassword);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const email = e.target[0].value;
    const password = e.target[1].value;

    tokenAuth({
      variables: {
        email,
        password,
      },
    })
      .then(({ data }) => {
        console.log(data);
        if (data?.tokenAuth.token) {
          const userInfo = {
            token: data?.tokenAuth.token,
            username: data?.tokenAuth.user.email,
          };
          dispatch({ type: LOGIN, payload: userInfo });
          navigate("/", { replace: true });
        } else {
          for (const err of data.tokenAuth.errors.nonFieldErrors) {
            toast.error(err.message);
          }
        }
      })
      .catch((error) => {
        toast.error(error);
      });
    // navigate(`/dashboard`, { replace: true });
  };

  const responseGoogle = (response) => {
    console.log(response);
    if (response.accessToken) {
      googleAuth({
        variables: {
          accessToken: response.accessToken,
        },
      })
        .then(({ data }) => {
          console.log({ data });
          if (data.socialAuth) {
            const userInfo = {
              token: data.socialAuth.token,
              username: response.wt.cu,
            };
            dispatch({ type: LOGIN, payload: userInfo });
            navigate("/");
          }
        })
        .catch((error) => {
          toast.error(
            "Error al iniciar sesión. Compruebe que su cuenta existe y que está verificada."
          );
        });
    }
  };

  return (
    <Container fluid={true}>
      <ToastContainer />
      <Row>
        <Col
          xl="7"
          className="b-center bg-size"
          style={{
            backgroundImage: `url(${require("../../assets/images/login/ilustracionLibros.jpg")})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "block",
          }}
        >
          <img
            className="bg-img-cover bg-center"
            style={{ display: "none" }}
            src={require("../../assets/images/login/2.jpg")}
            alt="looginpage"
          />
        </Col>
        <Col xl="5" className="p-0">
          <div
            className="login-card"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <div>
              <div className="login-main login-tab">
                <Form className="theme-form" onSubmit={onSubmit}>
                  <FormGroup>
                    <Label className="col-form-label">Email</Label>
                    <Input
                      className="form-control"
                      type="email"
                      required
                      placeholder="Introduzca su email..."
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label className="col-form-label">{Password}</Label>
                    <Input
                      className="form-control"
                      type={togglePassword ? "text" : "password"}
                      name="login[password]"
                      value={password}
                      onChange={(e) => handleChange(e)}
                      required=""
                      placeholder="*********"
                    />
                  </FormGroup>
                  <FormGroup className="mb-0">
                    <div className="checkbox ms-3">
                      <Input id="checkbox1" type="checkbox" />
                      <Label className="text-muted" for="checkbox1">
                        Recordar contraseña
                      </Label>
                    </div>

                    <Button type="submit" className="w-100">
                      Entrar
                    </Button>
                  </FormGroup>
                  <h6 className="text-muted mt-4 or">{"O haz login con "}</h6>
                  <div className="social mt-4">
                    <div className="btn-showcase text-center">
                      <GoogleLogin
                        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                        buttonText="Entrar con Google"
                        onSuccess={responseGoogle}
                        onFailure={(errorResponse) =>
                          console.log({ errorResponse })
                        }
                        cookiePolicy={"single_host_origin"}
                        IsSignedIn={false}
                      />
                    </div>
                  </div>
                  <div className="logos-europa">
                    <Row
                      style={{
                        marginTop: "20px",
                      }}
                    >
                      <Col>
                        <img
                          src={require("../../assets/logos/consejeria-economia-gobcan-1.png")}
                          alt="Gobierno de Canarias"
                          style={{ width: "100%" }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        className="flex justify-content-center"
                        style={{ marginBottom: "20px" }}
                      >
                        <img
                          src={require("../../assets/logos/union-europea-1.png")}
                          alt="Canarias Avanza"
                          style={{ maxHeight: "100px" }}
                        />
                      </Col>
                      <Col className="flex justify-content-center">
                        <img
                          src={require("../../assets/logos/canarias-avanza-1.png")}
                          alt="Union Europea"
                          style={{ maxHeight: "100px" }}
                        />
                      </Col>
                    </Row>
                    <p  className="text-center">
                      <strong>FONDO EUROPEO DE DESARROLLO REGIONAL</strong>
                    </p>
                    <p className="text-center">
                    SUBVENCIÓN INNOBONOS2022010006. "Cofinanciada con Fondos FEDER (85%), con un porcentaje de subvención del 70%”
                    </p>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default LoginWithBgImage;
