import { gql } from "@apollo/client";

export const CREATE_TIPO_DOCUMENTO = gql`
  mutation createTipoDocumento($data: TipoDocumentoInput!) {
    createTipodocumento(data: $data) {
      ok
      tipodocumento {
        id
        nombre
      }
    }
  }
`;

export const UPDATE_TIPO_DOCUMENTO = gql`
  mutation updateTipoDocumento($id: Int!, $data: TipoDocumentoUpdateInput!) {
    updateTipodocumento(id: $id, data: $data) {
      ok
      tipodocumento {
        id
        nombre
      }
    }
  }
`;
