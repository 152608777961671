import { gql } from "@apollo/client";

export const QUERY_TIPO_ACTIVO = gql`
  query TipoActivos($id: Int!) {
    tipoactivo(id: $id) {
      id
      nombre
    }
  }
`;

export const QUERY_ALL_TIPO_ACTIVO= gql`
  query AllTiposActivos {
    allTiposActivo {
      id
      nombre
    }
  }
`;
