import { useLazyQuery } from "@apollo/client"
import { QUERY_TIPO_DOCUMENTOS } from "../graphql/queries";

export const useTipoDocumento = () => {
  const [getTipoDocumento] = useLazyQuery(QUERY_TIPO_DOCUMENTOS, 
    {
      fetchPolicy: "network-only",
    });
  return {
    getTipoDocumento
  }
}
