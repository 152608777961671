import { gql } from "@apollo/client";

export const QUERY_ULTIMOS_CAMBIOS = gql`
  query ultimosCambios {
    ultimosCambios {
      id
      fechahora
      operacion
      tabla
      detalle
      usuario {
        email
      }
    }
  }
`;
