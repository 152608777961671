import { useMutation } from "@apollo/client";
import { CREATE_USER, UPDATE_USER } from "../graphql/mutations";
import { QUERY_USERS } from "../graphql/queries";

export const useUserMutations = () => {
  const [createUser] = useMutation(CREATE_USER, {
    refetchQueries: [
      {
        query: QUERY_USERS,
      },
    ],
  });
  const [updateUser] = useMutation(UPDATE_USER, {
    refetchQueries: [
      {
        query: QUERY_USERS,
      },
    ],
  });
  return {
    createUser,
    updateUser,
  };
};
