import { gql } from "@apollo/client";

export const MUTATION_CREATE_PEDIDO = gql`
  mutation createPedido($data: PedidoInput!) {
    createPedido(data: $data) {
      ok
      pedido {
        id
        activo {
          id
          titulo
        }
        fecha
        cantidad
        proveedor {
          id
          nombre
        }
        observaciones
      }
    }
  }
`;

export const MUTATION_UPDATE_PEDIDO = gql`
  mutation updatePedido($id: Int!, $data: PedidoUpdateInput!) {
    updatePedido(id: $id, data: $data) {
      ok
      pedido {
        id
        activo {
          id
          titulo
        }
        fecha
        cantidad
        proveedor {
          id
          nombre
        }
        observaciones
      }
    }
  }
`;

export const MUTATION_DELETE_PEDIDO = gql`
  mutation deletePedido($id: Int!) {
    deletePedido(id: $id) {
      ok
    }
  }
`;
