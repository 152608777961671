import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { useParams } from "react-router";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { useMutationsWaybill } from "../hooks/useMutationsWaybill";
import { toast } from "react-toastify";
import {
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import moment from "moment";
import { useOrders } from "../../order/hooks/useOrders";
import { SaveButton } from "../../../../components";
import { useWaybill } from "../hooks/useWaybill";
import { useSelector } from "react-redux";
import { useSites } from "../../site/hooks/useSites";

export const WaybillFormView = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { estadosMercancias, tiemposEntrega } = useSelector(
    (state) => state.GlobalState
  );
  const { data: dataSedes } = useSites();
  const [filteredPedidos, setFilteredPedidos] = useState([]);
  const [pedido, setPedido] = useState({});
  const [albaran, setAlbaran] = useState({});
  const [sede, setSede] = useState(1);
  const [tiempoEntrega, setTiempoEntrega] = useState("A");
  const [estadoMercancia, setEstadoMercancia] = useState("A");
  const { refetch: getPedidos } = useOrders();
  const { getAlbaran } = useWaybill();
  const {
    register,
    setValue,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      fecha: moment().format("YYYY-MM-DD"),
      cantidad: 0,
      precioUnidad: 0,
      tiempoEntrega: "A",
      estadoMercancia: "A",
      sedeId: 1,
    },
  });
  const { createAlbaran, updateAlbaran } = useMutationsWaybill();

  useEffect(() => {
    if (id) {
      getAlbaran({
        variables: {
          id,
        },
      }).then(({ data }) => {
        console.log(data);
        if (data.albaran) {
          setAlbaran(data.albaran);
          setTiempoEntrega(data.albaran.tiempoEntrega);
          setEstadoMercancia(data.albaran.estadoMercancia);
          setFilteredPedidos([{ ...data.albaran.pedido }]);
          setValue("fecha", data.albaran.fecha);
          setValue("cantidad", data.albaran.cantidad);
          setValue("precioUnidad", data.albaran.precioUnidad);
          setValue("observaciones", data.albaran.observaciones);
          setValue("pedidoId", data.albaran?.pedido?.id);
          setValue("tiempoEntrega", data.albaran.tiempoEntrega);
          setValue("estadoMercancia", data.albaran.estadoMercancia);
          setValue("sedeId", data.albaran?.sede?.id);
          setPedido(data.albaran.pedido);
          setSede(data.albaran.sede.id);
        }
      });
    }
  }, [id]);

  const filterPedidos = (search) => {
    getPedidos({
      variables: {
        search,
      },
    })
      .then(({ data }) => {
        if (data.allPedidos) {
          setFilteredPedidos(data.allPedidos.items);
        } else {
          setFilteredPedidos([]);
        }
      })
      .catch((error) => {
        toast.error("Error obteniendo los pedidos. " + error.message);
        setFilteredPedidos([]);
      });
  };

  const isFocused = (field) => {
    return (
      getValues(field) !== undefined && getValues(field) !== null && getValues(field) !== ""
    );
  };

  const onSubmit = (formData) => {
    if (!id) {
      createAlbaran({
        variables: {
          data: {
            ...formData,
          },
        },
      })
        .then(({ data }) => {
          if (data.createAlbaran.ok) {
            toast.info("Albarán creado correctamente");
            navigate("/waybills", { replace: true });
          } else {
            toast.error("Error al crear albarán");
          }
        })
        .catch((error) => {
          toast.error("Error al crear albarán. " + error.message);
        });
    } else {
      updateAlbaran({
        variables: {
          id,
          data: {
            ...formData,
          },
        },
      })
        .then(({ data }) => {
          if (data.updateAlbaran.ok) {
            toast.info("Albarán actualizado correctamente");
            navigate("/waybills", { replace: true });
          } else {
            toast.error("Error al actualizar albarán");
          }
        })
        .catch((error) => {
          toast.error("Error al actualizar albarán. " + error.message);
        });
    }
  };
  return (
    <>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Row className="mb-3">
                    <Col sm="12">
                      <h6>{id ? "Editar" : "Nuevo"} albarán</h6>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12" md="2">
                      <TextField
                        size="small"
                        name="fecha"
                        label="FECHA"
                        type="date"
                        focused={isFocused("fecha")}
                        {...register("fecha", { required: true })}
                        helperText={errors.fecha ? "Campo obligatorio" : ""}
                        error={errors.fecha?.type === "required"}
                      />
                    </Col>
                    <Col sm="12" md="4">
                      <FormControl fullWidth focused={isFocused('sedeId')}>
                        <input
                          type="hidden"
                          {...register("sedeId", {
                            required: true,
                          })}
                        />
                        <InputLabel>SEDE</InputLabel>
                        <Select
                          size="small"
                          name="sedeId"
                          label="SEDE"
                          value={sede}
                          onChange={(e) => {
                            setSede(e.target.value);
                            setValue("sedeId", e.target.value);
                          }}
                        >
                          {dataSedes?.allSedes.map((estado) => (
                            <MenuItem key={estado.id} value={estado.id}>
                              {estado.nombre}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Col>
                    <Col sm="12" md="6">
                      <input type="hidden" {...register("pedidoId")} />
                      <Autocomplete
                        fullWidth
                        size="small"
                        value={pedido}
                        options={filteredPedidos}
                        getOptionLabel={(option) =>
                          `[${option.id || " -- Buscar pedido (id, título activo, ...) -- "}] ${
                            option?.fecha
                              ? moment(option?.fecha).format("DD-MM-YYYY")
                              : ""
                          } - ${option?.activo?.titulo || ""}` || ""
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="PEDIDOS"
                            focused={isFocused("pedidoId")}
                            onChange={(e) => filterPedidos(e.target.value)}
                          />
                        )}
                        onChange={(event, value) => {
                          if (value) {
                            setValue("pedidoId", value.id);
                            setPedido(value);
                          }
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="0" md="2"></Col>
                    <Col sm="12" md="2">
                      <TextField
                        size="small"
                        name="cantidad"
                        label="CANTIDAD"
                        type="number"
                        focused={isFocused("cantidad")}
                        {...register("cantidad", { required: true })}
                        helperText={errors.cantidad ? "Campo obligatorio" : ""}
                        error={errors.cantidad?.type === "required"}
                      />
                    </Col>
                    <Col sm="12" md="2">
                      <TextField
                        size="small"
                        name="precioUnidad"
                        label="PRECIO UNIDAD"
                        inputProps={{
                          inputMode: "decimal",
                          pattern: "([0-9]+)?[,\\.]?[0-9]*",
                        }}
                        focused={isFocused("precioUnidad")}
                        {...register("precioUnidad", { required: true })}
                        helperText={
                          errors.precioUnidad ? "Campo obligatorio" : ""
                        }
                        error={errors.precioUnidad?.type === "required"}
                      />
                    </Col>
                    <Col sm="12" md="3">
                      <FormControl fullWidth focused={isFocused('estadoMercancia')}>
                        <input
                          type="hidden"
                          {...register("estadoMercancia", {
                            required: true,
                          })}
                        />
                        <InputLabel>ESTADO MERCANCÍAS</InputLabel>
                        <Select
                          size="small"
                          name="estadoMercancia"
                          label="ESTADO MERCANCIA"
                          value={estadoMercancia}
                        
                          onChange={(e) => {
                            setValue("estadoMercancia", e.target.value);
                            setEstadoMercancia(e.target.value);
                          }}
                        >
                          {estadosMercancias.map((estado) => (
                            <MenuItem key={estado.id} value={estado.id}>
                              {estado.nombre}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Col>
                    <Col sm="12" md="3">
                      <FormControl fullWidth focused={isFocused('tiempoEntrega')}>
                        <input
                          type="hidden"
                          {...register("tiempoEntrega", {
                            required: true,
                          })}
                        />
                        <InputLabel>TIEMPO ENTREGA</InputLabel>
                        <Select
                          size="small"
                          name="tiempoEntrega"
                          label="TIEMPO ENTREGA"
                          value={tiempoEntrega}
                          onChange={(e) => {
                            setTiempoEntrega(e.target.value);
                            setValue("tiempoEntrega", e.target.value);
                          }}
                        >
                          {tiemposEntrega.map((estado) => (
                            <MenuItem key={estado.id} value={estado.id}>
                              {estado.nombre}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <TextField
                        name="observaciones"
                        label="OBSERVACIONES"
                        multiline
                        rows={4}
                        fullWidth
                        size="small"
                        focused={isFocused("observaciones")}
                        {...register("observaciones")}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12" className="d-flex justify-content-end">
                      <SaveButton />
                    </Col>
                  </Row>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};
