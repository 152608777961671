import { useLazyQuery } from "@apollo/client";
import { QUERY_AUDITORIA_STOCKAGE_ACTIVO } from "../graphql/queries";

export const useAuditoriaStockageActivo = () => {
  const [getAuditoriaStockageActivo, { data, loading, error }] = useLazyQuery(
    QUERY_AUDITORIA_STOCKAGE_ACTIVO,
    {
      fetchPolicy: "network-only",
    }
  );
  return {
    getAuditoriaStockageActivo,
  };
};
