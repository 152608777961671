import { useState } from "react";

export const useFiltrosPaginacion = () => {
  const [filtros, setFiltros] = useState({
    order: "-id",
    filtros: [],
    skip: 0,
  });

  const setPage = (page) => {
    setFiltros({
      ...filtros,
      skip: page * 15,
    });
  };

  const removeFilter = (campo) => {
    setFiltros({
      ...filtros,
      filtros: filtros.filtros.filter((f) => f.campo !== campo),
    });
  };

  return {
    filtros,
    setFiltros,
    setPage,
    removeFilter,
  };
};
