import { gql } from "@apollo/client";

export const QUERY_USER = gql`
  query User($email: String!) {
    user2(email: $email) {
      username
      email
      firstName
      lastName
      isActive
    }
  }
`;

export const QUERY_USERS = gql`
  query Users {
    users2 {
      user {
        email
        firstName
        lastName
        isActive
        groups {
          id
          name
        }
      }
      verified
    }
  }
`;
