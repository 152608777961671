import { gql } from "@apollo/client";

export const MUTATION_CREATE_ALBARAN = gql`
  mutation createAlbaran($data: AlbaranInput!) {
    createAlbaran(data: $data) {
      ok
      albaran {
        id
        fecha
        cantidad
        precioUnidad
        observaciones
        pedido {
          id
          fecha
          cantidad
          activo {
            id
            titulo
          }
          observaciones
        }
      }
    }
  }
`;

export const MUTATION_UPDATE_ALBARAN = gql`
  mutation updateAlbaran($id: Int!, $data: AlbaranUpdateInput!) {
    updateAlbaran(id: $id, data: $data) {
      ok
      albaran {
        id
        fecha
        cantidad
        precioUnidad
        observaciones
        pedido {
          id
          fecha
          cantidad
          activo {
            id
            titulo
          }
          observaciones
        }
      }
    }
  }
`;

export const MUTATION_DELETE_ALBARAN = gql`
  mutation deleteAlbaran($id: Int!) {
    deleteAlbaran(id: $id) {
      ok
    }
  }
`;
