import { Navigate } from "react-router";

export const RoutesGuard = ({ children }) => {
  const token = window.localStorage.getItem("gestion-editorial-token");

  if (!token) {
    return <Navigate to="/auth/login" replace />;
  }

  return children;
};
