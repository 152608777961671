import React from 'react'
import { Navigate, Route, Routes } from 'react-router'
import { WaybillFormView } from '../views/WaybillFormView'
import { WaybillListView } from '../views/WaybillListView'

export const WaybillRouter = () => {
  return (
    <Routes>
    <Route path="/" element={<WaybillListView />} />
    <Route path="create" element={<WaybillFormView />} />
    <Route path=":id" element={<WaybillFormView />} />
    <Route path="/*" element={<Navigate to="actives" />} />
  </Routes>
  )
}
