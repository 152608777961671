import { Close } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useUpdateStockage } from "../../hooks/useUpdateStockage";
import { toast } from "react-toastify";
import { SaveButton } from "../../../../../components/buttons/SaveButton";

export const StockUpdateModal = ({ visible, closeHandler, stockage }) => {
  const { updateStockage } = useUpdateStockage();
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    setValue("stockage", stockage.stockage);
  }, [stockage]);

  const onSubmit = (formData) => {
    console.log(formData);
    updateStockage({
      variables: {
        id: stockage.id,
        data: {
          stockage: formData.stockage,
        },
      },
    })
      .then(({ data }) => {
        console.log(data);
        toast.info("Stock actualizado");
        closeHandler();
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error al actualizar el stock. " + err.message);
        closeHandler();
      });
  };

  return (
    <Dialog
      disableEscapeKeyDown
      fullWidth
      maxWidth="sm"
      open={visible}
      onClose={(_, reason) => {
        if (reason !== "backdropClick") {
          closeHandler();
        }
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle className="d-flex justify-content-between">
          <span id="modal-title">Actualizar Stock</span>
          <Button onClick={closeHandler}>
            <Close />
          </Button>
        </DialogTitle>
        <DialogContent>
          <div className="p-2">
            <TextField
              fullWidth
              name="stockage"
              id="stockage"
              label="Stock"
              {...register("stockage", { required: true })}
              helperText={errors.stockage ? "Campo obligatorio" : ""}
              error={errors.stockage?.type === "required"}
            />
          </div>
        </DialogContent>
        <DialogActions className="d-flex justify-content-between">
          <Button
            color="error"
            onClick={closeHandler}
            type="button"
            size="large"
            className="btn-air-danger mx-2"
          >
            Cancelar
          </Button>
          <SaveButton />
        </DialogActions>
      </form>
    </Dialog>
  );
};
