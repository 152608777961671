import { Edit } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useNavigate } from "react-router";

export const EditButton = ({ id, entity }) => {
  const navigate = useNavigate();
  const edit = () => {
    navigate(`/${entity}/${id}`);
  };
  return (
    <IconButton onClick={edit} color="info" className="p-0">
      <Edit />
    </IconButton>
  );
};
