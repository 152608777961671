import React, { Fragment, useState, useEffect } from "react";
import man from "../../assets/images/user/user.png";
import { LogIn, Bell, Maximize, Search } from "react-feather";
import { useNavigate } from "react-router-dom";
import { Admin, LogOut } from "../../constant";
import { LOGOUT } from "../../redux/actionTypes";
import { useDispatch, useSelector } from "react-redux";
import { useGoogleLogout } from "react-google-login";

const Rightbar = () => {
  const history = useNavigate();
  const [profile, setProfile] = useState("Usuario");
  const [user, setUser] = useState({});
  const [role, setRole] = useState("");
  const [searchresponsive, setSearchresponsive] = useState(false);
  const [moonlight, setMoonlight] = useState(false);
  const [notificationDropDown, setNotificationDropDown] = useState(false);
  const [selected, setSelected] = useState("es");
  const dispatch = useDispatch();
  const { signOut, loaded } = useGoogleLogout({
    clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    onLogoutSuccess: () => {
      dispatch({ type: LOGOUT });
      history("/auth/login", { replace: true });
    },
  });

  const { activeUser } = useSelector((state) => state.Auth);

  useEffect(() => {
    if (activeUser) {
      setUser(activeUser);
      if (activeUser?.isSuperuser) {
        setRole("Administrador");
      } else if (activeUser?.groups?.length > 0) {
        setRole(activeUser.groups[0].name);
      }
    }
  }, [activeUser]);

  useEffect(() => {
    setProfile(localStorage.getItem("profileURL") || man);
    if (localStorage.getItem("layout_version") === "dark-only") {
      setMoonlight(true);
    }
  }, []);

  const logout = () => {
    signOut();
  };

  const UserMenuRedirect = (redirect) => {
    history(redirect);
  };

  //full screen function
  function goFull() {
    if (
      (document.fullScreenElement && document.fullScreenElement !== null) ||
      (!document.mozFullScreen && !document.webkitIsFullScreen)
    ) {
      if (document.documentElement.requestFullScreen) {
        document.documentElement.requestFullScreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullScreen) {
        document.documentElement.webkitRequestFullScreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  return (
    <Fragment>
      <div className="nav-right col-8 pull-right right-header p-0">
        <ul className="nav-menus">
          <li className="maximize">
            <a className="text-dark" href="#javascript" onClick={goFull}>
              <Maximize />
            </a>
          </li>
          <li className="profile-nav onhover-dropdown p-0">
            <div className="media profile-media flex mbsc-align-items-center">
              <img className="b-r-10" src={man} alt="" />
              <div className="media-body">
                <span>{user?.email}</span>
                <p className="mb-0 font-roboto">
                  {role}
                  <i className="middle fa fa-angle-down"></i>
                </p>
              </div>
            </div>
            <ul className="profile-dropdown onhover-show-div">
              <li onClick={logout}>
                <LogIn />
                <span>{LogOut}</span>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </Fragment>
  );
};
// export default translate(Rightbar);
export default Rightbar;
