import React, { useEffect, useMemo } from "react";
import { EditButton, LOCALE_TABLE } from "../../../../components";
import { useTiposDocumento } from "../hooks/useTiposDocumento";

import MaterialReactTable from "material-react-table";

import { Button } from "@mui/material";
import { Box } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { ExportToCsv } from "export-to-csv";

export const TipoDocumentoListView = () => {
  const { data, loading } = useTiposDocumento();
  const [tiposDocumento, setTiposDocumento] = React.useState([]);

  const columns = useMemo(
    () => [
      {
        header: "",
        accessorKey: "id",
        enableColumnActions: false,
        enableFilters: false,
        enableMultiSort: false,
        enableColumnFilter: false,
        enableColumnFilterChangeMode: false,
        id: "actionsEdit",
        size: 30,
        Cell: ({ cell }) => (
          <div>
            <EditButton entity="tipos-documentos" id={cell.getValue()} />
          </div>
        ),
      },
      {
        accessorKey: "nombre", //access nested data with dot notation
        header: "TIPO",
        Filter: ({ filter }) => <span></span>,
      },
    ],
    []
  );

  const csvOptions = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers: columns.map((c) => c.header),
  };

  const csvExporter = new ExportToCsv(csvOptions);

  useEffect(() => {
    if (data) {
      setTiposDocumento(data.allTiposDocumento);
    }
  }, [data]);

  const handleExportRows = (rows) => {
    csvExporter.generateCsv(rows.map((row) => row.original));
  };

  const handleExportData = () => {
    csvExporter.generateCsv(tiposDocumento);
  };

  return (
    <>
      <MaterialReactTable
        columns={columns}
        data={tiposDocumento}
        localization={LOCALE_TABLE}
        enableRowSelection
        enableColumnFilterModes
        enableGlobalFilterModes
        enableDensityToggle={false}
        state={{
          density: "compact",
          isLoading: loading,
        }}
        initialState={{
          pagination: {
            pageSize: 30,
          },
        }}
        muiTableProps={{
          sx: {
            tableLayout: "auto",
          },
        }}
        muiTablePaginationProps={{
          rowsPerPageOptions: [30, 50, 100],
          SelectProps: { native: true },
        }}

        renderTopToolbarCustomActions={({ table }) => (
          <Box>
            <Button
              color="primary"
              className="btn-xs btn-air-primary mx-1"
              //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
              onClick={handleExportData}
            >
              <FileDownloadIcon /> Exportar todo
            </Button>
            <Button
              color="primary"
              className="btn-xs btn-air-primary mx-1"
              disabled={table.getRowModel().rows.length === 0}
              //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
              onClick={() => handleExportRows(table.getRowModel().rows)}
            >
              <FileDownloadIcon /> Exportar página
            </Button>
            <Button
              color="primary"
              className="btn-xs btn-air-primary mx-1"
              disabled={
                !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
              }
              //only export selected rows
              onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
            >
              <FileDownloadIcon /> Exportar selección
            </Button>
          </Box>
        )}
      ></MaterialReactTable>
    </>
  );
};
