import React, { Fragment } from "react";
import sad from "../../assets/images/other-images/sad.png";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { Container, Button, Media, Col } from "reactstrap";

const Error503 = () => {
  const navigate = useNavigate();
  return (
    <Fragment>
      <div className="page-wrapper">
        <div className="error-wrapper">
          <Container>
            <Media body className="img-100" src={sad} alt="" />
            <h4 className="my-3">
              NO TIENE PERMISOS PARA ENTRAR EN LA APLICACIÓN
            </h4>
            <div className="error-heading">
              <h2 className="headline font-secondary">{"503"}</h2>
            </div>
            <Col md="8 offset-md-2">
              <p className="sub-content">
                {
                  "Puede que su token de acceso no sea válido o que su sesión haya expirado. Por favor, vuelva a iniciar sesión."
                }
              </p>
            </Col>
            <Button
              color="secondary-gradien"
              size="lg"
              onClick={() => navigate(-1)}
            >
              Volver
            </Button>
          </Container>
        </div>
      </div>
    </Fragment>
  );
};

export default Error503;
