import React from "react";
import { Suspense, useEffect } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Loader from "../layout/loader";
import Logins from "../pages/authentication/login";
import LoginWithBgImage from "../pages/authentication/loginWithBgImage";
import Error503 from "../pages/errors/error503";
import LayoutRoutes from "./LayoutRoutes";
import { RoutesGuard } from "./RoutesGuard";

// import { classes } from "../data/layouts";

const Routers = () => {
  const abortController = new AbortController();
  // const defaultLayoutObj = classes.find(
  //   (item) => Object.values(item).pop(1) === "compact-wrapper"
  // );
  // const layout =
  //   localStorage.getItem("layout") || Object.keys(defaultLayoutObj).pop();

  useEffect(() => {
    console.ignoredYellowBox = ["Warning: Each", "Warning: Failed"];
    console.disableYellowBox = true;
    return function cleanup() {
      abortController.abort();
    };
  }, [abortController]);

  return (
    <BrowserRouter>
      <>
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route
              path={`/*`}
              element={
                <RoutesGuard>
                  <LayoutRoutes />
                </RoutesGuard>
              }
            />
            <Route path={`/auth/login`} element={<LoginWithBgImage />} />
            <Route path={`/auth/forbidden`} element={<Error503 />} />
          </Routes>
        </Suspense>
      </>
    </BrowserRouter>
  );
};

export default Routers;
