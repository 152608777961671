import React, { Fragment, useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  Button,
} from "reactstrap";
import { Home } from "react-feather";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Add, ArrowBack } from "@mui/icons-material";

const Breadcrumbs = ({ title, entidad, parent, addOption = true }) => {
  const location = useLocation();
  const [path, setPath] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    setPath(location.pathname.split("/").pop());
  }, [location]);

  return (
    <Fragment>
      <Container fluid={true}>
        <div className="page-title">
          <Row>
            <Col xs="6">
              <h3>{title}</h3>
            </Col>
            <Col xs="6">
              <Breadcrumb className="d-flex align-items-center justify-content-end">
                {addOption && path === entidad && (
                  <Button
                    color="info"
                    onClick={() => navigate(`/${entidad}/create`)}
                    className="d-flex align-items-center justify-content-end mx-4 btn-air-primary p-2"
                  >
                    <Add /> Crear nuevo
                  </Button>
                )}
                {path !== entidad && (
                  <Button
                    color="primary"
                    onClick={() => navigate(-1)}
                    className="d-flex align-items-center justify-content-end mx-4 btn-air-info p-2"
                  >
                    <ArrowBack /> Volver
                  </Button>
                )}
                <BreadcrumbItem className="align-self-center">
                  <Link to={`/dashboard`}>
                    <Home />
                  </Link>
                </BreadcrumbItem>
                {parent !== path && (
                  <BreadcrumbItem className="align-self-center">
                    <Link to={"/" + entidad}>{parent}</Link>
                  </BreadcrumbItem>
                )}
                <BreadcrumbItem active className="align-self-center">
                  {path === "create" ? "Nuevo" : ""}
                </BreadcrumbItem>
              </Breadcrumb>
            </Col>
          </Row>
        </div>
      </Container>
    </Fragment>
  );
};

export default Breadcrumbs;
