import { useQuery } from "@apollo/client";
import { QUERY_ULTIMOS_CAMBIOS } from "../graphql/queries";

export const useGetUltimosCambios = () => {
  const { data } = useQuery(QUERY_ULTIMOS_CAMBIOS, {
    fetchPolicy: "network-only",
  });
  return {
    data,
  };
};
