import { gql, useLazyQuery } from "@apollo/client";

const query = gql`
  query me2 {
    me2 {
      email
      isSuperuser
      firstName
      lastName
      groups {
        id
        name
      }
    }
  }
`;

export const useUserVerified = () => {
  const [getMe, { data }] = useLazyQuery(query, {
    fetchPolicy: "network-only",
  });
  return { getMe };
};
