import { useEffect, useMemo, useState } from "react";
import { useClients } from "../hooks/useClients";
import { useMutationClient } from "../hooks/useMutationClient";

import { confirmAlert } from "react-confirm-alert";
// import { useFiltrosPaginacion } from "../../../../hooks/useFiltrosPaginacion";
import { DeleteButton } from "../../../../components/buttons/DeleteButton";
import { EditButton } from "../../../../components/buttons/EditButton";
import { toast } from "react-toastify";
import { TableLoading } from "../../../../components/custom-table/TableLoading";
import MaterialReactTable from "material-react-table";
import { LOCALE_TABLE } from "../../../../components/custom-table/CustomTable";
import { Button } from "@mui/material";
import { Box } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { ExportToCsv } from "export-to-csv";

export const ClientListView = () => {
  // const [numItems, setNumItems] = useState(0);
  const [clientes, setClientes] = useState([]);
  // const { setPage, setFiltros, filtros, removeFilter } = useFiltrosPaginacion();
  const { loading, data, refetch } = useClients();
  const { deleteClient, dataDelete } = useMutationClient();
  const columns = useMemo(() => [
    {
      header: "",
      accessorKey: "id",
      enableColumnActions: false,
      enableFilters: false,
      enableMultiSort: false,
      enableColumnFilter: false,
      enableColumnFilterChangeMode: false,
      id: "actionsEdit",
      size: 30,
      Cell: ({ cell }) => (
        <div className="text-center">
          <EditButton entity="clients" id={cell.getValue()} />
        </div>
      ),
    },
    {
      header: "NIF/NIE/CIF",
      accessorKey: "identificacion",
      // Filter: ({ filter }) => (
      //   <TextFilter
      //     filtros={filtros}
      //     setFiltros={setFiltros}
      //     removeFilter={removeFilter}
      //     campo="identificacion"
      //   />
      // ),
    },
    {
      header: "NOMBRE",
      accessorKey: "nombre",
      // Filter: ({ filter }) => (
      //   <TextFilter
      //     filtros={filtros}
      //     setFiltros={setFiltros}
      //     removeFilter={removeFilter}
      //     campo="nombre"
      //   />
      // ),
    },
    {
      header: "DIRECCIÓN",
      accessorKey: "direccion",
      // Filter: ({ filter }) => (
      //   <TextFilter
      //     filtros={filtros}
      //     setFiltros={setFiltros}
      //     removeFilter={removeFilter}
      //     campo="direccion"
      //   />
      // ),
    },
    {
      header: "TELEFONO",
      accessorKey: "telefono",
      // Filter: ({ filter }) => (
      //   <TextFilter
      //     filtros={filtros}
      //     setFiltros={setFiltros}
      //     removeFilter={removeFilter}
      //     campo="telefono"
      //   />
      // ),
    },
    {
      header: "EMAIL",
      accessorKey: "email",
      // Filter: ({ filter }) => (
      //   <TextFilter
      //     filtros={filtros}
      //     setFiltros={setFiltros}
      //     removeFilter={removeFilter}
      //     campo="email"
      //   />
      // ),
    },
    {
      header: "",
      accessorKey: "id",
      enableColumnActions: false,
      enableFilters: false,
      enableMultiSort: false,
      enableColumnFilter: false,
      enableColumnFilterChangeMode: false,
      id: "actions",
      size: 30,
      Cell: ({ cell }) => (
        <div className="text-end">
          <DeleteButton onClick={() => handleDeleteClient(cell.getValue())} />
        </div>
      ),
    },
  ]);

  const csvOptions = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers: columns.map((c) => c.header),
  };

  const csvExporter = new ExportToCsv(csvOptions);

  useEffect(() => {
    if (data?.allClientes) {
      setClientes(
        data?.allClientes?.items.map((client) => {
          return {
            ...client,
            domicilio: client.dirFacturacion?.domicilio || "",
          };
        }) || []
      );
      // setNumItems(data?.allClientes?.numitems);
    }
  }, [data]);

  // useEffect(() => {
  //   if (filtros) {
  //     refetch({
  //       filtros: filtros.filtros,
  //       order: filtros.order,
  //       skip: filtros.skip,
  //     });
  //   }
  // }, [filtros]);

  useEffect(() => {
    if (dataDelete?.deleteCliente.ok) {
      toast.info("Cliente borrado correctamente");
      refetch({
        // filtros: filtros.filtros,
        // order: filtros.order,
        // skip: filtros.skip,
      });
    }
  }, [dataDelete]);

  const handleDeleteClient = (id) => {
    confirmAlert({
      title: "¿Está seguro de eliminar este cliente?",
      message: "Esta acción es irreversible",
      buttons: [
        {
          label: "Si, ELIMINAR",
          onClick: () => {
            deleteClient({
              variables: {
                id,
              },
            });
          },
        },
        {
          label: "No",
          onClick: () => null,
        },
      ],
    });
  };

  const handleExportRows = (rows) => {
    csvExporter.generateCsv(rows.map((row) => row.original));
  };

  const handleExportData = () => {
    csvExporter.generateCsv(data);
  };

  return (
    <>
      {data ? (
        // <CustomTable
        //   columns={columns}
        //   data={clientes}
        //   numItems={numItems}
        //   estadoFiltros={filtros}
        //   setfiltros={setFiltros}
        // />
        <MaterialReactTable
          columns={columns}
          data={clientes}
          localization={LOCALE_TABLE}
          enableRowSelection
          enableColumnFilterModes
          enableGlobalFilterModes
          enableDensityToggle={false}
          state={{
            density: "compact",
            isLoading: loading,
          }}
          initialState={{
            pagination: {
              pageSize: 30,
            },
          }}
          muiTableProps={{
            sx: {
              tableLayout: "auto",
            },
          }}
          muiTablePaginationProps={{
            rowsPerPageOptions: [30, 50, 100],
            SelectProps: { native: true },
          }}

          renderTopToolbarCustomActions={({ table }) => (
            <Box>
              <Button
                color="primary"
                className="btn-xs btn-air-primary mx-1"
                //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                onClick={handleExportData}
              >
                <FileDownloadIcon /> Exportar todo
              </Button>
              <Button
                color="primary"
                className="btn-xs btn-air-primary mx-1"
                disabled={table.getRowModel().rows.length === 0}
                //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
                onClick={() => handleExportRows(table.getRowModel().rows)}
              >
                <FileDownloadIcon /> Exportar página
              </Button>
              <Button
                color="primary"
                className="btn-xs btn-air-primary mx-1"
                disabled={
                  !table.getIsSomeRowsSelected() &&
                  !table.getIsAllRowsSelected()
                }
                //only export selected rows
                onClick={() =>
                  handleExportRows(table.getSelectedRowModel().rows)
                }
              >
                <FileDownloadIcon /> Exportar selección
              </Button>
            </Box>
          )}
        ></MaterialReactTable>
      ) : (
        <TableLoading />
      )}
    </>
  );
};
