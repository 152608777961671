import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { InvoiceListView } from "../views/InvoiceListView";
import { InvoiceFormView } from "../views/InvoiceFormView";

export const InvoiceRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<InvoiceListView />} />
      <Route path="create" element={<InvoiceFormView />} />
      <Route path=":id" element={<InvoiceFormView />} />
      <Route path="/*" element={<Navigate to="clients" />} />
    </Routes>
  );
};
