import { useLazyQuery } from "@apollo/client";
import { QUERY_PEDIDO } from "../graphql/queries";

export const useOrder = () => {
  const [getPedido, { loading }] = useLazyQuery(QUERY_PEDIDO, {
    fetchPolicy: "network-only",
  });
  return {
    getPedido,
  };
};
