import { useLazyQuery } from "@apollo/client";
import { ALL_CLIENTES } from "../../client/graphql/queries";

export const useClienteFilter = () => {
  const [getClientes, { data: _clientesFiltered, loading, error }] =
    useLazyQuery(ALL_CLIENTES);
  return {
    getClientes,
    _clientesFiltered,
    loading,
  };
};
