import { gql } from "@apollo/client";

export const MUTATION_CREATE_ACTIVO = gql`
  mutation createActivo($data: ActivoInput!) {
    createActivo(data: $data) {
      ok
      activo {
        id
        titulo
        fechaAlta
        tipo {
          id
          nombre
        }
        paginas
        color
        tipoEncuadernacion
        isbn
        coste
        pvp
        gdrive
        estado
        observaciones
      }
    }
  }
`;

export const MUTATION_UPDATE_ACTIVO = gql`
  mutation updateActivo($id: Int!, $data: ActivoUpdateInput!) {
    updateActivo(id: $id, data: $data) {
      ok
      activo {
        id
        titulo
        fechaAlta
        tipo {
          id
          nombre
        }
        paginas
        color
        tipoEncuadernacion
        isbn
        coste
        pvp
        gdrive
        observaciones
        estado
      }
    }
  }
`;

export const MUTATION_DELETE_ACTIVO = gql`
  mutation deleteActivo($id: Int!) {
    deleteActivo(id: $id) {
      ok
    }
  }
`;

export const MUTATION_DELETE_DOCUMENTO_ACTIVO = gql`
  mutation deleteDocumentoActivo($id: Int!) {
    deleteDocumento(id: $id) {
      ok
    }
  }
`;

export const UPDATE_DOCUMENTO = gql`
  mutation updateDocumento($id: Int!, $data: DocumentoUpdateInput!) {
    updateDocumento(id: $id, data: $data) {
      ok
    }
  }
`;

export const MUTATION_GDRIVESYNC = gql`
  mutation gdriveSync($idcarpeta: String!) {
    gdriveSync(idcarpeta: $idcarpeta) {
      ok
      ficheros {
        id
        carpeta
        nombre
        tipo {
          id
          nombre
        }
        fechahora
        refGdrive
        estado
        carpeta
        createdTime
        modifiedTime
        modifiedUser
        size
        noEncontrado
        url
      }
    }
  }
`;

export const MUTATION_UPDATE_STOCKAGE = gql`
  mutation updateStockage($id: Int!, $data: ActivoStockageUpdateInput!) {
    updateActivostockage(data: $data, id: $id) {
      ok
      activostockage {
        id
        stockage
      }
    }
  }
`;
