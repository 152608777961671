import { useQuery, useLazyQuery } from "@apollo/client";
import { ALL_FACTURAS } from "../graphql/queries-graphql";
import { toast } from "react-toastify";

export const useInvoices = () => {
  const {
    data: facturas,
    loading,
    error,
    refetch,
  } = useQuery(ALL_FACTURAS, {
    onError: (e) => toast.error(e.message),
  });
  const [getInvoices, { data: _lazyFacturas, error: errorInvoices }] =
    useLazyQuery(ALL_FACTURAS, {
      onError: () => toast.error(errorInvoices.message),
    });
  return {
    facturas,
    loading,
    refetch,
    getInvoices,
    _lazyFacturas,
  };
};
