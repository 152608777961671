import { useMutation } from "@apollo/client";
import {
  MUTATION_CREATE_ALBARAN,
  MUTATION_DELETE_ALBARAN,
  MUTATION_UPDATE_ALBARAN,
} from "../graphql/mutation";
import { QUERY_ALL_ALBARANES } from "../graphql/queries";

export const useMutationsWaybill = () => {
  const [createAlbaran] = useMutation(MUTATION_CREATE_ALBARAN, {
    refetchQueries: [{ query: QUERY_ALL_ALBARANES }],
  });
  const [updateAlbaran] = useMutation(MUTATION_UPDATE_ALBARAN, {
    refetchQueries: [{ query: QUERY_ALL_ALBARANES }],
  });
  const [deleteAlbaran] = useMutation(MUTATION_DELETE_ALBARAN, {
    refetchQueries: [{ query: QUERY_ALL_ALBARANES }],
  });

  return { createAlbaran, updateAlbaran, deleteAlbaran };
};
