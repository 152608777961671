import { useLazyQuery } from "@apollo/client";
import { QUERY_SEDE } from "../graphql/queries";

export const useSite = () => {
  const [getSede] = useLazyQuery(QUERY_SEDE, {
    fetchPolicy: "network-only",
  });
  return {
    getSede,
  };
};
