import { useLazyQuery, useQuery } from "@apollo/client";
import { FACTURA } from "../graphql/queries-graphql";
export const useInvoice = () => {
  const [getInvoice, { data: _lazyFactura, loading: _lazyLoading, error }] =
    useLazyQuery(FACTURA, {
      fetchPolicy: "network-only",
    });
  // const { refetch: refetchFactura } = useQuery(FACTURA);
  return {
    getInvoice,
    // refetchFactura,
    _lazyFactura,
    _lazyLoading,
  };
};
