import { Routes, Route, Navigate } from "react-router-dom";
import { DocumentFormView } from "../views/DocumentFormView";
import { DocumentListView } from "../views/DocumentListView";

export const DocumentRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<DocumentListView />} />
      <Route path="create" element={<DocumentFormView />} />
      <Route path=":id" element={<DocumentFormView />} />
      <Route path="/*" element={<Navigate to="documents" />} />
    </Routes>
  );
};
