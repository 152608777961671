import React, { useEffect, useState } from "react";
import MaterialReactTable from "material-react-table";
import { esES } from "@mui/material/locale";
import {
  Button,
  createTheme,
  Pagination,
  ThemeProvider,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { ExportToCsv } from "export-to-csv";
const tiposFiltros = {
  identificacion: "text",
  nombre: "text",
  id: "number",
  cantidad: "number",
  observaciones: "text",
  precioUnidad: "number",
  fecha: "date",
  fechaAlta: "date",
  estado: "text",
  isbn: "text",
  titulo: "text",
  tipo: "fk",
  color: "bool",
  encuadernacion: "text",
};

export const LOCALE_TABLE = {
  actions: 'Acciones',
  cancel: 'Cancelar',
  changeFilterMode: 'Cambia el modo de filtro',
  clearFilter: 'Borrar filtro',
  clearSearch: 'Borrar búsqueda',
  clearSort: 'Borrar ordenación',
  columnActions: 'Acciones de columna',
  edit: 'Editar',
  expand: 'Expandir',
  expandAll: 'Expandir todo',
  filterByColumn: 'Filtrar por {column}',
  filterMode: 'Modo de filtro: {filterType}',
  grab: 'Agarrar',
  groupByColumn: 'Agrupar por {column}',
  groupedBy: 'Agrupados por ',
  hideAll: 'Ocultar todo',
  hideColumn: 'Ocultar columna de {column}',
  rowActions: 'Acciones de fila',
  pinToLeft: 'Alfile a la izquierda',
  pinToRight: 'Alfile a la derecha',
  save: 'Salvar',
  search: 'Búsqueda',
  selectedCountOfRowCountRowsSelected:
    '{selectedCount} de {rowCount} fila(s) seleccionadas',
  showAll: 'Mostrar todo',
  showAllColumns: 'Mostrar todas las columnas',
  showHideColumns: 'Mostrar/Ocultar columnas',
  showHideFilters: 'Mostrar/Ocultar filtros',
  showHideSearch: 'Alternar búsqueda',
  sortByColumnAsc: 'Ordenar por {column} ascendente',
  sortByColumnDesc: 'Ordenar por {column} descendiendo',
  thenBy: ', entonces por ',
  toggleDensity: 'Alternar relleno denso',
  toggleFullScreen: 'Alternar pantalla completa',
  toggleSelectAll: 'Seleccionar todo',
  toggleSelectRow: 'Seleccionar fila',
  ungroupByColumn: 'Desagrupar por {column}',
  unpin: 'Quitar pasador',
  unsorted: 'Sin clasificar',
  noRecordsToDisplay: 'No hay datos para mostrar',
  rowsPerPage: 'Items por página:',
  of: 'de',
  filterArrIncludes: 'incluye',
  filterArrIncludesAll: 'incluye todos',
  filterArrIncludesSome: 'incluye algunos',
  filterBetween: 'entre',
  filterBetweenInclusive: 'entre (inclusive)',
  filterContains: 'contiene',
  filterEmpty: 'vacío',
  filterEndsWith: 'termina con',
  filterEquals: 'igual',
  filterEqualsString: 'igual',
  filterFuzzy: 'difuso',
  filterGreaterThan: 'mayor que',
  filterGreaterThanOrEqualTo: 'mayor o igual que',
  filterInNumberRange: 'en el rango de números',
  filterIncludesString: 'incluye',
  filterIncludesStringSensitive: 'incluye (sensible a mayúsculas/minúsculas)',
  filterLessThan: 'menor que',
  filterLessThanOrEqualTo: 'menor o igual que',
  filterNotEmpty: 'no vacío',
  filterNotEquals: 'no igual',
  filterStartsWith: 'comienza con',
  filterWeakEquals: 'igual débil',
  filteringByColumn: 'filtrado por {column} - {filterType} {filterValue}',
  changeSearchMode: 'Cambiar modo de búsqueda',
};

export const CustomTable = ({
  columns,
  data,
  numItems = data?.length,
  estadoFiltros,
  setfiltros,
  viewTableOptions = true,
  enableRowSelection = true,
  manualFiltersPagination = true,
}) => {
  const csvOptions = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers: columns.map((c) => c.header),
  };

  const csvExporter = new ExportToCsv(csvOptions);

  const theme = useTheme();
  const [columnFilters, setColumnFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [sortedColumn, setSortedColumn] = useState(null);

  useEffect(() => {
    if (columnFilters.length > 0) {
      for (const colfilter of columnFilters) {
        const filtrosTemp = estadoFiltros.filtros.filter(
          (f) => f.campo !== colfilter.id
        );

        filtrosTemp.push({
          campo: colfilter.id,
          valor: colfilter.value,
          tipo: tiposFiltros[colfilter.id],
        });

        setfiltros({
          ...estadoFiltros,
          filtros: [...filtrosTemp],
        });
      }
    } else {
      setfiltros({
        ...estadoFiltros,
        filtros: [],
      });
    }
  }, [columnFilters]);

  useEffect(() => {
    if (sortedColumn?.length > 0) {
      setfiltros({
        ...estadoFiltros,
        order: `${sortedColumn[0]?.desc ? "-" : ""}${sortedColumn[0].id}`,
      });
    } else {
      setfiltros({
        ...estadoFiltros,
        order: "-id",
      });
    }
  }, [sortedColumn]);

  const handleExportRows = (rows) => {
    csvExporter.generateCsv(rows.map((row) => row.original));
  };

  const handleExportData = () => {
    csvExporter.generateCsv(data);
  };

  return (
    <ThemeProvider theme={createTheme(theme, esES)}>
      <MaterialReactTable
        columns={columns}
        data={data}
        enableRowSelection={enableRowSelection}
        enablePagination={false}
        manualSorting
        manualFiltering //turn off client-side filtering
        manualPagination
        enableDensityToggle={false}
        enableColumnFilterChangeMode={viewTableOptions}
        enableColumnFilters={viewTableOptions}
        enableFullScreenToggle={viewTableOptions}
        onColumnFiltersChange={setColumnFilters} //hoist internal columnFilters state to your state
        onSortingChange={(e) => {
          setSorting(e);
          setSortedColumn(e);
        }} //hoist internal sorting state to your state
        state={{ columnFilters, density: "compact", sorting }}
        enableGlobalFilter={false}
        rowCount={numItems}
        localization={LOCALE_TABLE}
        renderTopToolbarCustomActions={({ table }) => (
          <Box>
            <Button
              color="primary"
              className="btn-xs btn-air-primary mx-1"
              //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
              onClick={handleExportData}
            >
              <FileDownloadIcon /> Exportar todo
            </Button>
            <Button
              color="primary"
              className="btn-xs btn-air-primary mx-1"
              disabled={table.getRowModel().rows.length === 0}
              //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
              onClick={() => handleExportRows(table.getRowModel().rows)}
            >
              <FileDownloadIcon /> Exportar página
            </Button>
            <Button
              color="primary"
              className="btn-xs btn-air-primary mx-1"
              disabled={
                !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
              }
              //only export selected rows
              onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
            >
              <FileDownloadIcon /> Exportar selección
            </Button>
          </Box>
        )}
        renderBottomToolbarCustomActions={({ table }) => (
          <>
            {manualFiltersPagination && (
              <Box>
                <Pagination
                  count={Math.ceil(
                    numItems / process.env.REACT_APP_DEFAULT_NUM_RESULTS
                  )}
                  variant="outlined"
                  color="primary"
                  onChange={(event, value) => {
                    console.log(value - 1);
                    setfiltros({
                      ...estadoFiltros,
                      skip:
                        process.env.REACT_APP_DEFAULT_NUM_RESULTS * (value - 1),
                    });
                    console.log({ estadoFiltros });
                  }}
                />
              </Box>
            )}
          </>
        )}
        muiTableProps={{
          sx: {
            tableLayout: "auto",
          },
        }}
      />
    </ThemeProvider>
  );
};
