import React from "react";
import { Navigate, Route, Routes } from "react-router";
import { UserFormView } from "../views/UserFormView";
import { UserListView } from "../views/UserListView";

export const UserRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<UserListView />} />
      <Route path="create" element={<UserFormView />} />
      <Route path=":id" element={<UserFormView />} />
      <Route path="/*" element={<Navigate to="actives" />} />
    </Routes>
  );
};
