import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { TipoIgicFormView } from "../views/TipoIgicFormView";
import { TipoIgicListView } from "../views/TipoIgicListView";

export const TipoIgicRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<TipoIgicListView />} />
      <Route path="create" element={<TipoIgicFormView />} />
      <Route path=":id" element={<TipoIgicFormView />} />
      <Route path="/*" element={<Navigate to="tipo-igic" />} />
    </Routes>
  );
};
