import { Routes, Route, Navigate } from "react-router-dom";
import { ClientFormView, ClientListView } from "../views";

export const ClientRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<ClientListView />} />
      <Route path="create" element={<ClientFormView />} />
      <Route path=":id" element={<ClientFormView />} />
      <Route path="/*" element={<Navigate to="clients" />} />
    </Routes>
  );
};
