import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { TipoActivoFormView } from "../views/TipoActivoFormView";
import { TipoActivoListView } from "../views/TipoActivoListView";

export const TipoActivoRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<TipoActivoListView />} />
      <Route path="create" element={<TipoActivoFormView />} />
      <Route path=":id" element={<TipoActivoFormView />} />
      <Route path="/*" element={<Navigate to="tipos-activos" />} />
    </Routes>
  );
};
