import { Routes, Route, Navigate } from "react-router-dom";
import { ActiveFormView, ActiveListView } from "../views";

export const ActiveRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<ActiveListView />} />
      <Route path="create" element={<ActiveFormView />} />
      <Route path=":id" element={<ActiveFormView />} />
      <Route path="/*" element={<Navigate to="actives" />} />
    </Routes>
  );
};
