const { gql } = require("@apollo/client");

export const QUERY_PEDIDO = gql`
  query Pedido($id: Int!) {
    pedido(id: $id) {
      id
      activo {
        id
        titulo
      }
      fecha
      cantidad
      proveedor {
        id
        nombre
      }
      observaciones
      albaranSet {
        id
        sede {
          id
          nombre
        }
        fecha
        cantidad
        precioUnidad
        observaciones
      }
    }
  }
`;

export const QUERY_ALL_PEDIDOS = gql`
  query AllPedidos(
    $skip: Int
    $order: String
    $filtros: [FiltroType]
    $search: String
    $filtrosOr: [FiltroType]
  ) {
    allPedidos(
      filtros: $filtros
      search: $search
      filtrosOr: $filtrosOr
      first: 15
      skip: $skip
      order: $order
    ) {
      numitems
      items {
        id
        activo {
          id
          titulo
        }
        fecha
        cantidad
        proveedor {
          id
          nombre
        }
        observaciones
      }
    }
  }
`;
