import { Edit } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useState } from "react";
import { ExternalLink } from "react-feather";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Card, CardHeader, Table } from "reactstrap";
import { StockUpdateModal } from "./StockUpdateModal";

export const ActiveSiteStockage = ({
  stock,
  getActive,
  activeId,
  setStock,
}) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [stockage, setStockage] = useState(stock);
  const { activeUser } = useSelector((state) => state.Auth);

  const closeHandler = () => {
    setOpen(false);
    setStockage({});
    getActive({
      variables: {
        id: activeId,
      },
    })
      .then(({ data }) => {
        console.log(data);
        setStock(data.activo.activostockageSet);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <StockUpdateModal
        visible={open}
        closeHandler={closeHandler}
        stockage={stockage}
      />
      <div
        style={{
          height: "350px",
          overflowY: "scroll",
          backgroundColor: "white",
        }}
      >
        <Table>
          <thead>
            <tr>
              {activeUser?.isSuperuser && <th></th>}
              <th>SEDE</th>
              <th>STOCK</th>
            </tr>
          </thead>
          <tbody>
            {stock?.map((item, index) => (
              <tr
                key={index}
                style={{
                  cursor: "pointer",
                }}
              >
                {activeUser?.isSuperuser && (
                  <td className="text-start">
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setStockage(item);
                        setOpen(true);
                      }}
                    >
                      <Edit />
                    </IconButton>
                  </td>
                )}
                <td>
                  <IconButton
                    onClick={() => navigate(`/sites/${item.sede.id}`)}
                  >
                    <ExternalLink />
                  </IconButton>
                  {item.sede.nombre}{" "}
                </td>
                <td>{item.stockage}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  );
};
