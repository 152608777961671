import { useLazyQuery } from "@apollo/client";
import { QUERY_ACTIVO } from "../graphql/queries";

export const useActive = () => {
  const [getActive, { data, loading, error }] = useLazyQuery(QUERY_ACTIVO, {
    fetchPolicy: "network-only",
  });
  return {
    getActive,
  };
};
