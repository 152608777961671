import { gql } from "@apollo/client";

export const QUERY_SEDE = gql`
  query sede($id: Int!) {
    sede(id: $id) {
      id
      nombre
      activostockageSet {
        id
        activo {
          id
          titulo
        }
        stockage
      }
      albaranSet {
        id
        fecha
      }
      facturaSet {
        id
        fecha
        cliente {
          id
          nombre
        }
        numero
      }
    }
  }
`;

export const QUERY_SEDES = gql`
  query sedes {
    allSedes {
      id
      nombre
    }
  }
`;
