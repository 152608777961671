import { useLazyQuery } from "@apollo/client";
import { QUERY_PROVEEDOR } from "../graphql/queries";

export const useVendor = () => {
  const [getVendor, { data, loading, error }] = useLazyQuery(QUERY_PROVEEDOR);
  return {
    getVendor,
    loading,
  };
};
