import React from "react";
import Routers from "./route";
import { Provider } from "react-redux";
import { ApolloProvider } from "@apollo/client";
import { client } from "./graphql/apollo-client";
import store from "./store";
import { createTheme, ThemeProvider } from "@mui/material";
import colores from './assets/scss/utils/_variables.scss';

const theme = createTheme({
  palette: {
    danger: {
      main: colores.danger,
    },
    success: {
      main: colores.success,
    },
    primary: {
      main: colores.primary,
    },
    secondary: {
      main: colores.secondary,
    },
    warning: {
      main: colores.warning,
    },
    info: {
      main: colores.info,
    },
  },
  status: {
    danger: colores.danger,
    primary: colores.primary,
    success: colores.success,
    secondary: colores.secondary,
    warning: colores.warning,
    info: colores.info,
  },
});
const App = () => (
  <div className="App">
    <Provider store={store}>
      <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
          <Routers />
        </ThemeProvider>
      </ApolloProvider>
    </Provider>
  </div>
);

export default App;
