import { gql } from "@apollo/client";

export const CREATE_USER = gql`
  mutation createUser($data: UserInput!) {
    createUser(data: $data) {
      ok
      user {
        email
        isActive
        firstName
        lastName
      }
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser($email: String!, $data: UserUpdateInput!) {
    updateUser(email: $email, data: $data) {
      ok
      user {
        email
        isActive
        firstName
        lastName
      }
    }
  }
`;
