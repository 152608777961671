import { useQuery } from "@apollo/client";
import { QUERY_ALL_TIPO_IGIC } from "../graphql/queries";

export const useTiposIgic = () => {
  const {
    data,
    loading,
    error,
    refetch: getTiposIgic,
  } = useQuery(QUERY_ALL_TIPO_IGIC);
  return { data, loading, error, getTiposIgic };
};
