import React, { Fragment } from "react";
import Breadcrumbs from "../../../layout/breadcrumb";
import { Container } from "reactstrap";
import { UserListView } from "./views/UserListView";
import { UserRouter } from "./router/UserRouter";

export const UserPage = () => {
  return (
    <Fragment>
      <Breadcrumbs title="Usuarios" entidad="users" parent="Usuarios" />
      <Container fluid={true}>
        <div className="faq-wrap">
          <UserRouter />
        </div>
      </Container>
    </Fragment>
  );
};
