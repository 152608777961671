import { gql } from "@apollo/client";

export const CREATE_TIPO_ACTIVO = gql`
  mutation createTipoActivo($data: TipoActivoInput!) {
    createTipoactivo(data: $data) {
      ok
      tipoactivo {
        id
        nombre
      }
    }
  }
`;

export const UPDATE_TIPO_ACTIVO = gql`
  mutation updateTipoActivo($id: Int!, $data: TipoActivoUpdateInput!) {
    updateTipoactivo(id: $id, data: $data) {
      ok
      tipoactivo {
        id
        nombre
      }
    }
  }
`;
