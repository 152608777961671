import { useMutation } from "@apollo/client";
import { ALL_CLIENTES } from "../graphql/queries";
import {
  CREATE_CLIENTE,
  DELETE_CLIENTE,
  UPDATE_CLIENTE,
} from "../graphql/mutations";

export const useMutationClient = () => {
  const [deleteClient, { error: errorDelete, data: dataDelete }] = useMutation(
    DELETE_CLIENTE,
    {
      refetchQueries: [
        {
          query: ALL_CLIENTES,
        },
      ],
    }
  );
  const [createCliente, { data: dataCreate }] = useMutation(CREATE_CLIENTE, {
    refetchQueries: [
      {
        query: ALL_CLIENTES,
      },
    ],
  });
  const [updateCliente, { data: dataUpdate }] = useMutation(UPDATE_CLIENTE, {
    refetchQueries: [
      {
        query: ALL_CLIENTES,
      },
    ],
  });

  return {
    deleteClient,
    createCliente,
    updateCliente,

    dataCreate,
    dataUpdate,
    dataDelete,
    errorDelete,
  };
};
