import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Card, CardBody, Col, Container, Row, Table } from "reactstrap";
import {
  Autocomplete,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { SaveButton } from "../../../../components/buttons/SaveButton";
import { toast } from "react-toastify";
import { useInvoiceMutation } from "../hooks/useInvoiceMutation";
import { useInvoice } from "../hooks/useInvoice";
import { useClienteFilter } from "../hooks/useClienteFilter";
import moment from "moment";
import { Add, PictureAsPdf } from "@mui/icons-material";
import { DeleteButton } from "../../../../components/buttons/DeleteButton";
import { confirmAlert } from "react-confirm-alert";
import { useCalculoTotal } from "../hooks/useCalculoTotal";
import { useSites } from "../../site/hooks/useSites";
import { useActives } from "../../active/hooks/useActives";
import { useGenerarFacturaPdf } from "../hooks/useGenerarFacturaPdf";

export const InvoiceFormView = () => {
  const { id } = useParams();
  const { tiposIgic } = useSelector((state) => state.GlobalState);
  const navigate = useNavigate();
  const [invoice, setInvoice] = useState({});
  const [cliente, setCliente] = useState({});
  const [sede, setSede] = useState(1);
  const [filteredClientes, setFilteredClientes] = useState([]);
  const [lineaFacturaTemp, setLineaFacturaTemp] = useState({
    cantidad: "",
    concepto: "",
    precioUnidad: "",
    tipoigicId: 1,
    activo: null,
  });
  const [lineasFactura, setLineasFactura] = useState([]);
  const [totales, setTotales] = useState({
    baseimp: 0,
    igic: 0,
    total: 0,
  });
  const [activosFiltered, setActivosFiltered] = useState([]);
  const { getInvoice } = useInvoice();
  const { refetch: getActivos } = useActives();
  const { data: dataSedes } = useSites();
  const { getCalculo } = useCalculoTotal();
  const { generarFacturaPdf } = useGenerarFacturaPdf();
  const {
    createFactura,
    updateFactura,
    createLineafactura,
    deleteLineafactura,
  } = useInvoiceMutation();
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      fecha: moment().format("YYYY-MM-DD"),
      sedeId: 1,
    },
  });
  const {
    getClientes,
    _clientesFiltered,
    loading: _loadingClientes,
  } = useClienteFilter();

  useEffect(() => {
    if (id) {
      setLineaFacturaTemp({
        ...lineaFacturaTemp,
        facturaId: id,
      });
      getInvoice({ variables: { id } }).then(({ data }) => {
        setInvoice(data.factura);
        setFilteredClientes([data.factura.cliente]);
        setCliente(data.factura.cliente);
        setLineasFactura([...data.factura.facturalineaSet]);
        setValue("numero", data.factura.numero);
        setValue("clienteId", data.factura.cliente.id);
        setValue("fecha", data.factura.fecha);
        setValue("identificacion", data.factura.identificacion);
        setValue("direccion", data.factura.direccion);
        setValue("telefono", data.factura.telefono);
        setValue("concepto", data.factura.concepto);
        setValue("sedeId", data.factura.sede.id);
        setValue("idgrupo", data.factura.idgrupo);
        setSede(data.factura.sede.id);
        setTotales({
          baseimp: data.factura.baseimp,
          igic: data.factura.igic,
          total: data.factura.total,
        });
      });
    }
  }, [id]);

  const filterActivos = (text) => {
    if (text.length > 2) {
      getActivos({
        variables: {
          filtros: [{ campo: "nombre", valor: text, tipo: "string" }],
        },
      })
        .then(({ data }) => {
          setActivosFiltered(data.allActivos.items);
        })
        .catch((error) => {
          setActivosFiltered([]);
        });
    }
  };

  const filterClientes = (text) => {
    if (text.length > 2) {
      getClientes({
        variables: {
          filtros: [{ campo: "nombre", valor: text, tipo: "string" }],
        },
      })
        .then(({ data }) => {
          setFilteredClientes(data.allClientes.items);
        })
        .catch((error) => {
          console.log(error);
          setFilteredClientes([]);
        });
    }
  };

  const handleClienteChange = (cliente) => {
    if (cliente) {
      setValue("identificacion", cliente.identificacion);
      setValue("direccion", cliente.direccion);
      setValue("telefono", cliente.telefono);
      setInvoice({
        ...invoice,
        identificacion: cliente.identificacion,
        direccion: cliente.direccion,
        telefono: cliente.telefono,
      });
      setCliente(cliente);
    }
  };

  const handleDeleteLinea = (linea) => {
    confirmAlert({
      title: "¿Está seguro de eliminar esta línea de factura?",
      message: "Esta acción es irreversible",
      buttons: [
        {
          label: "Si, ELIMINAR",
          onClick: () => {
            if (id) {
              deleteLineafactura({
                variables: {
                  id: linea.id,
                },
              }).then(({ data }) => {
                getInvoice({ variables: { id } })
                  .then(({ data }) => {
                    setInvoice(data.factura);
                    setLineasFactura([...data.factura.facturalineaSet]);
                    setTotales({
                      baseimp: data.factura.baseimp,
                      igic: data.factura.igic,
                      total: data.factura.total,
                    });
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              });
            } else {
              getCalculo({
                cantidad: linea.cantidad,
                precioUnidad: linea.precioUnidad,
                tipoigicId: linea.tipoigicId,
              })
                .then(({ data }) => {
                  if (data.calculoFacturalinea) {
                    setTotales((prev) => {
                      console.log({ prev });
                      console.log({ data });
                      return {
                        baseimp:
                          prev.baseimp - data.calculoFacturalinea.baseimp,
                        igic: prev.igic - data.calculoFacturalinea.igic,
                        total: prev.total - data.calculoFacturalinea.total,
                      };
                    });
                    setLineasFactura(
                      lineasFactura.filter((l) => l.id !== linea.id)
                    );
                  }
                })
                .catch((error) => {
                  toast.error(error.message);
                });
            }
          },
        },
        {
          label: "No",
          onClick: () => null,
        },
      ],
    });
  };

  const isFocused = (field) => {
    return (
      (id !== undefined &&
        invoice[field] !== undefined &&
        invoice[field] !== "") ||
      getValues(field) !== ""
    );
  };

  const onSubmit = (data) => {
    if (!id) {
      createFactura({ variables: { data: { ...data } } })
        .then(({ data }) => {
          if (data.createFactura.ok) {
            if (lineasFactura.length > 0) {
              lineasFactura.forEach((linea) => {
                createLineafactura({
                  variables: {
                    data: {
                      ...linea,
                      total: undefined,
                      facturaId: data.createFactura.factura.id,
                      activoId: linea.activo.id,
                      activo: undefined,
                    },
                  },
                })
                  .then(({ data }) => {
                    console.log(data);
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              });
            }
            toast.info("Albarán de salida creado correctamente", { duration: 1500 });
            navigate("/invoices", { replace: true });
          } else {
            toast.error("Error al crear el albarán");
          }
        })
        .catch((err) => {
          toast.error("Error al crear el albarán. " + err.message);
        });
    } else {
      updateFactura({
        variables: { id, data: { ...data, numero: undefined } },
      }).then(
        ({ data }) => {
          if (data.updateFactura.ok) {
            toast.info("Albarán actualizado correctamente");
          } else {
            toast.error("Error al actualizar el albarán");
          }
        },
        (err) => {
          toast.error("Error al actualizar el albarán. " + err.message);
        }
      );
    }
  };

  const addLine = () => {
    if (
      lineaFacturaTemp.cantidad &&
      lineaFacturaTemp.precioUnidad &&
      lineaFacturaTemp.activo
    ) {
      if (id) {
        createLineafactura({
          variables: {
            data: {
              ...lineaFacturaTemp,
              activoId: lineaFacturaTemp.activo.id,
              activo: undefined,
            },
          },
        })
          .then(({ data }) => {
            if (data.createFacturalinea.ok) {
              console.log(data);
              toast.info("Linea de factura creada correctamente");
              setLineasFactura([
                ...lineasFactura,
                data.createFacturalinea.facturalinea,
              ]);
              setTotales({
                baseimp: data.createFacturalinea.facturalinea.factura.baseimp,
                igic: data.createFacturalinea.facturalinea.factura.igic,
                total: data.createFacturalinea.facturalinea.factura.total,
              });
            }
          })
          .catch((err) => {
            toast.error("Error al crear la linea de factura. " + err.message);
          });
      } else {
        getCalculo({
          cantidad: lineaFacturaTemp.cantidad,
          precioUnidad: lineaFacturaTemp.precioUnidad,
          idtipoigic: lineaFacturaTemp.tipoigicId,
        }).then(({ data }) => {
          if (data.calculoFacturalinea) {
            setLineasFactura([
              ...lineasFactura,
              {
                ...lineaFacturaTemp,
                total: data.calculoFacturalinea.total,
              },
            ]);
            setTotales((prev) => {
              return {
                baseimp: prev.baseimp + data.calculoFacturalinea.baseimp,
                igic: prev.igic + data.calculoFacturalinea.igic,
                total: prev.total + data.calculoFacturalinea.total,
              };
            });
          }
        });
      }

      setLineaFacturaTemp({
        cantidad: "",
        concepto: "",
        precioUnidad: "",
        tipoigicId: 1,
        facturaId: id,
        activo: null,
      });
    } else {
      toast.error("Rellene todos los campos");
    }
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col md={12}>
            <Card>
              <CardBody>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Row className="mb-3">
                    <Col sm="12" className="d-flex justify-content-between">
                      <h6>{id ? "Editar" : "Nuevo"} albarán de salida</h6>
                      <Button
                        onClick={() => generarFacturaPdf({ variables: { id } })}
                      >
                        <PictureAsPdf className="mx-2" /> Generar PDF
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12" md="2">
                      <TextField
                        size="small"
                        fullWidth
                        disabled
                        name="numero"
                        label="NÚM. (Auto)"
                        id="numero"
                        value={invoice?.numero || ""}
                      />
                    </Col>
                    <Col sm="12" md="2">
                      <TextField
                        size="small"
                        fullWidth
                        label="FECHA"
                        {...register("fecha")}
                        type="date"
                        focused={isFocused("fecha")}
                      />
                    </Col>
                    <Col sm="12" md="2">
                      <FormControl fullWidth focused={isFocused("sedeId")}>
                        <input
                          type="hidden"
                          {...register("sedeId", {
                            required: true,
                          })}
                        />
                        <InputLabel>SEDE</InputLabel>
                        <Select
                          size="small"
                          name="sedeId"
                          label="SEDE"
                          value={sede}
                          onChange={(e) => {
                            setSede(e.target.value);
                            setValue("sedeId", e.target.value);
                          }}
                        >
                          {dataSedes?.allSedes.map((estado) => (
                            <MenuItem key={estado.id} value={estado.id}>
                              {estado.nombre}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Col>
                    <Col sm="12" md="2">
                      <TextField
                        size="small"
                        fullWidth
                        name="idgrupo"
                        label="ID. GRUPO"
                        id="idgrupo"
                        type='number'
                        focused={isFocused("idgrupo")}
                        {...register("idgrupo")}
                      />
                    </Col>
                    <Col sm="12" md="4">
                      <TextField
                        fullWidth
                        size="small"
                        name="concepto"
                        id="concepto"
                        label="CONCEPTO"
                        focused={isFocused("concepto")}
                        {...register("concepto", { required: true })}
                        helperText={errors.concepto ? "Campo obligatorio" : ""}
                        error={errors.concepto?.type === "required"}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12" md="4">
                      <input type="hidden" {...register("clienteId")} />
                      <Autocomplete
                        fullWidth
                        size="small"
                        value={cliente}
                        options={filteredClientes || []}
                        getOptionLabel={(option) => option.nombre || ""}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="CLIENTE (Escriba para buscar)"
                            focused={isFocused("clienteId")}
                            onChange={(e) => filterClientes(e.target.value)}
                          />
                        )}
                        onChange={(event, value) => {
                          if (value) {
                            setValue("clienteId", value.id);
                            handleClienteChange(value);
                          }
                        }}
                      />
                    </Col>
                    <Col>
                      <TextField
                        fullWidth
                        size="small"
                        name="identificacion"
                        id="identificacion"
                        label="Nº IDENTIFICACIÓN"
                        focused={isFocused("identificacion")}
                        {...register("identificacion", { required: true })}
                        helperText={
                          errors.identificacion ? "Campo obligatorio" : ""
                        }
                        error={errors.identificacion?.type === "required"}
                      />
                    </Col>
                    <Col sm={12} md={4}>
                      <TextField
                        fullWidth
                        size="small"
                        name="direccion"
                        id="direccion"
                        label="DIRECCIÓN"
                        focused={isFocused("direccion")}
                        {...register("direccion", { required: true })}
                        helperText={errors.direccion ? "Campo obligatorio" : ""}
                        error={errors.direccion?.type === "required"}
                      />
                    </Col>
                    <Col sm={12} md={2}>
                      <TextField
                        fullWidth
                        size="small"
                        name="telefono"
                        id="telefono"
                        label="TELÉFONO"
                        focused={isFocused("telefono")}
                        {...register("telefono")}
                      />
                    </Col>
                  </Row>
                  <div
                    style={{
                      width: "100%",
                      height: "5px",
                      borderRadius: "5px",
                      margin: "15px auto",
                      backgroundColor: "var(--theme-deafult)",
                    }}
                  ></div>

                  <h6>Líneas de Factura</h6>
                  <Row>
                    <Col sm={12} md={1}>
                      <TextField
                        variant="standard"
                        type="number"
                        fullWidth
                        size="small"
                        name="cantidad"
                        id="cantidad"
                        label="Cantidad"
                        value={lineaFacturaTemp.cantidad}
                        onChange={(e) => {
                          setLineaFacturaTemp({
                            ...lineaFacturaTemp,
                            cantidad: e.target.value,
                          });
                        }}
                      />
                    </Col>
                    <Col sm="12" md="3">
                      <Autocomplete
                        fullWidth
                        variant="standard"
                        size="small"
                        value={lineaFacturaTemp.activo}
                        options={activosFiltered || []}
                        getOptionLabel={(option) => option.titulo || ""}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Activo"
                            variant="standard"
                            onChange={(e) => filterActivos(e.target.value)}
                          />
                        )}
                        onChange={(e, value) => {
                          if (value) {
                            console.log(value);
                            setLineaFacturaTemp({
                              ...lineaFacturaTemp,
                              activo: value,
                            });
                          }
                        }}
                      />
                    </Col>
                    <Col sm={12} md={4}>
                      <TextField
                        variant="standard"
                        fullWidth
                        size="small"
                        name="concepto"
                        id="concepto"
                        label="Concepto"
                        value={lineaFacturaTemp.concepto}
                        onChange={(e) => {
                          setLineaFacturaTemp({
                            ...lineaFacturaTemp,
                            concepto: e.target.value,
                          });
                        }}
                      />
                    </Col>
                    <Col sm={12} md={1}>
                      <TextField
                        variant="standard"
                        type="number"
                        fullWidth
                        size="small"
                        name="precioUnidad"
                        id="precioUnidad"
                        label="Precio"
                        value={lineaFacturaTemp.precioUnidad}
                        onChange={(e) => {
                          setLineaFacturaTemp({
                            ...lineaFacturaTemp,
                            precioUnidad: e.target.value,
                          });
                        }}
                      />
                    </Col>
                    <Col sm={12} md={2}>
                      <Select
                        sx={{ marginTop: "15px" }}
                        variant="standard"
                        size="small"
                        fullWidth
                        label="Tipo IGIC"
                        name="tipoIgic"
                        value={lineaFacturaTemp.tipoigicId}
                        onChange={(e) => {
                          setLineaFacturaTemp({
                            ...lineaFacturaTemp,
                            tipoigicId: e.target.value,
                          });
                        }}
                      >
                        {tiposIgic?.map((tipo) => (
                          <MenuItem key={tipo.id} value={tipo.id}>
                            {tipo.nombre} ({tipo.valor}%)
                          </MenuItem>
                        ))}
                      </Select>
                    </Col>
                    <Col sm={12} md={1}>
                      <Button
                        fullWidth
                        endIcon={<Add />}
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => {
                          addLine();
                        }}
                      >
                        Agregar
                      </Button>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={12} md={12}>
                      {lineasFactura?.length > 0 ? (
                        <Table aria-label="simple table">
                          <thead>
                            <tr>
                              <th>Cantidad</th>
                              <th>Activo</th>
                              <th>Concepto</th>
                              <th>Precio</th>
                              <th>Tipo</th>
                              <th>Total</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {lineasFactura?.map((linea, index) => (
                              <tr key={index}>
                                <td>{linea.cantidad}</td>
                                <td>{linea.activo?.titulo}</td>
                                <td>{linea.concepto}</td>
                                <td>{linea.precioUnidad}</td>
                                <td>
                                  {tiposIgic.find(
                                    (tipo) => tipo.id == linea.tipoigic?.id
                                  )?.nombre ||
                                    tiposIgic.find(
                                      (tipo) => tipo.id == linea.tipoigicId
                                    )?.nombre}
                                </td>
                                <td>{linea.total}</td>
                                <td className="text-end">
                                  <DeleteButton
                                    onClick={() => handleDeleteLinea(linea)}
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      ) : (
                        <div style={{ textAlign: "center" }}>
                          <Typography variant="body2">
                            No hay líneas de factura
                          </Typography>
                        </div>
                      )}
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={12} md={6}></Col>
                    <Col sm={12} md={2}>
                      <TextField
                        fullWidth
                        disabled
                        size="large"
                        name="baseimp"
                        id="baseimp"
                        label="Base imponible"
                        value={totales.baseimp}
                        onChange={(e) =>
                          setTotales({ ...totales, baseimp: e.target.value })
                        }
                      />
                    </Col>
                    <Col sm={12} md={2}>
                      <TextField
                        disabled
                        fullWidth
                        size="large"
                        name="igic"
                        id="igic"
                        label="IGIC"
                        value={totales.igic}
                        onChange={(e) =>
                          setTotales({ ...totales, igic: e.target.value })
                        }
                      />
                    </Col>
                    <Col sm={12} md={2}>
                      <TextField
                        disabled
                        fullWidth
                        size="large"
                        name="total"
                        id="total"
                        label="Total"
                        value={totales.total}
                        onChange={(e) =>
                          setTotales({ ...totales, total: e.target.value })
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12} md={12} className="d-flex justify-content-end">
                      <SaveButton />
                    </Col>
                  </Row>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};
