import { useState, useEffect, useMemo } from "react";
import { confirmAlert } from "react-confirm-alert";
import { DeleteButton } from "../../../../components/buttons/DeleteButton";
import { EditButton } from "../../../../components/buttons/EditButton";
import { LOCALE_TABLE } from "../../../../components/custom-table/CustomTable";

import { useFiltrosPaginacion } from "../../../../hooks/useFiltrosPaginacion";
import { useInvoiceMutation } from "../hooks/useInvoiceMutation";
import { useInvoices } from "../hooks/useInvoices";
import moment from "moment";
import { TableLoading } from "../../../../components/custom-table/TableLoading";
import { toast } from "react-toastify";
import { useNavigate, useSearchParams } from "react-router-dom";

import MaterialReactTable from "material-react-table";

import { Button } from "@mui/material";
import { Box } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { ExportToCsv } from "export-to-csv";

export const InvoiceListView = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [invoices, setInvoices] = useState([]);
  const [idgrupo] = useState(searchParams.get("idgrupo") || "");
  const { setFiltros, filtros } = useFiltrosPaginacion();
  const { loading, facturas, refetch } = useInvoices();

  const columns = useMemo(() => [
    {
      header: "",
      accessorKey: "id",
      enableColumnActions: false,
      enableFilters: false,
      enableMultiSort: false,
      enableColumnFilter: false,
      enableColumnFilterChangeMode: false,
      id: "actionsEdit",
      size: 30,
      Cell: ({ cell }) => (
        <div className="text-center">
          <EditButton entity="invoices" id={cell.getValue()} />
        </div>
      ),
    },
    {
      header: "NÚM.",
      accessorKey: "numero",
      size: 50,
    },
    {
      header: "SEDE",
      accessorKey: "sede.nombre",
    },
    {
      header: "ID. GRUPO",
      accessorKey: "idgrupo",
    },
    {
      header: "FECHA",
      accessorKey: "fecha",
      Cell: ({ cell }) => {
        return moment(cell.getValue()).format("DD/MM/YYYY");
      },
    },
    {
      header: "CLIENTE",
      accessorKey: "cliente.nombre",
    },
    {
      header: "IDENTIFICACIÓN",
      accessorKey: "identificacion",
    },
    {
      header: "DIRECCIÓN",
      accessorKey: "direccion",
    },
    {
      header: "TELÉFONO",
      accessorKey: "telefono",
    },
    {
      header: "CONCEPTO",
      accessorKey: "concepto",
    },
    {
      header: "TOTAL",
      accessorKey: "total",
    },
    {
      header: "B.IMPONIBLE",
      accessorKey: "baseimp",
    },
    {
      header: "IGIC",
      accessorKey: "igic",
    },
    {
      header: "",
      accessorKey: "id",
      enableColumnActions: false,
      enableFilters: false,
      enableMultiSort: false,
      enableColumnFilter: false,
      enableColumnFilterChangeMode: false,
      id: "actions",
      Cell: ({ cell }) => (
        <div className="text-end">
          <DeleteButton onClick={() => handleDeleteInvoice(cell.getValue())} />
        </div>
      ),
    },
  ]);

  const { deleteFactura } = useInvoiceMutation();

  const csvOptions = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers: columns.map((c) => c.header),
  };

  const csvExporter = new ExportToCsv(csvOptions);

  useEffect(() => {
    if (facturas?.allFacturas) {
      setInvoices(facturas.allFacturas.items);
    }
  }, [facturas]);

  useEffect(() => {
    if (filtros) {
      if (filtros.filtros.filter((f) => f.campo === "idgrupo").length > 0) {
        navigate(
          `?idgrupo=${
            filtros.filtros.filter((f) => f.campo === "idgrupo")[0].valor
          }`
        );
      } else {
        navigate(``);
      }
    }
  }, [filtros]);

  useEffect(() => {
    if (idgrupo) {
      setFiltros({
        filtros: [
          ...filtros.filtros,
          { campo: "idgrupo", valor: idgrupo, tipo: "text" },
        ],
        order: filtros.order,
        skip: filtros.skip,
      });
    }
  }, []);

  const handleDeleteInvoice = (id) => {
    confirmAlert({
      title: "¿Está seguro de eliminar este albarán de salida?",
      message: "Esta acción es irreversible",
      buttons: [
        {
          label: "Si, ELIMINAR",
          onClick: () => {
            deleteFactura({
              variables: {
                id,
              },
            })
              .then(({ data }) => {
                console.log(data);
                refetch({
                  filtros: filtros.filtros,
                  order: filtros.order,
                  skip: filtros.skip,
                });
                toast.info("Albarán eliminada correctamente");
              })
              .catch(({ message }) => {
                toast.error(message);
              });
          },
        },
        {
          label: "No",
          onClick: () => null,
        },
      ],
    });
  };

  const handleExportRows = (rows) => {
    csvExporter.generateCsv(rows.map((row) => row.original));
  };

  const handleExportData = () => {
    csvExporter.generateCsv(invoices);
  };

  return (
    <>
      {facturas ? (
        <MaterialReactTable
          columns={columns}
          data={invoices}
          localization={LOCALE_TABLE}
          enableRowSelection
          enableColumnFilterModes
          enableGlobalFilterModes
          enableDensityToggle={false}
          state={{
            density: "compact",
            isLoading: loading,
          }}
          initialState={{
            pagination: {
              pageSize: 30,
            },
          }}
          muiTableProps={{
            sx: {
              tableLayout: "auto",
            },
          }}
          muiTablePaginationProps={{
            rowsPerPageOptions: [30, 50, 100],
            SelectProps: { native: true },
          }}

          renderTopToolbarCustomActions={({ table }) => (
            <Box>
              <Button
                color="primary"
                className="btn-xs btn-air-primary mx-1"
                //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                onClick={handleExportData}
              >
                <FileDownloadIcon /> Exportar todo
              </Button>
              <Button
                color="primary"
                className="btn-xs btn-air-primary mx-1"
                disabled={table.getRowModel().rows.length === 0}
                //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
                onClick={() => handleExportRows(table.getRowModel().rows)}
              >
                <FileDownloadIcon /> Exportar página
              </Button>
              <Button
                color="primary"
                className="btn-xs btn-air-primary mx-1"
                disabled={
                  !table.getIsSomeRowsSelected() &&
                  !table.getIsAllRowsSelected()
                }
                //only export selected rows
                onClick={() =>
                  handleExportRows(table.getSelectedRowModel().rows)
                }
              >
                <FileDownloadIcon /> Exportar selección
              </Button>
            </Box>
          )}
        ></MaterialReactTable>
      ) : (
        <TableLoading />
      )}
    </>
  );
};
