import { useMutation } from "@apollo/client";
import { MUTATION_GDRIVESYNC } from "../graphql/mutations";

export const useGdriveSync = () => {
  const [sync, { loading }] = useMutation(MUTATION_GDRIVESYNC);
  return {
    sync,
    loading,
  };
};
