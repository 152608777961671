import React from 'react'
import { Container } from 'reactstrap'
import Breadcrumbs from '../../../layout/breadcrumb'
import { VendorRouter } from './router/VendorRouter'

export const VendorPage = () => {
  return (
    <>
    <Breadcrumbs title="Proveedores" entidad="vendors" parent="Proveedores" />
    <Container fluid={true}>
      <div className="faq-wrap">
        <VendorRouter />
      </div>
    </Container>
  </>
  )
}
