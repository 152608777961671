import { useMutation } from "@apollo/client";
import {
  CREATE_TIPO_DOCUMENTO,
  UPDATE_TIPO_DOCUMENTO,
} from "../graphql/mutations";
import { QUERY_ALL_TIPO_DOCUMENTOS } from "../graphql/queries";

export const useTipoDocumentoMutation = () => {
  const [createTipoDocumento] = useMutation(CREATE_TIPO_DOCUMENTO, {
    refetchQueries: [{ query: QUERY_ALL_TIPO_DOCUMENTOS }],
  });
  const [updateTipoDocumento] = useMutation(UPDATE_TIPO_DOCUMENTO, {
    refetchQueries: [{ query: QUERY_ALL_TIPO_DOCUMENTOS }],
  });

  return {
    createTipoDocumento,
    updateTipoDocumento,
  };
};
