import { gql } from "@apollo/client";

export const QUERY_ACTIVO = gql`
  query activo($id: Int!) {
    activo(id: $id) {
      id
      titulo
      fechaAlta
      tipo {
        id
        nombre
      }
      paginas
      color
      tipoEncuadernacion
      isbn
      coste
      pvp
      gdrive
      estado
      observaciones
      activostockageSet {
        id
        sede {
          id
          nombre
        }
        stockage
      }
      documentoSet {
        id
        carpeta
        url
        nombre
        tipo {
          id
          nombre
        }
        fechahora
        refGdrive
        estado
        carpeta
        createdTime
        modifiedTime
        modifiedUser
        size
        noEncontrado
        activo {
          id
        }
      }
    }
  }
`;

export const QUERY_ALL_ACTIVOS = gql`
  query allActivos(
    $skip: Int
    $order: String
    $filtros: [FiltroType]
    $search: String
    $filtrosOr: [FiltroType]
  ) {
    allActivos(
      filtros: $filtros
      search: $search
      filtrosOr: $filtrosOr
      skip: $skip
      order: $order
    ) {
      numitems
      items {
        id
        titulo
        fechaAlta
        tipo {
          id
          nombre
        }
        documentoSet {
          id
        }
        paginas
        color
        tipoEncuadernacion
        isbn
        coste
        pvp
        gdrive
        estado
        observaciones
      }
    }
  }
`;

export const QUERY_AUDITORIA_STOCKAGE_ACTIVO = gql`
  query auditoriaStockageActivo($idactivo: Int!) {
    auditoriaStockageActivo(idactivo: $idactivo) {
      fechahora
      operacion
      tabla
      stockage
      sede {
        id
        nombre
      }
    }
  }
`;
