import { gql } from "@apollo/client";

export const MUTATION_CREATE_SEDE = gql`
  mutation createSede($data: SedeInput!) {
    createSede(data: $data) {
      ok
      sede {
        id
        nombre
      }
    }
  }
`;

export const MUTATION_UPDATE_SEDE = gql`
  mutation updateSede($id: Int!, $data: SedeUpdateInput!) {
    updateSede(id: $id, data: $data) {
      ok
      sede {
        id
        nombre
      }
    }
  }
`;
