import React, { useMemo, useState } from "react";
import { Card, CardHeader, Table } from "reactstrap";

export const SiteActivesStockage = ({ stock }) => {
  const [filtros, setFiltros] = useState([]);

  const columns = useMemo(() => [
    {
      header: "Activo",
      accessorKey: "activo.titulo",
    },
    {
      header: "Stock",
      accessorKey: "stockage",
    },
  ]);

  return (
    <Card>
      <CardHeader>
        <h6>Stock</h6>
      </CardHeader>
      <Table responsive hover>
        <thead>
          <tr>
            <th>Activo</th>
            <th>Stock</th>
          </tr>
        </thead>
        <tbody>
          {stock?.map((stockage) => (
            <tr key={stockage.id}>
              <td>{stockage.activo.titulo}</td>
              <td>{stockage.stockage}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Card>
  );
};
