import { useMutation } from "@apollo/client";
import { GENERAR_FACTURA_PDF } from "../graphql/mutations-graphql";
export const useGenerarFacturaPdf = () => {
  const [generarFacturaPdf] = useMutation(GENERAR_FACTURA_PDF, {
    onCompleted: (data) => {
      console.log(data);
      if (data.generarFacturaPdf) {
        const filePath =
          process.env.REACT_APP_MEDIA_URI + data.generarFacturaPdf.path;
        const link = document.createElement("a");
        link.download = filePath;
        link.target = "_blank";

        // Construct the URI
        link.href = filePath;
        document.body.appendChild(link);
        link.click();

        // Cleanup the DOM
        document.body.removeChild(link);
      }
    },
  });
  return {
    generarFacturaPdf,
  };
};
