import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router";
import { Card, CardBody, Col, Container, Row, Table } from "reactstrap";
import { useMutationsOrder } from "../hooks/useMutationsOrder";
import { useOrder } from "../hooks/useOrder";
import { toast } from "react-toastify";
import { Autocomplete, Button, TextField } from "@mui/material";
import moment from "moment";
import { useActives } from "../../active/hooks/useActives";
import { useVendors } from "../../vendor/hooks/useVendors";
import { SaveButton } from "../../../../components";
import { CreateAlbaranModal } from "./CreateAlbaranModal";
import { GridAlbaranes } from "./GridAlbaranes";

export const OrderFormView = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [pedido, setPedido] = useState({});
  const [activo, setActivo] = useState({});
  const [proveedor, setProveedor] = useState({});
  const [activosFiltered, setActivosFiltered] = useState([]);
  const [proveedoresFiltered, setProveedoresFiltered] = useState([]);
  const { getPedido } = useOrder();
  const { refetch: getActivos } = useActives();
  const { refetch: getProveedores } = useVendors();
  const { createPedido, updatePedido } = useMutationsOrder();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues
  } = useForm({
    defaultValues: {
      fecha: moment().format("YYYY-MM-DD"),
      cantidad: 1,
    },
  });

  useEffect(() => {
    if (id) {
      getPedido({ variables: { id } })
        .then(({ data }) => {
          if (data.pedido) {
            setPedido(data.pedido);
            setProveedoresFiltered([{ ...data.pedido.proveedor }]);
            setProveedor(data.pedido.proveedor);
            setActivosFiltered([{ ...data.pedido.activo }]);
            setActivo(data.pedido.activo);
            setValue("fecha", data.pedido.fecha);
            setValue("cantidad", data.pedido.cantidad);
            setValue("activoId", data.pedido.activo.id);
            setValue("proveedorId", data.pedido.proveedor.id);
            setValue("observaciones", data.pedido.observaciones);
          }
        })
        .catch((e) => {
          toast.error("Error al cargar el pedido. " + e.message);
          navigate("/orders", { replace: true });
        });
    }
  }, [id]);

  const filterActivos = (text) => {
    if (text.length > 2) {
      getActivos({
        variables: {
          filtros: [{ campo: "nombre", valor: text, tipo: "string" }],
        },
      })
        .then(({ data }) => {
          setActivosFiltered(data.allActivos.items);
        })
        .catch((error) => {
          setActivosFiltered([]);
        });
    }
  };

  const filterProveedores = (text) => {
    if (text.length > 2) {
      getProveedores({
        variables: {
          filtros: [{ campo: "nombre", valor: text, tipo: "string" }],
        },
      })
        .then(({ data }) => {
          setProveedoresFiltered(data.allProveedores.items);
        })
        .catch((error) => {
          console.log(error);
          setProveedoresFiltered([]);
        });
    }
  };

  const isFocused = (field) => {
    return (
      getValues(field) !== undefined && getValues(field) !== null && getValues(field) !== ""
    );
  };

  const onSubmit = (formData) => {
    console.log(formData);
    if (!id) {
      createPedido({
        variables: {
          data: { ...formData },
        },
      })
        .then(({ data }) => {
          if (data.createPedido.ok) {
            toast.info("Pedido creado con éxito");
            navigate("/orders", { replace: true });
          } else {
            toast.error("Error al crear el pedido");
          }
        })
        .catch((error) => {
          toast.error("Error al crear el pedido. " + error.message);
        });
    } else {
      updatePedido({
        variables: {
          id,
          data: { ...formData },
        },
      })
        .then(({ data }) => {
          if (data.updatePedido.ok) {
            toast.info("Pedido actualizado con éxito");
          } else {
            toast.error("Error al actualizar el pedido");
          }
        })
        .catch((error) => {
          toast.error("Error al actualizar el pedido. " + error.message);
        });
    }
  };
  const closeHandler = () => {
    getPedido({ variables: { id } })
      .then(({ data }) => {
        console.log(data);
        if (data.pedido) {
          setPedido(data.pedido);
          setProveedoresFiltered([{ ...data.pedido.proveedor }]);
          setProveedor(data.pedido.proveedor);
          setActivosFiltered([{ ...data.pedido.activo }]);
          setActivo(data.pedido.activo);
          setValue("fecha", data.pedido.fecha);
          setValue("cantidad", data.pedido.cantidad);
          setValue("activoId", data.pedido.activo.id);
          setValue("proveedorId", data.pedido.proveedor.id);
          setValue("observaciones", data.pedido.observaciones);
        }
      })
      .catch((e) => {
        toast.error("Error al cargar el pedido. " + e.message);
        navigate("/orders", { replace: true });
      });
    setVisible(false);
  };

  return (
    <>
      <Container fluid>
        <CreateAlbaranModal
          visible={visible}
          setVisible={setVisible}
          closeHandler={closeHandler}
          pedidoId={id}
        />
        <Row>
          <Col md={12}>
            <Card>
              <CardBody>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Row className="mb-3">
                    <Col sm="12">
                      <h6>{id ? "Editar" : "Nuevo"} pedido</h6>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12" md="2">
                      <TextField
                        size="small"
                        label="FECHA"
                        name="fecha"
                        type="date"
                        focused={isFocused("fecha")}
                        {...register("fecha", { required: true })}
                        helperText={errors.fecha ? "Campo obligatorio" : ""}
                        error={errors.fecha?.type === "required"}
                      />
                    </Col>

                    <Col sm="12" md="4">
                      <input type="hidden" {...register("activoId")} />
                      <Autocomplete
                        fullWidth
                        size="small"
                        value={activo}
                        options={activosFiltered || []}
                        getOptionLabel={(option) => option.titulo || ""}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="ACTIVO (Escriba para buscar)"
                            focused={isFocused("activoId")}
                            onChange={(e) => filterActivos(e.target.value)}
                          />
                        )}
                        onChange={(event, value) => {
                          if (value) {
                            setActivo(value);
                            setValue("activoId", value.id);
                          }
                        }}
                      />
                    </Col>
                    <Col sm="12" md="4">
                      <input type="hidden" {...register("proveedorId")} />
                      <Autocomplete
                        fullWidth
                        size="small"
                        value={proveedor}
                        options={proveedoresFiltered || []}
                        getOptionLabel={(option) => option.nombre || ""}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="PROVEEDOR (Escriba para buscar)"
                            focused={isFocused("proveedorId")}
                            onChange={(e) => filterProveedores(e.target.value)}
                          />
                        )}
                        onChange={(event, value) => {
                          if (value) {
                            setProveedor(value);
                            setValue("proveedorId", value.id);
                          }
                        }}
                      />
                    </Col>
                    <Col sm="12" md="2">
                      <TextField
                        size="small"
                        label="CANTIDAD"
                        name="cantidad"
                        type="number"
                        focused={isFocused("cantidad")}
                        {...register("cantidad", { required: true })}
                        helperText={errors.cantidad ? "Campo obligatorio" : ""}
                        error={errors.cantidad?.type === "required"}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <TextField
                        fullWidth
                        multiline
                        label="OBSERVACIONES"
                        name="observaciones"
                        rows={4}
                        maxRows={4}
                        focused={isFocused("observaciones")}
                        {...register("observaciones")}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12" className="d-flex justify-content-between">
                      <span>
                        {id && (
                          <Button
                            color="secondary"
                            onClick={() => setVisible(true)}
                          >
                            Crear Albarán/Entrega
                          </Button>
                        )}
                      </span>
                      <SaveButton />
                    </Col>
                  </Row>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {id && (<GridAlbaranes albaranes={pedido?.albaranSet} />)}
      </Container>
    </>
  );
};
