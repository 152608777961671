import { gql } from "@apollo/client";

export const TIPOS_ESTADOS = gql`
  query estadosActivos {
    __type(name: "ActivoEstado") {
      enumValues {
        name
        description
      }
    }
  }
`;

export const ESTADOS_MERCANCIA = gql`
  query estadosMercancia {
    __type(name: "AlbaranEstadoMercancia") {
      enumValues {
        name
        description
      }
    }
  }
`;

export const TIEMPOS_ENTREGA = gql`
  query tiemposEntrega {
    __type(name: "AlbaranTiempoEntrega") {
      enumValues {
        name
        description
      }
    }
  }
`;
