import { useLazyQuery } from "@apollo/client";
import { QUERY_TIPO_ACTIVO } from "../graphql/queries";

export const useTipoActivo = () => {
  const [getTipo] = useLazyQuery(QUERY_TIPO_ACTIVO, {
    fetchPolicy: "network-only",
  });
  return {
    getTipo,
  };
};
