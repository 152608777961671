import { gql } from "@apollo/client";
export const ALL_CLIENTES = gql`
  query allClientes(
    $skip: Int
    $order: String
    $filtros: [FiltroType]
    $search: String
    $filtrosOr: [FiltroType]
  ) {
    allClientes(
      filtros: $filtros
      search: $search
      filtrosOr: $filtrosOr
      skip: $skip
      order: $order
    ) {
      numitems
      items {
        id
        identificacion
        nombre
        email
        telefono
        direccion
      }
    }
  }
`;

export const CLIENTE = gql`
  query cliente($id: Int!) {
    cliente(id: $id) {
      id
      identificacion
      nombre
      email
      telefono
      direccion
    }
  }
`;

