import { Button } from "@mui/material";
import React from "react";
import {Modal, ModalBody, ModalFooter } from "reactstrap";

export const DetailsModal = ({ detalles, open, setOpen }) => {
  return (
    <Modal isOpen={open} size="xl" scrollable={true} centered={true}>
      <ModalBody>{detalles}</ModalBody>
      <ModalFooter>
        <Button className="btn-air-primary" color="primary" onClick={() => setOpen(false)}>
          Cerrar
        </Button>
      </ModalFooter>
    </Modal>
  );
};
