import { useEffect, useMemo, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { DeleteButton, EditButton, LOCALE_TABLE } from "../../../../components";
import { useMutationsWaybill } from "../hooks/useMutationsWaybill";
import { useWaybills } from "../hooks/useWaybills";
import { toast } from "react-toastify";
import moment from "moment";
import { TableLoading } from "../../../../components/custom-table/TableLoading";

import MaterialReactTable from "material-react-table";

import { Button } from "@mui/material";
import { Box } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { ExportToCsv } from "export-to-csv";

export const WaybillListView = () => {
  const [albaranes, setAlbaranes] = useState([]);
  const { loading, _albaranes } = useWaybills();
  const { deleteAlbaran } = useMutationsWaybill();
  const columns = useMemo(() => [
    {
      header: "",
      accessorKey: "id",
      enableColumnActions: false,
      enableFilters: false,
      enableMultiSort: false,
      enableColumnFilter: false,
      enableColumnFilterChangeMode: false,
      id: "actionsEdit",
      size: 30,
      Cell: ({ cell }) => (
        <div className="text-center">
          <EditButton entity="waybills" id={cell.getValue()} />
        </div>
      ),
    },
    {
      header: "ID",
      accessorKey: "id",
      key: "pedidoId",
      size: 40,
    },
    {
      header: "FECHA",
      accessorKey: "fecha",
      Cell: ({ cell }) => {
        return moment(cell.getValue()).format("DD/MM/YYYY");
      },
    },
    {
      header: "SEDE",
      accessorKey: "sede.nombre",
    },
    {
      header: "PEDIDO",
      accessorKey: "pedido",

      Cell: ({ cell }) => {
        return (
          <a
            target="_blank"
            rel="noreferrer"
            href={`/orders/${cell.getValue().id}`}
          >
            {cell.getValue().activo.titulo}
          </a>
        );
      },
    },
    {
      header: "CANTIDAD",
      accessorKey: "cantidad",

    },
    {
      header: "PRECIO UNIDAD",
      accessorKey: "precioUnidad",

    },
    {
      header: "OBSERVACIONES",
      accessorKey: "observaciones",

      Cell: ({ cell }) => {
        return `${cell?.getValue()?.slice(0, 50)} ${
          cell?.getValue()?.length > 50 ? "..." : ""
        }`;
      },
    },
    {
      header: "",
      accessorKey: "id",
      enableColumnActions: false,
      enabaleFilters: false,
      enableColumnFilters: false,
      id: "actions",
      Cell: ({ cell }) => (
        <div className="text-end">
          <DeleteButton onClick={() => handleDeleteWaybill(cell.getValue())} />
        </div>
      ),
    },
  ]);

  const csvOptions = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers: columns.map((c) => c.header),
  };

  const csvExporter = new ExportToCsv(csvOptions);


  useEffect(() => {
    if (_albaranes?.allAlbaranes) {
      setAlbaranes(_albaranes.allAlbaranes.items);
    }
  }, [_albaranes]);

  const handleExportRows = (rows) => {
    csvExporter.generateCsv(rows.map((row) => row.original));
  };

  const handleExportData = () => {
    csvExporter.generateCsv(albaranes);
  };


  const handleDeleteWaybill = (id) => {
    confirmAlert({
      title: "¿Está seguro de eliminar este albarán de entrada?",
      message: "Esta acción es irreversible",
      buttons: [
        {
          label: "Si, ELIMINAR",
          onClick: () => {
            deleteAlbaran({
              variables: {
                id,
              },
            })
              .then(({ data }) => {
                if (data.deleteAlbaran.ok) {
                  toast.info(data.deleteAlbaran.mensaje);
                } else {
                  toast.error(data.deleteAlbaran.mensaje);
                }
              })
              .catch((error) => {
                toast.error(error.message);
              });
          },
        },
        {
          label: "No",
          onClick: () => null,
        },
      ],
    });
  };

  return (
    <>
      {_albaranes ? (
        <MaterialReactTable
          columns={columns}
          data={albaranes}
          localization={LOCALE_TABLE}
          enableRowSelection
          enableColumnFilterModes
          enableGlobalFilterModes
          enableDensityToggle={false}
          state={{
            density: "compact",
            isLoading: loading,
          }}
          initialState={{
            pagination: {
              pageSize: 30,
            },
          }}
          muiTableProps={{
            sx: {
              tableLayout: "auto",
            },
          }}
          muiTablePaginationProps={{
            rowsPerPageOptions: [30, 50, 100],
            SelectProps: { native: true },
          }}

          renderTopToolbarCustomActions={({ table }) => (
            <Box>
              <Button
                color="primary"
                className="btn-xs btn-air-primary mx-1"
                //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                onClick={handleExportData}
              >
                <FileDownloadIcon /> Exportar todo
              </Button>
              <Button
                color="primary"
                className="btn-xs btn-air-primary mx-1"
                disabled={table.getRowModel().rows.length === 0}
                //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
                onClick={() => handleExportRows(table.getRowModel().rows)}
              >
                <FileDownloadIcon /> Exportar página
              </Button>
              <Button
                color="primary"
                className="btn-xs btn-air-primary mx-1"
                disabled={
                  !table.getIsSomeRowsSelected() &&
                  !table.getIsAllRowsSelected()
                }
                //only export selected rows
                onClick={() =>
                  handleExportRows(table.getSelectedRowModel().rows)
                }
              >
                <FileDownloadIcon /> Exportar selección
              </Button>
            </Box>
          )}
        ></MaterialReactTable>
      ) : (
        <TableLoading />
      )}
    </>
  );
};
