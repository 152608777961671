import { gql } from "@apollo/client";

const CREATE_FACTURA = gql`
  mutation createFactura($data: FacturaInput!) {
    createFactura(data: $data) {
      ok
      factura {
        id
        numero
        cliente {
          id
          identificacion
          nombre
        }
        fecha
        identificacion
        direccion
        telefono
        concepto
        total
        baseimp
        igic
        sede {
          id
        }
        facturalineaSet {
          id
          numlinea
          concepto
          activo {
            id
            titulo
          }
          cantidad
          precioUnidad
          baseimp
          tipoigic {
            id
            valor
            nombre
          }
          igic
          total
        }
      }
    }
  }
`;

const UPDATE_FACTURA = gql`
  mutation updateFactura($id: Int!, $data: FacturaUpdateInput!) {
    updateFactura(id: $id, data: $data) {
      ok
      factura {
        id
        numero
        cliente {
          id
          identificacion
          nombre
        }
        fecha
        identificacion
        direccion
        telefono
        concepto
        total
        baseimp
        igic
        facturalineaSet {
          id
          numlinea
          concepto
          activo {
            id
            titulo
          }
          cantidad
          precioUnidad
          baseimp
          tipoigic {
            id
            valor
            nombre
          }
          igic
          total
        }
      }
    }
  }
`;

const DELETE_FACTURA = gql`
  mutation deleteFactura($id: Int!) {
    deleteFactura(id: $id) {
      ok
    }
  }
`;

const CREATE_LINEAFACTURA = gql`
  mutation createFacturalinea($data: FacturaLineaInput!) {
    createFacturalinea(data: $data) {
      ok
      facturalinea {
        id
        numlinea
        concepto
        activo {
          id
          titulo
        }
        cantidad
        precioUnidad
        baseimp
        tipoigic {
          id
          valor
          nombre
        }
        factura {
          total
          baseimp
          igic
        }
        igic
        total
        __typename
      }
    }
  }
`;

const DELETE_LINEAFACTURA = gql`
  mutation deleteFacturalinea($id: Int!) {
    deleteFacturalinea(id: $id) {
      ok
    }
  }
`;

const GENERAR_FACTURA_PDF = gql`
  mutation generarFacturaPdf($id: Int!) {
    generarFacturaPdf(id: $id) {
      ok
      path
    }
  }
`;

export {
  CREATE_FACTURA,
  UPDATE_FACTURA,
  DELETE_FACTURA,
  CREATE_LINEAFACTURA,
  DELETE_LINEAFACTURA,
  GENERAR_FACTURA_PDF,
};
