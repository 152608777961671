import { LOGIN, LOGOUT, SET_ACTIVE_USER } from "../actionTypes";

const initialState = {
  status: "not-authenticate",
  token: null,
  username: "",
  activeUser: {},
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVE_USER:
      state.activeUser = action.payload;
      window.localStorage.setItem("activeUser", JSON.stringify(action.payload));
      return { ...state };
    case LOGIN:
      state.status = "authenticated";
      state.token = action.payload.token;
      state.username = action.payload.username;
      window.localStorage.setItem(
        "gestion-editorial-token",
        action.payload.token
      );
      window.localStorage.setItem(
        "gestion-editorial-username",
        action.payload.username
      );
      return { ...state };
    case LOGOUT:
      state.status = "not-authenticated";
      state.token = null;
      state.username = "";
      state.activeUser = {};
      window.localStorage.clear();

      return { ...state };
    default:
      return { ...state };
  }
};

export default authReducer;
