import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router";
import { useForm } from "react-hook-form";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { TextField } from "@mui/material";
import { useClient } from "../hooks/useClient";
import { SaveButton } from "../../../../components/buttons/SaveButton";
import { useMutationClient } from "../hooks/useMutationClient";
import { toast } from "react-toastify";

export const ClientFormView = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [client, setClient] = useState({});
  const { getClient } = useClient();
  const { createCliente, updateCliente } = useMutationClient();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (id) {
      getClient({ variables: { id } }).then(({ data }) => {
        console.log(data);
        setClient(data.cliente);
        setValue("identificacion", data.cliente.identificacion);
        setValue("nombre", data.cliente.nombre);
        setValue("apellido", data.cliente.apellido);
        setValue("telefono", data.cliente.telefono);
        setValue("direccion", data.cliente.direccion);
        setValue("email", data.cliente.email);
      });
    }
  }, [id]);

  const isFocused = (field) => {
    return (
      id !== undefined && client[field] !== undefined && client[field] !== ""
    );
  };

  const onSubmit = (data) => {
    if (!id) {
      createCliente({ variables: { data: { ...data } } })
        .then(({ data }) => {
          if (data.createCliente.ok) {
            toast.info("Cliente creado correctamente");
            navigate("/clients", { replace: true });
          } else {
            toast.error("Error al crear el cliente");
          }
        })
        .catch((err) => {
          toast.error("Error al crear el cliente. " + err.message, {
            duration: 1500,
          });
        });
    } else {
      updateCliente({ variables: { id, data: { ...data } } }).then(
        ({ data }) => {
          if (data.updateCliente.ok) {
            toast.info("Cliente actualizado correctamente");
          } else {
            toast.error("Error al actualizar el cliente");
          }
        },
        (err) => {
          toast.error("Error al actualizar el cliente. " + err.message, {
            duration: 1500,
          });
        }
      );
    }
  };

  return (
    <>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Row className="mb-3">
                    <Col sm="12">
                      <h6>{id ? 'Editar' : 'Nuevo'} cliente</h6>
                    </Col>
                  </Row>
                  <Row>
                    {/* <Col sm="12" md="2">
                      <FormControl fullWidth>
                        <input
                          type="hidden"
                          {...register("tipoIdentificacion", {
                            required: true,
                            value: tipoIdentificacion,
                          })}
                        />
                        <InputLabel id="tipo">TIPO</InputLabel>
                        <Select
                          size="small"
                          labelId="tipo"
                          name="tipoIdentificacion"
                          value={tipoIdentificacion}
                          placeholder="Seleccione un tipo"
                          onChange={(event) => {
                            setTipoIdentificacion(event.target.value);
                            setValue("tipoIdentificacion", event.target.value);
                          }}
                        >
                          {tiposIdentificacion?.map((tipo) => (
                            <MenuItem key={tipo.name} value={tipo.name}>
                              {tipo.description}
                            </MenuItem>
                          ))}
                        </Select>
                        {errors.tipoIdentificacion?.type === "required" && (
                          <FormHelperText>Campo obligatorio</FormHelperText>
                        )}
                      </FormControl>
                    </Col> */}
                    <Col sm="12" md="2">
                      <TextField
                        fullWidth
                        size="small"
                        name="identificacion"
                        id="identificacion"
                        label="Nº IDENTIFICACIÓN"
                        focused={isFocused("identificacion")}
                        {...register("identificacion", { required: true })}
                        helperText={
                          errors.identificacion ? "Campo obligatorio" : ""
                        }
                        error={errors.identificacion?.type === "required"}
                      />
                    </Col>
                    <Col sm="12" md="6">
                      <TextField
                        size="small"
                        fullWidth
                        name="nombre"
                        id="nombre"
                        label="NOMBRE"
                        focused={isFocused("nombre")}
                        {...register("nombre", { required: true })}
                        helperText={errors.nombre ? "Campo obligatorio" : ""}
                        error={errors.nombre?.type === "required"}
                      />
                    </Col>
                    <Col sm="12" md="4">
                      <TextField
                        size="small"
                        fullWidth
                        name="telefono"
                        id="telefono"
                        label="TELÉFONO"
                        focused={isFocused("telefono")}
                        {...register("telefono", { required: true })}
                        helperText={errors.telefono ? "Campo obligatorio" : ""}
                        error={errors.telefono?.type === "required"}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12" md="6">
                      <TextField
                        size="small"
                        fullWidth
                        name="direccion"
                        id="direccion"
                        label="DIRRECCIÓN"
                        focused={isFocused("direccion")}
                        {...register("direccion", { required: true })}
                        helperText={errors.direccion ? "Campo obligatorio" : ""}
                        error={errors.direccion?.type === "required"}
                      />
                    </Col>
                    <Col sm="12" md="6">
                      <TextField
                        type="email"
                        size="small"
                        fullWidth
                        name="email"
                        id="email"
                        label="EMAIL"
                        focused={isFocused("email")}
                        {...register("email", { required: true })}
                        helperText={errors.email ? "Campo obligatorio" : ""}
                        error={errors.email?.type === "required"}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12" className="d-flex justify-content-end">
                      <SaveButton />
                    </Col>
                  </Row>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};
