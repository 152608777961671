import { useMutation } from "@apollo/client";
import { CREATE_TIPO_ACTIVO, UPDATE_TIPO_ACTIVO } from "../graphql/mutations";
import { QUERY_ALL_TIPO_ACTIVO } from "../graphql/queries";
export const useTipoActivoMutation = () => {
  const [createTipoActivo] = useMutation(CREATE_TIPO_ACTIVO, {
    refetchQueries: [{ query: QUERY_ALL_TIPO_ACTIVO }],
  });
  const [updateTipoActivo] = useMutation(UPDATE_TIPO_ACTIVO, {
    refetchQueries: [{ query: QUERY_ALL_TIPO_ACTIVO }],
  });

  return {
    createTipoActivo,
    updateTipoActivo,
  };
};
