import React from "react";
import { Container } from "reactstrap";
import Breadcrumbs from "../../../layout/breadcrumb";
import { TipoActivoRouter } from "./router/TipoActivoRouter";

export const TipoActivoPage = () => {
  return (
    <>
      <Breadcrumbs title="Tipos Activos" entidad="tipos-activos" parent="Tipos Activos" />
      <Container fluid={true}>
        <div className="faq-wrap">
          <TipoActivoRouter />
        </div>
      </Container>
    </>
  );
};
