import React from 'react'
import { Navigate, Route, Routes } from 'react-router'
import { VendorFormView } from '../views/VendorFormView'
import { VendorListView } from '../views/VendorListView'

export const VendorRouter = () => {
  return (
    <Routes>
    <Route path="/" element={<VendorListView />} />
    <Route path="create" element={<VendorFormView />} />
    <Route path=":id" element={<VendorFormView />} />
    <Route path="/*" element={<Navigate to="actives" />} />
  </Routes>
  )
}
