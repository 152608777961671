import React from "react";
import { Container } from "reactstrap";
import Breadcrumbs from "../../../layout/breadcrumb";
import { InvoiceRouter } from "./router/InvoiceRouter";

export const InvoicePage = () => {
  return (
    <>
      <Breadcrumbs title="Albaranes/Salida" parent="Albaranes de salida" entidad="invoices"/>
      <Container fluid={true}>
        <div className="faq-wrap">
          <InvoiceRouter />
        </div>
      </Container>
    </>
  );
};
