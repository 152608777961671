import {
  ClientPage,
  InvoicePage,
  DashboardPage,
  UserPage,
  TipoIgicPage,
  ActivePage,
  DocumentPage,
  OrderPage,
  WaybillPage,
  VendorPage,
} from "../pages/full-pages";
import { SitePage } from "../pages/full-pages/site/SitePage";
import { TipoActivoPage } from "../pages/full-pages/tipo-activo/TipoActivoPage";
import { TipoDocumentoPage } from "../pages/full-pages/tipos-documentos/TipoDocumentoPage";

export const routes = [
  { path: `/dashboard`, Component: <DashboardPage /> },
  { path: "/actives/*", Component: <ActivePage /> },
  { path: "/documents/*", Component: <DocumentPage /> },
  { path: "/clients/*", Component: <ClientPage /> },
  { path: "/invoices/*", Component: <InvoicePage /> },
  { path: "/orders/*", Component: <OrderPage /> },
  { path: "/waybills/*", Component: <WaybillPage /> },
  { path: "/vendors/*", Component: <VendorPage /> },
  { path: `/users/*`, Component: <UserPage /> },
  { path: "/tipos-igic/*", Component: <TipoIgicPage /> },
  { path: "/tipos-activos/*", Component: <TipoActivoPage /> },
  { path: "/tipos-documentos/*", Component: <TipoDocumentoPage /> },
  { path: "/sites/*", Component: <SitePage /> },
];
