import React from "react";
import { useLocation } from "react-router";
import { Container } from "reactstrap";
import Breadcrumbs from "../../../layout/breadcrumb";
import { ClientRouter } from "./router/ClientRouter";

export const ClientPage = () => {
  const location = useLocation();

  return (
    <>
      <Breadcrumbs title="Clientes" entidad="clients" parent="Clientes" />
      <Container fluid={true}>
        <div className="faq-wrap">
          <ClientRouter />
        </div>
      </Container>
    </>
  );
};
