import { gql } from "@apollo/client";

export const MUTATION_CREATE_PROVEEDOR = gql`
  mutation CreateProveedor($data: ProveedorInput!) {
    createProveedor(data: $data) {
      ok
      proveedor {
        id
        nombre
        url
        telefono
      }
    }
  }
`;

export const MUTATION_UPDATE_PROVEEDOR = gql`
  mutation UpdateProveedor($id: Int!, $data: ProveedorUpdateInput!) {
    updateProveedor(id: $id, data: $data) {
      ok
      proveedor {
        id
        nombre
        url
        telefono
      }
    }
  }
`;

export const MUTATION_DELETE_PROVEEDOR = gql`
  mutation DeleteProveedor($id: Int!) {
    deleteProveedor(id: $id) {
      ok
    }
  }
`;
