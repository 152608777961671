import { gql } from "@apollo/client";

export const QUERY_PROVEEDOR = gql`
  query Proveedor($id: Int!) {
    proveedor(id: $id) {
      id
      nombre
      url
      telefono
      pedidoSet {
        id
        fecha
        activo {
          id
          titulo
        }
        cantidad
        proveedor {
          id
          nombre
        }
        observaciones
      }
    }
  }
`;

export const QUERY_ALL_PROVEEDORES = gql`
  query AllProveedores(
    $skip: Int
    $order: String
    $filtros: [FiltroType]
    $search: String
    $filtrosOr: [FiltroType]
  ) {
    allProveedores(
      filtros: $filtros
      search: $search
      filtrosOr: $filtrosOr
      first: 15
      skip: $skip
      order: $order
    ) {
      numitems
      items {
        id
        nombre
        url
        telefono
      }
    }
  }
`;
