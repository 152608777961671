import { gql } from "@apollo/client";

export const QUERY_TIPO_IGIC = gql`
  query TipoIgic($id: Int!) {
    tipoigic(id: $id) {
      id
      nombre
      valor
    }
  }
`;

export const QUERY_ALL_TIPO_IGIC = gql`
  query AllTipoIgic {
    allTiposIgic {
      id
      nombre
      valor
    }
  }
`;
