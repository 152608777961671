import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { SaveButton } from "../../../../components/buttons/SaveButton";
import { useMutationActives } from "../hooks/useMutationActives";
import { toast } from "react-toastify";
import { useActive } from "../hooks/useActive";
import { ActiveDocuments } from "./grids/ActiveDocuments";
import { useGdriveSync } from "../hooks/useGdriveSync";
import { ActiveSiteStockage } from "./grids/ActiveSiteStockage";
import { ActivoFolder } from "../../../../components/activoFolder/ActivoFolder";
import { AuditoriaStockage } from "./grids/AuditoriaStockage";

export const ActiveFormView = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("1");
  const [activo, setActivo] = useState({});
  const [tipoActivo, setTipoActivo] = useState(1);
  const [estado, setEstado] = useState("E");
  const [color, setColor] = useState(true);
  const [documentos, setDocumentos] = useState([]);
  const [stock, setStock] = useState([]);
  const { getActive } = useActive();
  const { sync, loading: loadingSync } = useGdriveSync();
  const { tiposActivos } = useSelector((state) => state.GlobalState);
  const { estadosActivos } = useSelector((state) => state.GlobalState);
  const { createActivo, updateActivo } = useMutationActives();
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      color,
      estado,
      tipoId: tipoActivo,
      pvp: 0,
      paginas: 0,
    },
  });

  useEffect(() => {
    if (id) {
      getActive({
        variables: {
          id,
        },
      })
        .then(({ data }) => {
          console.log({ data });
          if (data.activo) {
            setActivo(data.activo);
            setDocumentos([...data.activo.documentoSet]);
            setStock([...data.activo.activostockageSet]);
            setValue("titulo", data.activo.titulo);
            setValue("tipoId", data.activo.tipo.id);
            setTipoActivo(data.activo.tipo.id);
            setValue("isbn", data.activo.isbn);
            setValue("paginas", data.activo.paginas);
            setValue("tipoEncuadernacion", data.activo.tipoEncuadernacion);
            setValue("color", data.activo.color);
            setColor(data.activo.color);
            setValue("estado", data.activo.estado);
            setEstado(data.activo.estado);
            setValue("pvp", data.activo.pvp);
            setValue("gdrive", data.activo.gdrive);
            setValue("observaciones", data.activo.observaciones);
          }
        })
        .catch((error) => {
          toast.error(error.message);
          navigate("/actives");
        });
    }
  }, [id]);

  useEffect(() => {
    if (activo && activo.gdrive) {
      syncronize();
    }
  }, [activo]);

  const syncronize = () => {
    sync({
      variables: {
        idcarpeta: activo.gdrive,
      },
    })
      .then(({ data }) => {
        if (data.gdriveSync.ok) {
          setDocumentos([...data.gdriveSync.ficheros]);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const isFocused = (field) => {
    return (
      getValues(field) !== "" &&
      getValues(field) !== undefined &&
      getValues(field) !== null
    );
  };

  const onSubmit = (formData) => {
    console.log({ formData });
    if (!id) {
      createActivo({
        variables: {
          data: { ...formData },
        },
      })
        .then(({ data }) => {
          if (data.createActivo.ok) {
            toast.info("Activo creado correctamente");
            navigate("/actives", { replace: true });
          } else {
            toast.error("Error al crear el activo");
          }
        })
        .catch((error) => {
          toast.error(error.message);
        });
    } else {
      updateActivo({
        variables: {
          id,
          data: { ...formData },
        },
      })
        .then(({ data }) => {
          if (data.updateActivo.ok) {
            toast.info("Activo actualizado correctamente");
          } else {
            toast.error("Error al actualizar el activo");
          }
        })
        .catch((error) => {
          toast.error(error.message);
        });
    }
  };
  return (
    <>
      <Container fluid={true}>
        <Row>
          <Col sm={8}>
            <Row>
              <Col sm={12}>
                <Card>
                  <CardBody>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <Row className="mb-3">
                        <Col sm="12">
                          <h6>{id ? "Editar" : "Nuevo"} activo</h6>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="12" md="9">
                          <TextField
                            size="small"
                            fullWidth
                            name="titulo"
                            id="titulo"
                            label="TÍTULO"
                            focused={isFocused("titulo")}
                            {...register("titulo", { required: true })}
                            helperText={
                              errors.titulo ? "Campo obligatorio" : ""
                            }
                            error={errors.titulo?.type === "required"}
                          />
                        </Col>
                        <Col sm="12" md="3">
                          <FormControl fullWidth focused={true}>
                            <input
                              type="hidden"
                              {...register("tipoId", {
                                required: true,
                                value: tipoActivo,
                              })}
                            />
                            <InputLabel id="tipo">TIPO</InputLabel>
                            <Select
                              size="small"
                              labelId="tipo"
                              name="tipoActivo"
                              value={tipoActivo}
                              placeholder="Seleccione un tipo"
                              onChange={(event) => {
                                setTipoActivo(event.target.value);
                                setValue("tipoId", event.target.value);
                              }}
                            >
                              {tiposActivos?.map((tipo) => (
                                <MenuItem key={tipo.id} value={tipo.id}>
                                  {tipo.nombre}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="12" md="4">
                          <TextField
                            size="small"
                            fullWidth
                            label="ISBN"
                            name="isbn"
                            focused={isFocused("isbn")}
                            {...register("isbn")}
                          />
                        </Col>
                        <Col sm="12" md="2">
                          <TextField
                            size="small"
                            fullWidth
                            label="PÁGINAS"
                            name="paginas"
                            focused={isFocused("paginas")}
                            {...register("paginas")}
                          />
                        </Col>
                        <Col sm="12" md="6">
                          <TextField
                            size="small"
                            fullWidth
                            label="TIPO ENCUADERNACIÓN"
                            name="tipoEncuadernacion"
                            focused={isFocused("tipoEncuadernacion")}
                            {...register("tipoEncuadernacion")}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="12" md="4">
                          <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Typography>B/N</Typography>
                            <Switch
                              defaultChecked
                              value={color}
                              onChange={(event) => {
                                setColor(event.target.checked);
                                setValue("color", event.target.checked);
                              }}
                              inputProps={{ "aria-label": "ant design" }}
                            />
                            <Typography>COLOR</Typography>
                          </Stack>
                        </Col>
                        <Col sm="12" md="4">
                          <FormControl fullWidth>
                            <input type="hidden" {...register("estado")} />
                            <InputLabel id="estado">ESTADO</InputLabel>
                            <Select
                              size="small"
                              labelId="estado"
                              name="estado"
                              value={estado}
                              placeholder="Seleccione un estado"
                              onChange={(event) => {
                                setEstado(event.target.value);
                                setValue("estado", event.target.value);
                              }}
                            >
                              {estadosActivos?.map((tipo) => (
                                <MenuItem key={tipo.id} value={tipo.id}>
                                  {tipo.nombre}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Col>
                        <Col sm="0" md="2"></Col>
                        <Col sm="12" md="2">
                          <TextField
                            size="small"
                            fullWidth
                            label="P.V.P."
                            name="pvp"
                            type="number"
                            {...register("pvp")}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="12">
                          <TextField
                            id="outlined-multiline-flexible"
                            label="OBSERVACIONES"
                            multiline
                            rows={4}
                            name="observaciones"
                            fullWidth
                            focused={isFocused("observaciones")}
                            {...register("observaciones")}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="12" md="6">
                          <TextField
                            InputProps={{
                              readOnly: true,
                            }}
                            size="small"
                            fullWidth
                            name="gdrive"
                            label="REFERENCIA GOOGLE DRIVE (AUTO)"
                            focused={isFocused("gdrive")}
                            {...register("gdrive")}
                          />
                        </Col>
                        <Col
                          sm="12"
                          md="6"
                          className="d-flex justify-content-start"
                        >
                          {id && (
                            <>
                              <ActivoFolder activo={activo} />
                            </>
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="12" className="d-flex justify-content-end">
                          <SaveButton />
                        </Col>
                      </Row>
                    </form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
          {id && (
            <Col sm={12} md={4}>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={
                      (activeTab === "1" ? "active" : "") + " cursor-pointer"
                    }
                    onClick={() => {
                      setActiveTab("1");
                    }}
                  >
                    STOCK
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={
                      (activeTab === "2" ? "active" : "") + " cursor-pointer"
                    }
                    onClick={() => {
                      setActiveTab("2");
                    }}
                  >
                    AUDITORÍA
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab}>
                <TabPane className="fade show" tabId="1">
                  <ActiveSiteStockage
                    stock={stock}
                    getActive={getActive}
                    activeId={id}
                    setStock={setStock}
                  />
                </TabPane>
                <TabPane className="fade show" tabId="2">
                  <AuditoriaStockage idactivo={id} />
                </TabPane>
              </TabContent>
            </Col>
          )}
        </Row>
        <Row>
          <Col>
            {id && (
              <ActiveDocuments
                documentos={documentos}
                syncronize={syncronize}
                loading={loadingSync}
              />
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};
