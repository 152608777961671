import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Card, CardHeader, Table } from "reactstrap";
import { useAuditoriaStockageActivo } from "../../hooks/useAuditoriaStockageActivo";
import moment from "moment";

export const AuditoriaStockage = ({ idactivo }) => {
  const { getAuditoriaStockageActivo } = useAuditoriaStockageActivo();
  const [auditoria, setAuditoria] = useState([]);
  useEffect(() => {
    getAuditoriaStockageActivo({
      variables: {
        idactivo: idactivo,
      },
    })
      .then(({ data }) => {
        console.log({ data });
        setAuditoria(data.auditoriaStockageActivo);
      })
      .catch(({ message }) => {
        toast.error(message);
      });
  }, []);
  return (
    <div
      style={{
        height: "350px",
        overflowY: "scroll",
        backgroundColor: "white",
      }}
    >
      <Table striped hover>
        <thead>
          <tr>
            <th>FECH/HOR</th>
            <th>OP.</th>
            <th>TABLA</th>
            <th>SEDE</th>
            <th>STOCK</th>
          </tr>
        </thead>
        <tbody>
          {auditoria?.map((item, index) => (
            <tr key={index}>
              <td>{moment(item?.fechahora).format("DD-MM-YYYY / HH:mm")}</td>
              <td>{item?.operacion}</td>
              <td>{item?.tabla}</td>
              <td>{item.sede?.nombre}</td>
              <td>{item.stockage}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};
