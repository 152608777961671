import { useQuery } from "@apollo/client";
import { QUERY_ALL_TIPO_DOCUMENTOS } from "../graphql/queries";

export const useTiposDocumento = () => {
  const {
    data,
    loading,
    error,
    refetch: getTiposDocumento,
  } = useQuery(QUERY_ALL_TIPO_DOCUMENTOS);
  return { data, loading, error, getTiposDocumento };
};
