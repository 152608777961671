import { TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { SaveButton } from "../../../../components";
import { useMutationsVendor } from "../hooks/useMutationsVendor";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { useVendor } from "../hooks/useVendor";
import { VendorWaybills } from "./grids/VendorWaybills";

export const VendorFormView = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { createProveedor, updateProveedor } = useMutationsVendor();
  const { getVendor } = useVendor();
  const [proveedor, setProveedor] = useState({});
  const [pedidos, setPedidos] = useState([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  useEffect(() => {
    if (id) {
      getVendor({
        variables: {
          id,
        },
      })
        .then(({ data }) => {
          setProveedor(data.proveedor);
          setPedidos(data.proveedor.pedidoSet);
          setValue("nombre", data.proveedor.nombre);
          setValue("telefono", data.proveedor.telefono);
          setValue("url", data.proveedor.url);
        })
        .catch((error) => {
          toast.error(error.message);
          navigate("/vendors", { replace: true });
        });
    }
  }, [id]);

  const isFocused = (field) => {
    return (
      id !== undefined &&
      proveedor[field] !== undefined &&
      proveedor[field] !== ""
    );
  };

  const onSubmit = (formData) => {
    if (!id) {
      createProveedor({
        variables: {
          data: { ...formData },
        },
      })
        .then(({ data }) => {
          if (data.createProveedor.ok) {
            toast.info("Proveedor creado con éxito");
            navigate("/vendors", { replace: true });
          } else {
            toast.error("Error al crear el proveedor");
          }
        })
        .catch((error) => {
          toast.error("Error al crear el proveedor. " + error.message);
        });
    } else {
      updateProveedor({
        variables: {
          id,
          data: { ...formData },
        },
      })
        .then(({ data }) => {
          if (data.updateProveedor.ok) {
            toast.info("Proveedor actualizado con éxito");
            navigate("/vendors", { replace: true });
          } else {
            toast.error("Error al actualizar el proveedor");
          }
        })
        .catch((error) => {
          toast.error("Error al actualizar el proveedor. " + error.message);
        });
    }
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col md={8}>
            <Card>
              <CardBody>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Row className="mb-3">
                    <Col sm="12">
                      <h6>{id ? "Editar" : "Nuevo"} Proveedor</h6>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <TextField
                        fullWidth
                        size="small"
                        name="nombre"
                        id="nombre"
                        label="NOMBRE"
                        focused={isFocused("nombre")}
                        {...register("nombre", { required: true })}
                        helperText={errors.nombre ? "Campo obligatorio" : ""}
                        error={errors.nombre?.type === "required"}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12" md="4">
                      <TextField
                        fullWidth
                        size="small"
                        name="telefono"
                        id="telefono"
                        label="TELÉFONO"
                        focused={isFocused("telefono")}
                        {...register("telefono")}
                      />
                    </Col>
                    <Col sm="12" md="8">
                      <TextField
                        fullWidth
                        size="small"
                        name="url"
                        id="url"
                        label="URL"
                        focused={isFocused("url")}
                        {...register("url")}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12" className="d-flex justify-content-end">
                      <SaveButton />
                    </Col>
                  </Row>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <VendorWaybills pedidos={pedidos} />
      </Container>
    </>
  );
};
