import { Delete } from "@mui/icons-material";
import { IconButton } from "@mui/material";

export const DeleteButton = ({ onClick }) => {
  return (
    <IconButton
      onClick={onClick}
      color="danger"
      className="p-0"
    >
      <Delete/>
    </IconButton>
  );
};
