import { useMutation } from "@apollo/client";
import {
  MUTATION_CREATE_PEDIDO,
  MUTATION_DELETE_PEDIDO,
  MUTATION_UPDATE_PEDIDO,
} from "../graphql/mutations";
import { QUERY_ALL_PEDIDOS } from "../graphql/queries";

export const useMutationsOrder = () => {
  const [createPedido] = useMutation(MUTATION_CREATE_PEDIDO, {
    refetchQueries: [{ query: QUERY_ALL_PEDIDOS }],
  });
  const [updatePedido] = useMutation(MUTATION_UPDATE_PEDIDO, {
    refetchQueries: [{ query: QUERY_ALL_PEDIDOS }],
  });
  const [deletePedido] = useMutation(MUTATION_DELETE_PEDIDO, {
    refetchQueries: [{ query: QUERY_ALL_PEDIDOS }],
  });
  return {
    createPedido,
    updatePedido,
    deletePedido,
  };
};
