import React from "react";
import { Container } from "reactstrap";
import Breadcrumbs from "../../../layout/breadcrumb";
import { SiteRouter } from "./router/SiteRouter";

export const SitePage = () => {
  return (
    <>
      <Breadcrumbs title="Sedes" entidad="sites" parent="Sedes" />
      <Container fluid={true}>
        <div className="faq-wrap">
          <SiteRouter />
        </div>
      </Container>
    </>
  );
};
