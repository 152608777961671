import React from "react";
import { Container } from "reactstrap";
import Breadcrumbs from "../../../layout/breadcrumb";
import { DocumentRouter } from "./router/DocumentRouter";

export const DocumentPage = () => {
  return (
    <>
      <Breadcrumbs title="Documentos" entidad="documents" parent="Documentos" />
      <Container fluid={true}>
        <div className="faq-wrap">
          <DocumentRouter />
        </div>
      </Container>
    </>
  );
};
