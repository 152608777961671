import { useMutation } from "@apollo/client";
import {
  CREATE_FACTURA,
  CREATE_LINEAFACTURA,
  DELETE_FACTURA,
  DELETE_LINEAFACTURA,
  UPDATE_FACTURA,
} from "../graphql/mutations-graphql";
import toast from "react-hot-toast";
import { ALL_FACTURAS } from "../graphql/queries-graphql";

export const useInvoiceMutation = () => {
  const [createFactura, { data: _dataCreate, loading: _loadingCreate }] =
    useMutation(CREATE_FACTURA, {
      refetchQueries: [{ query: ALL_FACTURAS }],
    });
  const [updateFactura, { data: _dataUpdate }] = useMutation(UPDATE_FACTURA, {
    onError: (err) => {
      toast.error(err.message);
    },
    refetchQueries: [{ query: ALL_FACTURAS }],
  });
  const [deleteFactura, { data: _dataDeleteFactura }] = useMutation(
    DELETE_FACTURA,
    {
      refetchQueries: [{ query: ALL_FACTURAS }],
    }
  );
  const [createLineafactura, { data: _dataLinea }] = useMutation(
    CREATE_LINEAFACTURA,
    {
      onError: (err) => {
        toast.error(err.message);
      },
      refetchQueries: [{ query: ALL_FACTURAS }],
    }
  );
  const [deleteLineafactura, { data: _dataDelete }] = useMutation(
    DELETE_LINEAFACTURA,
    {
      onError: (err) => {
        toast.error(err.message);
      },
      refetchQueries: [{ query: ALL_FACTURAS }],
    }
  );

  return {
    createFactura,
    _dataDeleteFactura,
    _dataCreate,
    _loadingCreate,
    createLineafactura,
    _dataLinea,
    deleteLineafactura,
    _dataDelete,
    deleteFactura,
    updateFactura,
    _dataUpdate,
  };
};
