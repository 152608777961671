import { gql } from "@apollo/client";

export const QUERY_ALBARAN = gql`
  query albaran($id: Int!) {
    albaran(id: $id) {
      id
      fecha
      cantidad
      precioUnidad
      observaciones
      estadoMercancia
      tiempoEntrega
      sede {
        id
        nombre
      }
      pedido {
        id
        fecha
        cantidad
        activo {
          id
          titulo
        }
        observaciones
      }
    }
  }
`;

export const QUERY_ALL_ALBARANES = gql`
  query allAlbaranes(
    $skip: Int
    $order: String
    $filtros: [FiltroType]
    $search: String
    $filtrosOr: [FiltroType]
  ) {
    allAlbaranes(
      filtros: $filtros
      search: $search
      filtrosOr: $filtrosOr
      first: 15
      skip: $skip
      order: $order
    ) {
      numitems
      items {
        id
        fecha
        cantidad
        precioUnidad
        observaciones
        estadoMercancia
        tiempoEntrega
        sede {
          id
          nombre
        }
        pedido {
          id
          fecha
          cantidad
          activo {
            id
            titulo
          }
          observaciones
        }
      }
    }
  }
`;
